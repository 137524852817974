import './riderIndex.css'
import axios from "axios"
import Swal from 'sweetalert2'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { FaLongArrowAltLeft, FaFileUpload } from "react-icons/fa"
import { siginUser } from '../../reducers/userSlice'
import {RiderTopBar} from '../../components/topbar/RiderTopbar'
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import {FooterBar} from '../../components/general_footer/footer'


export default function RiderUpdate() {
  const navigate = useNavigate();
  const userSignin = useSelector(siginUser);
  const { user } = userSignin;
  const { token, riders_description } = user;
  
  const backend_url = process.env.REACT_APP_API;

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id')
  const [error, setError] = useState("")
  const [errors, setErrors] = useState([])
  const [status, setStatus] = useState('')
  const [remark, setRemark] = useState('')
  const [resident, setResident] = useState('')
  const [loading, setLoading] = useState(false)
  const [descriptions, setDescriptions] = useState('')
  const [addressImgFile, setAddressImgFile] = useState([])
  const [landmarkImgFile, setLandmarkImgFile] = useState([])

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('')
        setErrors([])
        if(
            status && descriptions && landmarkImgFile.length !== 0 &&
            ((status === 'Yes' && resident) || (status === 'No' && !resident) || (status === 'No' && resident)) &&
            ((status === 'Yes' && addressImgFile.length !== 0) || (status === 'No' && !addressImgFile.length !== 0) || (status === 'No' && addressImgFile.length !== 0))
        ){
            // console.log(true);
            setLoading(true)
            let address = ''
            let addressImg = ''
            let landmarkImg = ''
            
            const res = new FormData();
            res.append("address_img", addressImgFile);
            res.append("landmark_img", landmarkImgFile);
            
            try {
                const { data } = await axios.post(`${backend_url}/api/address/upload-image`, res, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                });
                addressImg = data && data['address_img'] ? `https://fortressimages.fra1.digitaloceanspaces.com/${data['address_img']}` : "";
                landmarkImg = data && data['landmark_img'] ? `https://fortressimages.fra1.digitaloceanspaces.com/${data['landmark_img']}` : "";
                
                const latlng = JSON.parse(localStorage.getItem('latlng'))
                // if(latlng) {
                //     const { data } = await axios.get(`https://api.positionstack.com/v1/reverse?access_key=b2c573d3780e3aba1a77626e2633a472&query=${latlng.lat},${latlng.lng}`);
                //     address = data.data[0].street + ' ' + data.data[0].county + ' ' + data.data[0].region;
                // }
                // if(address !== '') {
                    await axios.post(`${backend_url}/api/manifest/update`,
                        {
                            id: id,
                            v_status: status,
                            resident: resident,
                            remark: remark,
                            descriptions: descriptions,
                            address_img: addressImg,
                            landmark_img: landmarkImg,
                            address: address,
                            latitude: latlng ? latlng.lat : null,
                            longitude: latlng ? latlng.lng : null,
                        },
                        {
                            headers: { Authorization: `Bearer ${token}` },
                        }
                    );
                    setLoading(false)
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Update successful',
                        showConfirmButton: false,
                        timer: 2500
                    })
                    navigate(`/rider/unverified`);
                // }
            } catch (error) {
                setLoading(false)
                console.log(error);
                if (error.response.data.errors) {
                    const err = Object.entries(error.response.data.errors)
                    setErrors(err)
                }else{
                    setError(error.response.data.message)
                }
            }
        }else{
            if(landmarkImgFile.length === 0) setError("Upload Landmark Image")
            if(status === 'Yes' && addressImgFile.length === 0) setError("Upload Address Image")
            if(!descriptions) setError("Description of location field is required")
            if(status === 'Yes' && !resident) setError("Does the Applicant reside there field is required")
            if(!status) setError("Does the Address Exist field is required")
        }
    };

return (
    <>
    <main className="ridercontent">
        <RiderTopBar />
        <div className="riderall">
            <div className="row py-4">
                <div className="col-12">
                    <Link to={`/rider/details?id=${id}`} className="card-title fw-bolder text-oran text-ora mid p-2 h2"><FaLongArrowAltLeft className=' icon-md' />Back</Link>
                    <div className="card text-center border-0 shadow">
                        <div className="card-body p-4 enitre">
                        
                            <form onSubmit={handleSubmit} className="form-update">
                                <div className="form-group">
                                    <p className="fw-bold">Does the Address Exist</p>
                                    <label className="label-update ">
                                        <input id="radio1" className="float-start" type="radio" required checked={status === "Yes"} onChange={(e) => setStatus('Yes')} /><span>Yes</span>
                                    </label>

                                    <label className="label-update ">
                                        <input id="radio2" className="float-start" type="radio" required checked={status === "No"} onChange={(e) => setStatus('No')} /><span>No</span>
                                    </label>
                                    
                                </div>

                                <div className="form-group">

                                    <p className="fw-bold">Does the Applicant reside there</p>
                                    <label className="label-update">
                                        <input id="radio3" className="float-start" type="radio" required={status === "Yes"} checked={resident === "Yes"} onChange={(e) => setResident('Yes')} /><span>Yes</span>
                                    </label>

                                    <label className="label-update">
                                        <input id="radio4" className="float-start" type="radio" required={status === "Yes"} checked={resident === "No"} onChange={(e) => setResident('No')} /><span>No</span>
                                    </label>

                                    {/* <label className="label-update">
                                        <input id="radio4" className="float-start" type="radio" required={status === "Yes"} checked={resident === "Cannot ascertain"} onChange={(e) => setResident('Cannot ascertain')} /><span>Cannot ascertain</span>
                                    </label> */}
                                </div>

                                <div className="form-group">
                                    <p>Description of Location </p>
                                    { Object.keys(riders_description).map((key, index) => {
                                        return <label key={index} className="label-update ">
                                            <input id="radio5" className="float-start" type="radio" required checked={descriptions === key} onChange={() => setDescriptions(key)} />
                                            <span>{ riders_description[key] }</span>
                                        </label>
                                    })}

                                    <label className="label-update ">
                                        Description :
                                        <textarea className="form-control" name="remarks" id="rmks" value={remark} onChange={(e) => setRemark(e.target.value)}></textarea>
                                    </label>
                            
                                </div>

                                <div className="form-group">
                                    <span>Upload Address Image :</span>
                                    <span className="mobileHide text-danger">Can only upload on mobile devices only and its required</span>
                                    <label className="mobileShow">
                                        <input type="file" accept="image/*" capture='environment' onChange={(e)=>setAddressImgFile(e.target.files[0])} style={{display: 'none'}}/>
                                        <FaFileUpload className="h1 "/>
                                    </label>
                                    <p className="text-primary">{ addressImgFile && addressImgFile.name }</p>
                                    
                                    <hr />
                                    <span>Upload Address Landmark Image :</span>
                                    <span className="mobileHide text-danger">Can only upload on mobile devices only and its required </span>
                                    <label className="mobileShow">
                                        <input type="file" accept="image/*" capture='environment' onChange={(e)=>setLandmarkImgFile(e.target.files[0])} style={{display: 'none'}}/>
                                        <FaFileUpload className="h1 "/>
                                    </label>
                                    <p className="text-primary">{ landmarkImgFile && landmarkImgFile.name }</p>
                                </div>

                                <div className="form-group">
                                    { error && <div className="text-center text-danger">{ error }</div> }
                                    { errors && <div className="text-center text-danger">{ 
                                        errors.map((data, i) => {
                                        return <span key={i}>{data[1]}<br/></span>
                                        }) }
                                    </div> }
                                </div>
                                <div className="d-grid gap-2">
                                    { !loading ? <input type="submit" className="btn btn-primary but" value="Submit"/> :
                                    <input type="submit" className="btn btn-primary but" value="Loading..." disabled/> }
                                </div>
                        
                            </form>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterBar />                                
    </main>
    </>
  )
}
