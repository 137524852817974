import React, {useState, useEffect} from 'react'
import {
  Link
} from "react-router-dom";
import { useSelector } from 'react-redux';
import Moment from 'moment';
import axios from "axios";
import _ from 'lodash'


import './Dashboard.css'
import {NavigationBar} from '../../../components/navigationbar/NewworksNavbar.jsx'
import {NewworksTopbar} from '../../../components/topbar/NewworksTopbar.jsx'
import DataChart from './component/DataChart';
import TodayCard from './component/TodayCard';
import WeekCard from './component/WeekCard';
import MonthCard from './component/MonthCard';

import ReportCard from './component/ReportCard';
import { siginUser } from '../../../reducers/userSlice';
// import  Data from "../../../assets/sampledatafiles/addresses.json"
import {FooterBar} from '../../../components/general_footer/footer'




export default function Dashboard() {

  const userSignin = useSelector(siginUser);
  const { user} = userSignin;

  const { token } = user;
  const backend_url = process.env.REACT_APP_API;
  
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'Octomber', 'November', 'December']
  const [addressesReport, setAddressesReport] = useState({});
  const [year] = useState(new Date().getFullYear());
  const [thisMonth, setThisMonth] = useState(new Date().getMonth() + 1)
  const [monthlyVerified, setMonthlyVerified] = useState([])
  

  const removeDuplicate = (arr)=>{
    return arr.filter((item, i) => arr.indexOf(item) === i)
  }


  const [sidebarWidth, setSidebarWidth] = useState('sidebar-width')
  const [mainWidth, setMainWidth] = useState('ml-10')
  const toggleNavbar = ()=>{
      sidebarWidth === 'sidebar-width' ? setSidebarWidth('') : setSidebarWidth('sidebar-width');
      mainWidth === 'ml-10' ? setMainWidth('') : setMainWidth('ml-10');
  }


  const chartData  = {
    labels: removeDuplicate(monthlyVerified.map((address) => Moment(address.updated_at.substring(0, 10)).format("D"))).map((each) => each + Moment(monthlyVerified[0]?.updated_at.substring(0, 10)).format("MMM")),
    datasets: [{
      label:`Monthly Verification - ${months[thisMonth -1]} ${year}`,
      data: Object.values(_.countBy(monthlyVerified.map((address) => Moment(address.updated_at.substring(0, 10)).format("D")))),
      backgroundColor:'#f58634',
      borderColor:"#FFB973",
      borderWidth:1.2,
      pointRadius: 3,
      pointHoverRadius: 7
    }]
  }

      // console.log();
      
  useEffect(()=>{
    const fetchAddresesReport = async ()=>{
      const response = await axios.get(`${backend_url}/api/address/verifification/report`, 
        {
            headers: { Authorization: `Bearer ${token}` },
        }
      );
      // console.log(response.data);
      setAddressesReport(response.data)
    }
    fetchAddresesReport()


    const fetchMonthlyVerified = async ()=>{
      const response = await axios.get(`${backend_url}/api/address/verifification/monthly?month=${thisMonth}`, 
        {
            headers: { Authorization: `Bearer ${token}` },
        }
      );
      setMonthlyVerified(response.data.data)
    }
    fetchMonthlyVerified();
    
  }, [setAddressesReport, backend_url , token, thisMonth])
      
  return (
    <>
    <NavigationBar sidebarWidth={sidebarWidth} />
    <main className={`n_content overflow-hidden  ${mainWidth}`}>
      <NewworksTopbar toggleNavbar={toggleNavbar}/>
      <div className="py-3">
        <div aria-label="breadcrumb" className=" d-inline-block w-100 ">
          <ol className="breadcrumb bg-blueblack-800  breadcrumb-transparent ">
            <li className="breadcrumb-item">
              <Link  to="/newworks/dashboard" className='d-flex align-items-center text-white'>
                <svg className="icon icon-sm me-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="22" height="22">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" fillRule="evenodd" clipRule="evenodd"></path>
                </svg>
                <span>Dashboard</span>
              </Link>
            </li>
          </ol>
        </div>
      </div>

      <div className="row mb-1">
           <div className="col-12 col-sm-6 col-xl-4 mb-4">
            <TodayCard VerifiedToday={addressesReport} />
          </div>
          <div className="col-12 col-sm-6 col-xl-4 mb-4">
            <WeekCard VerifiedThisWeek={addressesReport} />
          </div>
          <div  className="col-12 col-sm-6 col-xl-4 mb-4">
            <MonthCard VerifiedThisMonth={addressesReport} />
          </div>
      </div>

      <div className="row">
        <div className="col-12 col-xxl-8 order-1 order-lg-0 mb-3">
          <div className="card bg-yellow-100 border-0 shadow">
            
            <div className="card-body p-2">
              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <select name="" id="" className="form-select w-25 border-0" defaultValue={thisMonth -1} aria-label="Month selection" onChange={(e)=> setThisMonth(Number(e.target.value) + 1)}>
                    {months.map((m, i)=> (
                      <option value={i} key={i}>{m}</option>
                    ))}
                  </select>
                </div>
                <div className="col-12">
                  <DataChart ChartData={chartData} />

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-xxl-4 order-0 order-lg-1  mb-2">
         <ReportCard year={year} report={addressesReport}/>
        </div>
      </div>
        
      <FooterBar />
    </main>
    </>
  )
}
