import "./login.css"
import { unwrapResult } from '@reduxjs/toolkit'
import React, { useEffect, useState } from 'react'
import { FaEyeSlash, FaEye } from "react-icons/fa"
import { useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { loginUser, siginUser } from '../../reducers/userSlice'
// import {FooterBar} from '../../components/general_footer/footer'
import defaultAltech from '../../assets/img/alogo.png'
import defaultlogo from '../../assets/img/logo.png'

export default function Login() {
  
  const [email, setemail] = useState('')
  const [password, setpassword] = useState('')

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onemailChanged = e => setemail(e.target.value)
  const onpasswordChanged = e => setpassword(e.target.value)

  const userSignin = useSelector(siginUser);
  const { user, loading, error} = userSignin;
  const { user: loggedInUser, } = user;

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const handleClick = (e) => {
    e.preventDefault();

    dispatch(loginUser({email,password }))
    .then(unwrapResult)
  };

  useEffect(() => {

    if (loggedInUser) {

      if(loggedInUser?.type === 'rider'){
        navigate('/rider/dashboard')
      }else if (loggedInUser.type === 'admin') {
        navigate('/admin/dashboard')
      } else if (loggedInUser.type === 'nis'){
        navigate('/nis/dashboard')
      }  
      else if (loggedInUser?.type === 'newworks'){
        navigate('/newworks/dashboard')
      }else {
        navigate('/')
      }
    }
  
  }, [navigate,loggedInUser ])

  return (
    <>
      <div className="fot">
    
        <header className="header-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <nav className="main-nav">
                  
                  <div className="logo1">
                    <h4 className="zspacing bold tablet text-light head-size" >
                      <img className="" src={defaultlogo} alt="" width="70" height="60" />  Eagle's Eye</h4>
                  </div>
                
                  <span className="row nav-item  screen_414">
                  <section className="nav_action">
                    
                    <ul className="nav">
                      <div className='me-3'><Link to='/nis/dashboard'  className="btn btn-blue">GWX Portal</Link></div>
                      <div><Link to='/newworks/dashboard'  className="btn btn-blue">NewWorks Portal</Link></div>
                    </ul>
                  </section>
                  </span>
                
                </nav>
              </div>
            </div>
          </div>
        </header>
        <div className="main-banner ">
          <div className="has-overlay"></div>
          <div className="container showz adjust">
              <div className="col-lg-5 col-md-12 col-12 col-md-offset-1">

                  <form className="signup-form" onSubmit={handleClick}>

                      <h2 className="text-center h-size">Sign In</h2>

                      <hr></hr>
                      <div className='d-flex justify-content-center text-danger'>
                        {error === "Cannot read properties of undefined (reading 'message')" ? "Please Try again" : error}
                      </div>

                      <div className="form-group">

                          <input type="text" className="form-control" placeholder="UserName" 
                          required 
                          value={email}
                          onChange={onemailChanged}
                          // ref={email}
                          />
                        
                      </div>

                      <div className="form-group input-con">

                          <input className="form-control " placeholder="Password"
                          // type="password"
                          type={passwordShown ? "text" : "password"}
                          required
                          minLength="4"
                          value={password}
                          onChange={onpasswordChanged}
                          // ref={password}
                          />
                        <span className="eyes" onClick={togglePassword}>
                          {passwordShown ? <FaEyeSlash /> : <FaEye />}
                        </span>

                        {/* <button onClick={togglePassword}>Show Password</button> */}
                      </div>

                      <div className="form-group text-center">

                          <button className="btn btn-blu btn-block" type="Submit" disabled={loading}>
                          {loading ? "Loading..." : "Sign In"}
                            {/* Sign In */}
                          </button>

                      </div>

                  </form>

              </div>
              
          </div>
          <a href="http://alltsnetwork.com/" target="_blank" rel="noopener noreferrer" className="nav-link d-flex align-items-center d-flex justify-content-center">
            <p className="row p-3 text-light fw-bold">
                  Powered by: &nbsp;
                  <span className="f-icon t-icon">
                    <img src={defaultAltech} height="25" width="25" alt="Logo"></img>
                  </span> &nbsp; All-Tech Systems & Co.
                  </p>
          </a>
          {/* <FooterBar /> */}
        </div>

        
      </div>
      
    </>
  )
}
