import React from 'react'
import defaultWorks from '../../assets/img/newworks.webp'
import defaultAltech from '../../assets/img/alogo.png'
import './NewworksBar.css' 

import {
  // BrowserRouter as Router,
  //  Routes, 
  // Route,
  // Redirect
  // Navigate 
  useLocation,
  Link
} from "react-router-dom";



 const NavigationBar = ({sidebarWidth})=> {
  const location = useLocation()
  return (
   <>
    <nav id="sidebarMenu" className={`gw-sidebar  d-lg-block bg-blueblack-800 text-white ${sidebarWidth} `} >
      <div className="simplebar-wrapper" style={{"margin": "0px"}}>
        <div className="simplebar-height-auto-observer-wrapper">
          <div className="simplebar-height-auto-observer"></div>
        </div>
        <div className="simplebar-mask">

          <div className="simplebar-offset" style={{"right" : "0px", "bottom": "0px"}}>

            <div className="simplebar-content-wrapper" style={{"height": "auto" , "overflow": "hidden"}}>

              <div className="simplebar-content" style={{"padding": "0px"}}>

                <div className="sidebar-inner px-4 pt-3">

                <ul className="nav flex-column pt-3 pt-md-0">

                  <li className="nav-item justify-content-between align-items-center d-flex">

                    <Link to="#ff" className="nav-link d-flex align-items-center nw">

                      <span className="sidebar-icon nw-icon">

                        <img src={defaultWorks} height="50" width="50" alt="Volt"></img>

                      </span>

                      <span className="mt-1 ms-1 sidebar-text">Newworks Portal</span>

                    </Link>

                    <div className=" cursor d-md-none " data-bs-toggle="collapse" data-bs-target="#sidebarMenu">X</div>

                  </li>

                  <li className={location.pathname === '/newworks/dashboard' ?"nav-item active" : "nav-item"}>

                    <Link to="/newworks/dashboard" className="nav-link nw">

                      <span className="sidebar-icon">

                        <svg width="20" className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">

                          <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>

                          <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>

                        </svg> 

                      </span>

                      <span className="sidebar-text">Dashboard</span>

                    </Link>

                  </li>

                  <li className={location.pathname === '/newworks/record/search' ?"nav-item active" : "nav-item"}>

                    <Link to="/newworks/record/search" className="nav-link d-flex justify-content-between nw">

                      <span>

                        <span className="sidebar-icon">

                          <svg width="20" className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            
                            <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>

                          </svg> 

                        </span>

                        <span className="sidebar-text">Search</span>

                      </span>
                    </Link>
                  </li>
                  <li className={location.pathname === '/nis/upload/excel' ?"nav-item active" : "nav-item"}>

                    <Link to="/newworks/upload/excel"className="nav-link nw">

                      <span className="sidebar-icon">

                        <svg width="20" className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>

                        <path fillRule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clipRule="evenodd"></path>

                        </svg> 

                      </span>

                      <span className="sidebar-text">Upload Excel</span>

                    </Link>

                  </li>
                  {/* <li className="nav-item">

                    <Link to="#ff" className="nav-link d-flex justify-content-between">

                      <span>

                        <span className="sidebar-icon">

                          <svg width="20" className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">

                            <path fillRule="evenodd" d="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z" clipRule="evenodd"></path>

                          </svg> 

                        </span>

                        <span className="sidebar-text">Report</span> 

                      </span>

                    </Link>

                  </li> */}

                  <li role="separator" className="dropdown-divider mt-4 mb-3 border-gray-700"></li>

                  <li className="nav-item">

                  <a href="http://alltsnetwork.com/" target="_blank" rel="noopener noreferrer"  className="nav-link d-flex align-items-center nw">

                      <span className="sidebar-icon tb-icon">

                        <img src={defaultAltech} height="30" width="30" alt="Logo"></img>

                      </span>

                      <span className="sidebar-text fs-7">Powered by: <br /> All-Tech Systems & Co.</span>

                    </a>

                  </li>

                  <li className="nav-item">

                    <Link to="#ff" className="nav-link d-flex align-items-center nw">

                      <span className="sidebar-icon">

                        <svg width="20" className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">

                          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>

                        </svg> 

                      </span>

                      <span className="sidebar-text">Version 
                      
                        <span className="badge badge-md bg-secondary ms-1 text-gray-800">v2.1</span>

                      </span>

                    </Link>

                  </li>

                </ul>

                </div>

              </div>

            </div>

          </div>

        </div>

        <div className="simplebar-placeholder" style={{"width": "260px", "height": "621px"}}></div>

      </div>

    </nav>
   </>
  )
}

export { NavigationBar};