import './addUsers.css'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { UserTable } from '../../../components/UsersTable'
import { TopBar } from '../../../components/topbar/TopBar'
import { adminStaff, fetchStaff } from '../../../reducers/adminSlice'
import { NavigationBar } from '../../../components/navigationbar/NavigationBar'
import { siginUser } from '../../../reducers/userSlice'
import {FooterBar} from '../../../components/general_footer/footer'

export default function Addusers() {

  const navigate = useNavigate();
  const userSignin = useSelector(siginUser);
  const { user} = userSignin;
 
  const { user: loggedInUser} = user;

  // console.log( loggedInUser.level);

  const { level } = useParams();
  
  const staff = useSelector(adminStaff);
  const [update, setupdate] = useState(false)

  const [sidebarWidth, setSidebarWidth] = useState('sidebar-width')
  const [mainWidth, setMainWidth] = useState('ml-10')
  const toggleNavbar = ()=>{
      sidebarWidth === 'sidebar-width' ? setSidebarWidth('') : setSidebarWidth('sidebar-width');
      mainWidth === 'ml-10' ? setMainWidth('') : setMainWidth('ml-10');
  }

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStaff({level}));
    setupdate(false)
  }, [dispatch, level, update ]);

  useEffect(() => {

  if (loggedInUser) {

    if(loggedInUser.level >=  level && loggedInUser.level !==  '1' ){
      navigate('/admin/dashboard')
    // }else if (loggedInUser.type === 'admin') {
    //   navigate('/admin/dashboard')
    // } else if (loggedInUser.type === 'nis'){
    //   navigate('/nis/dashboard')
    // }  
    // else if (loggedInUser?.type === 'newworks'){
    //   navigate('/newworks/dashboard')
    // }else {
    //   navigate('/')
    }
}

// return () => {}

}, [navigate,loggedInUser, level])
 
  return (
   <>
    <NavigationBar sidebarWidth={sidebarWidth}/>
    <main className={`content  ${mainWidth}`}>
      <TopBar toggleNavbar={toggleNavbar}/>
      <div className="all">
        <div className="py-4">
          <div className="long d-none d-md-inline-block">
            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
              <li className="breadcrumb-item">
                <Link className="font-weight-bolder text-dark" to="">
                <svg className="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                </svg>
                <span>Dashboard</span>
                </Link>
              </li>
                <li className="breadcrumb-item">
                  <span className="font-weight-bolder text-dark">
                    { level === '1' && <span className='text-capitalize'>HO'S</span> }
                    { level === '2' && <span className='text-capitalize'>ZM'S</span> }
                    { level === '3' && <span className='text-capitalize'>RM'S</span> }
                    { level === '4' && <span className='text-capitalize'>DEO'S</span> }
                  </span>
                </li>
            
            </ol>
          </div>
        </div>

        <UserTable level={level} staff={staff}  update={update} />
        
      </div>
      
    </main>
    <FooterBar />
    </>
  )
}
