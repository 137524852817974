import {
  //  FaExpandAlt,
  FaEdit,
  FaTrashAlt,
  FaRegistered,
} from "react-icons/fa";
// import { Link } from "react-router-dom";
//   import { RidersModal } from '../../../components/RidersModal'
import Modal from "react-bootstrap/Modal";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
// import axios from "axios";
import Spinner from "./spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewStaff,
  updateStaff,
  deleteStaff,
  resetPasswork,
} from "../reducers/adminSlice";
import { siginUser } from "../reducers/userSlice";

export default function RidersTable({ staff }) {
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);
  const [type, settype] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [ErorrPageNumber, setErorrPageNumber] = useState("");
  const [PerPag, setPerPag] = useState(10);
  const [searchName, setSearchName] = useState("");
  // const [states, setStates] = useState([])
  const [lgas, setLga] = useState([]);
  const [eventType, seteventType] = useState([]);

  const userSignin = useSelector(siginUser);
  const { user } = userSignin;
  // const { token } = user;
  const { user: loggedInUser } = user;
  const { access_state: states } = loggedInUser;
  // console.log(states);

  const { staff: adminstaff, loading } = staff;
  const { data } = adminstaff;

  const [newPass, setNewPass] = useState("");
  const [passShow, setpassShow] = useState(false);
  const passhandleShow = () => setpassShow(true);

  const dispatch = useDispatch();

  // const backend_url = process.env.REACT_APP_API;

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    seteventType("");
    setModalData({
      last_name: "",
      first_name: "",
      address: "",
      email: "",
      username: "",
      passwor: "",
      confirm_password: "",
      state: "",
      lga: "",
      id: "",
      // region: "",
    });
  };

  const passhandleClose = () => {
    setpassShow(false);

    setModalData({
      id: "",
    });
  };

  function generateRandomPassword() {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789@#$%&*_?";
    const uppercaseCharset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercaseCharset = "abcdefghijklmnopqrstuvwxyz";
    const numberCharset = "123456789";
    const specialCharset = "@#$%&*_?";

    let result = "";

    // Generate first character (not a special character or number)
    const firstCharSet = uppercaseCharset + lowercaseCharset;
    result += firstCharSet[Math.floor(Math.random() * firstCharSet.length)];

    // Ensure at least 1 character from each category
    result +=
      uppercaseCharset[Math.floor(Math.random() * uppercaseCharset.length)];
    result +=
      lowercaseCharset[Math.floor(Math.random() * lowercaseCharset.length)];
    result += numberCharset[Math.floor(Math.random() * numberCharset.length)];
    result += specialCharset[Math.floor(Math.random() * specialCharset.length)];

    // Generate remaining characters
    for (let i = 5; i < 7; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset[randomIndex];
    }

    // Shuffle the characters
    let shuffledPassword = result
      .split("")
      .sort(() => 0.5 - Math.random())
      .join("");
    const randomUppercase = Math.floor(Math.random() * uppercaseCharset.length);
    shuffledPassword = uppercaseCharset[randomUppercase] + shuffledPassword;

    setNewPass(shuffledPassword);
  }

  const passData = (d) => {
    generateRandomPassword();
    setModalData(d);

    passhandleShow();
  };

  const [modalData, setModalData] = useState({
    last_name: "",
    first_name: "",
    email: "",
    username: "",
    password: "",
    confirm_password: "",
    lga: "",
  });

  const {
    last_name,
    first_name,
    email,
    password,
    lga,
    state,
    address,
    confirm_password,
    username,
    id,
  } = modalData;

  const handleEdit = (e) => {
    setModalData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const editData = (d) => {
    setModalData(d);
    seteventType("edit");
    handleShow();
  };

  //  Admin create users details
  const saveChanges = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      if (confirm_password !== password) {
        alert("Passwords don't match!");
      } else {
        dispatch(
          addNewStaff({
            last_name,
            first_name,
            email,
            username,
            password,
            state,
            lga,
            type,
            address,
          })
        ).unwrap();

        Swal.fire("updated successful");
      }
    } catch (err) {
      console.error("Failed to save the post", err);
      setLoad(false);
    } finally {
      handleClose();
      setLoad(false);
      setModalData({
        last_name: "",
        first_name: "",
        address: "",
        email: "",
        username: "",
        passwor: "",
        confirm_password: "",
        state: "",
        lga: "",
        region: "",
      });
    }
  };

  //  remove user detail
  const removeData = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");

        dispatch(deleteStaff({ id })).unwrap();
      }
    });
  };

  //  Admin edit users details
  const editChanges = async (e) => {
    e.preventDefault();
    setLoad(true);
    try {
      dispatch(
        updateStaff({
          last_name,
          first_name,
          email,
          username,
          password,
          state,
          lga,
          type,
          id,
          address,
        })
      ).unwrap();
      Swal.fire("updated successful");
    } catch (err) {
      console.error("Failed to save the post", err);
      setLoad(false);
    } finally {
      handleClose();
      setLoad(false);
      setModalData({
        last_name: "",
        first_name: "",
        address: "",
        email: "",
        username: "",
        passwor: "",
        confirm_password: "",
        state: "",
        lga: "",
        region: "",
      });
    }
  };

  useEffect(() => {
    states.forEach((el) => {
      if (el.id === +state) {
        setLga(el.lga);
      }
    });
  }, [state, states]);

  useEffect(() => {
    // const fetchStates = async () => {
    //     const { data } = await axios.get(`${backend_url}/api/state`,
    //     {
    //       headers: { Authorization: `Bearer ${token}` },
    //     }
    //     );
    //     setStates(data)
    //   }
    //   fetchStates()

    settype("rider");
    // setadmin_level('4')
  }, []);

  const Resetpassword = async (e) => {
    // console.log(id);

    try {
      dispatch(
        resetPasswork({
          id,
          newPass,
        })
      ).unwrap();
      passhandleClose();
      Swal.fire("Reset password successful");
    } catch (error) {
      console.log(error);
      passhandleClose();
    }
  };

  const onpagChanged = (e) => {
    let pageNum = Number(e.target.value);
    if (pageNum === isNaN || pageNum < 1) {
      throw new Error("Invalid input OR number!");
    }
    if (pageNum <= 500) {
      setPerPag(pageNum);
      setErorrPageNumber("");
    } else {
      setErorrPageNumber("number to large");
      // console.log('number to large');
    }
  };

  // const onpagChanged = e => setPerPag(Number(e.target.value))

  const addressPerPage = Number(PerPag);
  const pagesVisited = Number(pageNumber) * Number(addressPerPage);
  const addressTotal = _.size(data);
  // const addressTotal = 4;
  // console.log(PerPag);
  // console.log(Error);

  const pageCount = Math.ceil(addressTotal / addressPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // search by name

  const keys = ["first_name", "last_name", "email"];

  const search = (data) => {
    if (data) {
      return data.filter((item) =>
        keys.some((key) =>
          item[key]?.toLowerCase().includes(searchName.toLowerCase())
        )
      );
    }
  };

  return (
    <>
      <div className="alert-waning ">
        <div className="col-12 child alert alert-light ">
          <div className="" data-title="Buttons_wrapper"></div>
          <section>
            <div className="table-responsive mt-2 bg-white p-1">
              <form className="row form-group p-2" action="">
                <div className="col-12">
                  <div className="col-3">
                    <button
                      type="button"
                      className="btn btn-small btn-dark"
                      onClick={handleShow}
                    >
                      Create Verification Officer
                    </button>
                  </div>
                </div>
              </form>

              <div className="alert alert-warning m-2 bold text-uppercase text-center">
                {" "}
                Verification Officer
              </div>
              <div>
                <span className="float-right mb-2"></span>

                <div className="row mb-3 d-flex justify-content-between">
                  {/* <div className="col-1">
                                  <label className="label-control">No/Page</label>
                                  <select name="" onChange={onpagChanged} value={PerPag} className="form-control">
                                  <option value='10'>10</option>
                                  <option value='2'>20</option>
                                  <option value='50'>50</option>
                                  <option value='100'>100</option>
                                  <option value='500'>500</option>
                                  </select>
                              </div> */}

                  <div className="col-2">
                    <span className="danger">{ErorrPageNumber}</span>
                    <label className="label-control">No/Page</label>
                    <input
                      type="number"
                      placeholder="N0"
                      className="form-control"
                      onChange={onpagChanged}
                      value={PerPag}
                    />

                    {/* <input type="number"   onChange={(e) => setPerPag(e.target.value)}/> */}

                    {/* <select name="" onChange={onpagChanged} value={PerPag} className="form-control">
                                  <option value='10'>10</option>
                                  <option value='2'>20</option>
                                  <option value='50'>50</option>
                                  <option value='100'>100</option>
                                  <option value='500'>500</option>
                                  </select> */}
                  </div>

                  <div className="col-3 mt-2">
                    <label className="label-control">search</label>
                    <input
                      type="text"
                      name="mn"
                      placeholder="search by Name or Email"
                      className="form-control"
                      onChange={(e) => setSearchName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <>
                {loading ? (
                  <div
                    className="d-flex justify-content-center"
                    style={{ width: "100%" }}
                  >
                    {" "}
                    <Spinner />
                  </div>
                ) : (
                  <table className="table  fs-13 table-sm">
                    {/* <caption>
                  <span className="text-left">Showing 1 to  of manifest(s)</span>
                    <span className="float-right"></span>
                  </caption> */}
                    <thead>
                      <tr className="bevel text-light">
                        <th>S/N</th>
                        <th>Action</th>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Email</th>
                        <th>Username</th>
                        <th>Home Address</th>
                        <th>LGA</th>
                        <th>State</th>
                      </tr>
                    </thead>

                    <tbody id="t_body">
                      {search(data)
                        ?.slice(pagesVisited, pagesVisited + addressPerPage)
                        ?.map((data, i) => (
                          <tr key={i}>
                            <td className="sorting_1">{++i}</td>
                            <td>
                              <span
                                className="btn-group btn-group-sm"
                                role="group"
                                aria-label="Basic example"
                              >
                                {/* <Link className="btn btn-danger me-2" title="Show Rider" to="/admin/showRiders" type="button" data-bs-target="#exampleModal"><FaExpandAlt /></Link> */}
                                <span
                                  className="btn btn-danger me-2"
                                  title="Edit Rider"
                                  onClick={() => editData(data)}
                                >
                                  {" "}
                                  <FaEdit />
                                </span>
                                {loggedInUser.level === "1" && (
                                  <button
                                    className="btn btn-danger me-2"
                                    title="Reset Rider Password"
                                    onClick={() => passData(data)}
                                  >
                                    <FaRegistered />
                                  </button>
                                )}
                                <button
                                  className="btn btn-danger"
                                  title="Delete Rider"
                                  type="button"
                                  onClick={() => removeData(data.id)}
                                >
                                  {" "}
                                  <FaTrashAlt />
                                </button>
                              </span>
                            </td>
                            <td>{data.first_name}</td>
                            <td>{data.last_name}</td>
                            <td>{data.email}</td>
                            <td>{data.username}</td>
                            <td>
                              {data.address ? data.address : "No address"}{" "}
                            </td>
                            <td>{data.lga_name ?? "No LGA"}</td>
                            <td>{data.state_name}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                // activePage={current_page}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns pt-5"}
                previousLinkClassName={"previousBttn "}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </section>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <form onSubmit={eventType === "edit" ? editChanges : saveChanges}>
            <Modal.Header closeButton>
              <h4 className="modal-title">
                {eventType === "edit"
                  ? "Edit Rider"
                  : eventType === "show"
                  ? "Show Rider Detail"
                  : "Add New Rider"}
              </h4>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <dl className="col-6">
                  <dt>First Name</dt>
                  <dd>
                    <input
                      onChange={handleEdit}
                      name="first_name"
                      value={modalData.first_name}
                      type="text"
                      className="form-control"
                    />
                  </dd>
                </dl>

                <dl className="col-6">
                  <dt>Last Name</dt>
                  <dd>
                    <input
                      onChange={handleEdit}
                      name="last_name"
                      value={modalData.last_name}
                      type="text"
                      className="form-control"
                    />
                  </dd>
                </dl>
              </div>
              <div className="row">
                <dl className="col-6">
                  <dt>Email</dt>
                  <dd>
                    <input
                      onChange={handleEdit}
                      value={modalData.email}
                      name="email"
                      type="text"
                      className="form-control"
                    />
                  </dd>
                </dl>

                <dl className="col-6">
                  <dt>Username</dt>
                  <dd>
                    <input
                      onChange={handleEdit}
                      value={modalData.username}
                      name="username"
                      type="text"
                      className="form-control"
                    />
                  </dd>
                </dl>
              </div>
              <div className="row">
                <dl className="col-6">
                  <dt>Password</dt>
                  <dd>
                    <input
                      onChange={handleEdit}
                      value={modalData.password}
                      name="password"
                      type="password"
                      className="form-control"
                    />
                  </dd>
                </dl>

                <dl className="col-6">
                  <dt>Confirm Password</dt>
                  <dd>
                    <input
                      onChange={handleEdit}
                      value={modalData.confirm_password}
                      name="confirm_password"
                      type="password"
                      className="form-control"
                    />
                  </dd>
                </dl>
              </div>

              <div className="row">
                <dl className="col-6">
                  <dt>Home Address</dt>
                  <dd>
                    <input
                      onChange={handleEdit}
                      value={modalData.address}
                      name="address"
                      type="text"
                      className="form-control"
                    />
                  </dd>
                </dl>
                <dl className="col-6">
                  <dt>Deployed State</dt>
                  <dd>
                    <select
                      className="form-control"
                      onChange={handleEdit}
                      value={modalData.state}
                      name="state"
                    >
                      <option>Deployed State</option>
                      {states?.map((data, i) => {
                        return (
                          <option key={i} value={data.id}>
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                  </dd>
                </dl>

                <dl className="col-6">
                  <dt>LGA</dt>
                  <dd>
                    <select
                      className="form-control"
                      onChange={handleEdit}
                      value={modalData.lga}
                      name="lga"
                    >
                      <option>LGA</option>
                      {lgas?.map((data, i) => {
                        return (
                          <option key={i} value={data.id}>
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                  </dd>
                </dl>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {!load ? (
                <input
                  type="submit"
                  className="btn btn-success"
                  value="Generate"
                />
              ) : (
                <input
                  type="submit"
                  className="btn btn-success"
                  value="Loading..."
                  disabled
                />
              )}
              <button
                type="button"
                className="btn btn-dark"
                onClick={handleClose}
              >
                Close
              </button>
            </Modal.Footer>
          </form>
        </Modal>

        {/* <RidersModal show={} /> */}

        <Modal
          show={passShow}
          onHide={passhandleClose}
          size="lg"
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <form>
            <Modal.Header closeButton>
              <h4 className="modal-title">Rest Passwork</h4>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-2">
                {" "}
                <span className="fw-bolder fs-6 ms-1">{first_name}</span>
                <span className="fw-bolder fs-6 mx-1">{last_name}</span>
                Password would be reset as:{" "}
                <span className="fw-bolder fs-5 ms-1">{newPass}</span>{" "}
              </div>

              <button
                type="button"
                style={{ width: "50%", margin: "auto", background: "green" }}
                className="btn btn-dark d-flex justify-content-center p-2"
                onClick={Resetpassword}
              >
                Reset Password
              </button>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-dark"
                onClick={passhandleClose}
              >
                Close
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </>
  );
}
export { RidersTable };
