import React, { useState, useEffect } from "react";
import { NavigationBar } from "../../../components/navigationbar/NavigationBar.jsx";
import { TopBar } from "../../../components/topbar/TopBar.jsx";
// import Modal from './Modal';
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import _ from "lodash";
// import * as XLSX from 'xlsx';
import { useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router";
import { siginUser } from "../../../reducers/userSlice.js";
// import {FooterBar} from '../../../components/general_footer/footer'
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Spinner from "../../../components/spinner/Spinner.jsx";
// import React, { useState } from 'react';

function UploadCsv() {
  const { filename } = useParams();
  const userSignin = useSelector(siginUser);
  const { user } = userSignin;
  const { token } = user;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [PerPag, setPerPag] = useState(100);
  const backend_url = process.env.REACT_APP_API;

  const saveData = async () => {
    setLoading(true);
    let deducted = data.splice(0, PerPag);
    const myDatas = { perPage: PerPag, filename: filename, status: "upload", addresses: deducted, rem: data.length };
    // console.log(myDatas);
    const response = await axios
      .post(`${backend_url}/api/address/uploads`, myDatas, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .catch((error) => {
        setLoading(false);
        setData([...deducted, ...data]);
        let capErrors = Object.keys(error.response.data.errors);
        let capErrorWords = "-- ";
        capErrors.forEach((element) => {
          let errArr = element.split(".");
          let lineNumb = parseInt(errArr[1]) + 1;
          capErrorWords += `Line ${lineNumb} ${errArr[2]} is required -- `;
          // console.log(Object.keys(element)[0]);
        });
        // alert(capErrorWords);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${capErrorWords}`,
          // footer: '<a href="">Why do I have this issue?</a>'
        });
        // console.log(capErrors);
      });
    // console.log(response);
    if (response && response.status) {
      // console.log(response);
      // alert(response.data.message);

      Swal.fire({
        icon: "success",
        // title: 'Oops...',
        text: `${response.data.message}`,
        // footer: '<a href="">Why do I have this issue?</a>'
      });

      setLoading(false);
      setData((currentData) => currentData);
      if (!data.length) {
        navigate("/admin/uploads");
      }
      // console.log(data.length);
    }
  };

  useEffect(() => {
    function getDifference(array1, array2) {
      if (!array2.length) {
        return array1;
      }
      return array1.filter((object1) => {
        return !array2.some((object2) => {
          // return object1.id === object2.id;
          return object1.SURNAME === object2.last_name && object1.FIRSTNAME === object2.first_name && object1.OTHERNAME === object2.other_name && object1.PHONE === object2.phone;
        });
      });
    }

    const getData = async () => {
      setLoading(true);
      const response = await axios
        .get(`${backend_url}/api/address/saved/${filename}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((error) => {
          // console.log(error);
        });
      // if (response && response.data.address) {
      //Remove duplicate rows
      const uniqueIds = new Set();
      const unique = response.data.original_address.filter((element) => {
        const isDuplicate = uniqueIds.has(element.SN);
        uniqueIds.add(element.SN);
        if (!isDuplicate) {
          return true;
        }
        return false;
      });
      // }
      const diffAddress = getDifference(unique, response.data.up_address);
      // console.log(diffAddress);
      setData(diffAddress);
      setLoading(false);
      // console.log("me");
    };
    getData();
  }, [backend_url, token, filename]);

  const onpagChanged = (e) => setPerPag(Number(e.target.value));

  const [pageNumber, setPageNumber] = useState(0);

  const addressPerPage = PerPag;
  const pagesVisited = pageNumber * addressPerPage;
  const addressTotal = _.size(data);
  // const addressTotal = 4;

  const pageCount = Math.ceil(addressTotal / addressPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState({
    SN: "",
    SURNAME: "",
    FIRSTNAME: "",
    OTHERNAME: "",
    LGA: "",
    STATE: "",
    CITY: "",
    LANDMARK: "",
    STATUS: "",
    PHONE: "",
  });

  const handleEdit = (e) => {
    setModalData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const editData = (d) => {
    setModalData(d);
    handleShow();
  };

  const saveChanges = () => {
    const index = data.findIndex((object) => {
      return object.SN === modalData.SN;
    });
    data[index] = modalData;
    setData((currentData) => currentData);
    setModalData({
      SN: "",
      SURNAME: "",
      FIRSTNAME: "",
      OTHERNAME: "",
      LGA: "",
      STATE: "",
      CITY: "",
      LANDMARK: "",
      STATUS: "",
      PHONE: "",
    });
    // console.log(modalData, data, index);
    handleClose();
  };

  const [sidebarWidth, setSidebarWidth] = useState("sidebar-width");
  const [mainWidth, setMainWidth] = useState("ml-10");
  const toggleNavbar = () => {
    sidebarWidth === "sidebar-width" ? setSidebarWidth("") : setSidebarWidth("sidebar-width");
    mainWidth === "ml-10" ? setMainWidth("") : setMainWidth("ml-10");
  };

  return (
    <>
      <NavigationBar sidebarWidth={sidebarWidth} />
      <main className={`content  ${mainWidth}`}>
        <TopBar toggleNavbar={toggleNavbar} />
        <div className="all">
          <div className="py-4">
            <div aria-label="breadcrumb" className="long  d-inline-block">
              <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                <li className="breadcrumb-item">
                  <Link className="fw-bold text-dark" to="/admin/dashboard">
                    <svg className="fw-bold icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                    </svg>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link className="fw-bold text-dark" to="/admin/verificate/upload/excel">
                    <span className="text-dark">Upload Excel</span>
                  </Link>
                </li>
              </ol>
            </div>
          </div>

          <div className="container-fluid">
            <h2 className="text-center">Uploaded Addresses</h2>

            <section>
              <div className="col-12">
                <div className="row">
                  <div className="col-1">
                    <label className="label-control">No/Page</label>
                    <select name="pp" onChange={onpagChanged} value={PerPag} className="form-control">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="2000">2000</option>
                    </select>
                  </div>
                </div>
              </div>
              {/* display modal for error and success */}
              {/* {state.isModalOpen && <Modal modalContent={state.modalContent} />} */}

              <div className="table-responsive mt-2 ">
                <table className="table  fs-13 table-sm table-striped">
                  <thead>
                    <tr className="bevel text-light fw-bold">
                      <th className="text-uppercase">SN</th>
                      <th className="text-uppercase">PHONE</th>
                      <th className="text-uppercase">address</th>
                      <th className="text-uppercase">city</th>
                      <th className="text-uppercase">landmark</th>
                      <th className="text-uppercase">lga</th>
                      <th className="text-uppercase">state</th>
                      <th className="text-uppercase">surname</th>
                      <th className="text-uppercase">firstname</th>
                      <th className="text-uppercase">othername</th>
                      <th className="text-uppercase">status</th>
                      <th className="text-uppercase"></th>
                    </tr>
                  </thead>
                  {loading ? (
                    <div className="d-flex justify-content-center" style={{ width: "100%" }}>
                      {" "}
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      <tbody>
                        {data.slice(pagesVisited, pagesVisited + addressPerPage).map((d) => {
                          return (
                            <tr key={d.SN}>
                              <td> {d.SN} </td>
                              <td> {d.PHONE} </td>
                              <td> {d.ADDRESS} </td>
                              <td> {d.CITY} </td>
                              <td> {d.LANDMARK} </td>
                              <td> {d.LGA} </td>
                              <td> {d.STATE} </td>
                              <td> {d.SURNAME} </td>
                              <td> {d.FIRSTNAME} </td>
                              <td> {d.OTHERNAME} </td>
                              <td> {d.STATUS} </td>
                              <td>
                                {" "}
                                <button className="btn btn-sm btn-danger" onClick={() => editData(d)}>
                                  Edit
                                </button>{" "}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </>
                  )}
                </table>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns pt-5"}
                  previousLinkClassName={"previousBttn "}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
            </section>
            <div className="text-center mt-3">
              <button className="btn btn-dark" disabled={loading || !data.length} onClick={saveData}>
                {!loading && data.length ? "Upload Address" : loading && data ? "Uploading.....!" : "Upload Address"}
              </button>
            </div>
          </div>
        </div>

        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="example-modal-sizes-title-lg">
          <section>
            <Modal.Header closeButton>
              <h4 className="modal-title">Upload</h4>
            </Modal.Header>
            <Modal.Body>
              <section id="editFormModal">
                <div className="row">
                  <dl className="col-6">
                    <dt>First Name</dt>
                    <dd>
                      <input readOnly name="FIRSTNAME" value={modalData.FIRSTNAME} type="text" className="form-control" />
                    </dd>
                  </dl>

                  <dl className="col-6">
                    <dt> SURNAME</dt>
                    <dd>
                      <input readOnly name="SURNAME" value={modalData.SURNAME} type="text" className="form-control" />
                    </dd>
                  </dl>
                </div>
                <div className="row">
                  <dl className="col-6">
                    <dt>OTHERNAME</dt>
                    <dd>
                      <input readOnly value={modalData.OTHERNAME} name="OTHERNAME" type="text" className="form-control" />
                    </dd>
                  </dl>

                  <dl className="col-6">
                    <dt>ADDRESS</dt>
                    <dd>
                      <input onChange={handleEdit} value={modalData.ADDRESS} name="ADDRESS" type="text" className="form-control" />
                    </dd>
                  </dl>
                </div>
                <div className="row">
                  <dl className="col-6">
                    <dt>CITY</dt>
                    <dd>
                      <input onChange={handleEdit} value={modalData.CITY} name="CITY" type="text" className="form-control" />
                    </dd>
                  </dl>

                  <dl className="col-6">
                    <dt>LGA</dt>
                    <dd>
                      <input onChange={handleEdit} value={modalData.LGA} name="LGA" type="text" className="form-control" />
                    </dd>
                  </dl>
                </div>

                <div className="row">
                  <dl className="col-6">
                    <dt>LANDMARK </dt>
                    <dd>
                      <input onChange={handleEdit} value={modalData.LANDMARK} name="LANDMARK" type="text" className="form-control" />
                    </dd>
                  </dl>
                  <dl className="col-6">
                    <dt> STATE</dt>
                    <dd>
                      <input onChange={handleEdit} value={modalData.STATE} name="STATE" type="text" className="form-control" />
                    </dd>
                  </dl>
                </div>
                <div className="row">
                  <dl className="col-6">
                    <dt>PHONE</dt>
                    <dd>
                      <input readOnly value={modalData.PHONE} name="PHONE" type="text" className="form-control" />
                    </dd>
                  </dl>

                  <dl className="col-6">
                    <dt>STATUS</dt>
                    <dd>
                      <select className="form-control" onChange={handleEdit} value={modalData.STATUS} name="STATUS">
                        <option>Good</option>
                        <option>Incomplete</option>
                      </select>
                    </dd>
                  </dl>
                </div>
              </section>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-dark" onClick={handleClose}>
                Close
              </button>
              <button type="button" className="btn bevel" onClick={saveChanges}>
                Save changes
              </button>
            </Modal.Footer>
          </section>
        </Modal>
      </main>
    </>
  );
}

export default UploadCsv;
