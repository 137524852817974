import React, {useState, useEffect} from 'react'
import { NavigationBar } from '../../../components/navigationbar/NewworksNavbar.jsx'
import { NewworksTopbar } from '../../../components/topbar/NewworksTopbar.jsx'
import Moment from 'moment';
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useSelector } from 'react-redux';

import axios from "axios";
import { siginUser } from '../../../reducers/userSlice.js';
import {FooterBar} from '../../../components/general_footer/footer'
import Spinner from "../../../components/spinner/Spinner.jsx"

function Upload() {
  const userSignin = useSelector(siginUser);
  const { user} = userSignin;
  const { token} = user;
  const [loading, setLoading] = useState(false);
  const [upload, setUpload] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const backend_url = process.env.REACT_APP_API;

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const response = await axios.get(`${backend_url}/api/uploads?page=${pageNumber}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      ).catch((e)=>{
        console.log(e);
      });
      // console.log(response);
      if (response && response.data) {
      setUpload(response.data);
     }
    setLoading(false)
    }
    getData(pageNumber);
  }, [backend_url, token, pageNumber]);
  
  const {data, current_page, per_page, total, from } = upload
  
  const pageCount = Math.ceil(Number(total ? total : 0) / (Number(per_page?per_page:1)));

  const changePage = ({ selected }) => {
      let pageNumbe = selected + 1 
    setPageNumber(pageNumbe);
  };
  const [sidebarWidth, setSidebarWidth] = useState('sidebar-width')
  const [mainWidth, setMainWidth] = useState('ml-10')
  const toggleNavbar = ()=>{
      sidebarWidth === 'sidebar-width' ? setSidebarWidth('') : setSidebarWidth('sidebar-width');
      mainWidth === 'ml-10' ? setMainWidth('') : setMainWidth('ml-10');
  }

  return (
    <>
     <NavigationBar sidebarWidth={sidebarWidth}/>
     <main className={`n_content  ${mainWidth}`}>
      <NewworksTopbar  toggleNavbar={toggleNavbar}/>
      <div className="all">
       <div className="py-4">
         <div aria-label="breadcrumb" className="long  d-inline-block">
           <ol className="breadcrumb bg-blueblack-800  breadcrumb-transparent">
             <li className="breadcrumb-item">
               <Link className="fw-bold text-white" to="/newworks/dashboard">
               <svg className="fw-bold icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
               </svg>
               <span>Dashboard</span>
               </Link>
             </li>
             <li className="breadcrumb-item">
               <Link className="fw-bold text-white" to="">
               <span>Upload Excel</span>
               </Link>
             </li>
           </ol>
         </div>
       </div>
        
       <div className="container-fluid">

        <h2 className="text-center">Uploads</h2>

        <section>

          <div className="table-responsive mt-2 ">
            <table className="table  fs-13 table-sm table-striped">
                <thead>
                  <tr className="bg-blueblack-800 text-light fw-bold">
                    <th className="text-uppercase">sn</th>
                    <th className="text-uppercase">filename</th>
                    <th className="text-uppercase">Pushed From</th>
                    <th className="text-uppercase">Pushed Date</th>
                    <th className="text-uppercase">Uploaded By</th>
                    <th className="text-uppercase">Uploaded Date</th>
                    <th className="text-uppercase">status</th>
                    {/* <th className="text-uppercase">Total addresses</th>
                    <th className="text-uppercase">Total Verified</th>
                    <th className="text-uppercase">Total Incomplete</th>
                    <th className="text-uppercase">Total unverified</th> */}
                  </tr>
                </thead>
                {loading
                        ? <div  className="d-flex justify-content-center" style={{ width:"100%"}}> <Spinner  /></div>
                        :
                        <>
                <tbody>
                {data && data.map((d, index)=>{
                      return <tr key={d.id}>
                          <td>{index + from} </td>
                          <td className="text-upload">{d.filename} </td>
                          <td className="text-uppercase">{d.push_from} </td>
                          <td>{d.updated_by} </td>
                          <td>{ Moment(d.created_at).format('YYYY-MM-DD h:mm:ss a') } </td>
                          <td>{ Moment(d.updated_at).format('YYYY-MM-DD h:mm:ss a') } </td>
                          <td>{d.status} </td>
                          {/* <td>0</td>
                          <td>0</td> 
                          <td>0</td>
                          <td>0</td> */}
                      </tr>	 					
                    })
                  } 
                </tbody>

                  
                </> 
                  }
              </table>
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                activePage={current_page}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns pt-5"}
                previousLinkClassName={"previousBttn "}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
          </div>
        </section>
       </div>



      </div>
      <FooterBar />
     </main>
    </>
  )
}

export default Upload