import axios from "axios";
import Moment from "moment";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { siginUser } from "../../../reducers/userSlice";
import { TopBar } from "../../../components/topbar/TopBar";
import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { incompleteCount } from "../../../reducers/incompleteSlice.js";
import { NavigationBar } from "../../../components/navigationbar/NavigationBar";
import "./unassignedManifest.css";
import { FooterBar } from "../../../components/general_footer/footer";

export default function Unassigned() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userSignin = useSelector(siginUser);
  const { user } = userSignin;
  const { token } = user;
  const { user: loggedInUser } = user;
  // const { access_state: states } = loggedInUser;

  const [searchParams] = useSearchParams();
  const stateId = searchParams.get("s");

  const backend_url = process.env.REACT_APP_API;
  const [lgas, setLga] = useState([]);
  const [rider, setRider] = useState([]);
  const [send, setSend] = useState(false);
  const [riderId, setRiderId] = useState("");
  const [lgaData, setLGAData] = useState([]);
  const [addresss, setAddress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchLga, setSearchLga] = useState("");
  const [states, setState] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchState, setSearchstate] = useState("");
  const [searchPerPage, setSearchPerPage] = useState(10);
  const [checkedAddress, setCheckedAddress] = useState([]);
  const [searchLandmark, setSearchLandmark] = useState("");
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [currentState, setCurrentState] = useState(stateId);
  const [searchPageNumber, setSearchPageNumber] = useState(1);
  const [checkedAddressId, setCheckedAddressId] = useState([]);
  const [checkedAllAddress, setCheckedAllAddress] = useState(false);

  const { current_page, per_page, total } = addresss;
  // console.log(addresss);
  const pageCount = Math.ceil(Number(total ? total : 0) / Number(per_page ? per_page : 1));

  const changePage = ({ selected }) => {
    let pageNumbe = selected + 1;
    if (searchState || searchLga || searchLandmark) {
      setSend(true);
      setSearchPageNumber(pageNumbe);
    } else {
      setPageNumber(pageNumbe);
    }
    setCheckedAllAddress(false);
  };

  const [modalData, setModalData] = useState({
    id: "",
    last_name: "",
    first_name: "",
    other_name: "",
    lga: "",
    state: "",
    city: "",
    territory: "",
    address: "",
    status: "",
    phone: "",
  });

  const { last_name, first_name, other_name, lga, id, status, phone, state, territory } = modalData;

  const [show, setShow] = useState(false);
  const [editshow, seteditShow] = useState(false);

  // edithandleShow
  const edithandleShow = () => seteditShow(true);
  const edithandleClose = () => seteditShow(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const { data } = await axios.post(
      `${backend_url}/api/manifest/create`,
      {
        ids: checkedAddressId,
        rider_id: riderId,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setShow(false);
    setLoading(false);
    navigate(`/admin/verificate/manifest?m=${data.manifest_no}`);
  };

  const checkedAddresses = (data) => {
    setRiderId("");
    if (checkedAddress.includes(data)) {
      setCheckedAddress((current) =>
        current.filter((checkedAddress) => {
          return checkedAddress.id !== data.id;
        })
      );
      setCheckedAddressId((current) =>
        current.filter((checkedAddressId) => {
          return checkedAddressId !== data.id;
        })
      );
    } else {
      setCheckedAddress((current) => [...current, data]);
      setCheckedAddressId((current) => [...current, data.id]);
    }
  };

  const checkedAllAddresses = (data) => {
    setRiderId("");
    if (checkedAllAddress) {
      data.forEach((element) => {
        setCheckedAddress((current) =>
          current.filter((e) => {
            return e !== element;
          })
        );
        setCheckedAddressId((current) =>
          current.filter((e) => {
            return e !== element.id;
          })
        );
      });
    } else {
      data.forEach((element) => {
        if (checkedAddressId.includes(element.id)) {
          setCheckedAddress((current) =>
            current.filter((e) => {
              return e !== element;
            })
          );
          setCheckedAddressId((current) =>
            current.filter((e) => {
              return e !== element.id;
            })
          );
        }
        setCheckedAddress((current) => [...current, element]);
        setCheckedAddressId((current) => [...current, element.id]);
      });
    }
  };

  const [sidebarWidth, setSidebarWidth] = useState("sidebar-width");
  const [mainWidth, setMainWidth] = useState("ml-10");
  const toggleNavbar = () => {
    sidebarWidth === "sidebar-width" ? setSidebarWidth("") : setSidebarWidth("sidebar-width");
    mainWidth === "ml-10" ? setMainWidth("") : setMainWidth("ml-10");
  };

  useEffect(() => {
    const fetchAddress = async () => {
      const addressData = await axios.get(`${backend_url}/api/address/unassigned/${stateId}?page=${pageNumber}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAddress(addressData.data);
    };

    fetchAddress();
  }, [token, backend_url, stateId, pageNumber]);

  useEffect(() => {
    const fetchRider = async () => {
      const riderData = await axios.get(`${backend_url}/api/user/rider/manifest`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRider(riderData.data);
    };

    const fetchStates = async () => {
      const { data } = await axios.get(`${backend_url}/api/state`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setState(data);
    };

    fetchRider();
    fetchStates();
  }, [token, backend_url]);

  const addSearchstate = (name) => {
    setSearchstate(name);
    if (name !== "") {
      states.forEach((el) => {
        if (el.name === name) {
          setLga(el.lga);
        }
      });
    } else {
      setSearchLga("");
    }
  };

  const getSearchData = useCallback(
    async (event) => {
      event && event.preventDefault();
      if (send) {
        if (searchState !== "" && searchLga !== "" && searchLandmark !== "") {
          setSearchstate(stateId);
        }
        setLoadingSearch(true);
        const { data } = await axios.post(
          `${backend_url}/api/address/search/unassigned/manifest?page=${searchPageNumber}`,
          {
            state: searchState,
            lga: searchLga,
            landmark: searchLandmark,
            perPage: searchPerPage,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setLoadingSearch(false);
        setCurrentState(searchState);
        setAddress(data);
      }
    },
    [backend_url, token, send, searchPageNumber, searchState, searchLga, searchLandmark, searchPerPage, stateId]
  );

  useEffect(() => {
    getSearchData();
    return () => {
      setSend(false);
    };
  }, [searchPageNumber, getSearchData]);

  const editData = (d) => {
    setModalData(d);
    edithandleShow();
    getStateLga(d.state);
  };

  const handleEdit = (e) => {
    setModalData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    e.target.name === "state" && getStateLga(e.target.value);
  };

  const getStateLga = (name) => {
    states.map((data, i) => {
      return data.name.toLowerCase() === name.toLowerCase() && setLGAData(data.lga);
    });
  };

  const edithandleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await axios.put(
        `${backend_url}/api/addresses/${id}`,
        {
          last_name,
          first_name,
          other_name,
          lga,
          phone,
          status,
          state,
          territory,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const index = addresss.data.findIndex((object) => {
        return object.id === modalData.id;
      });
      addresss.data[index] = modalData;
      if (status === "incomplete") {
        setCheckedAddress((current) =>
          current.filter((checkedAddress) => {
            return checkedAddress.id !== id;
          })
        );
        setCheckedAddressId((current) =>
          current.filter((checkedAddressId) => {
            return checkedAddressId !== id;
          })
        );
      }
      dispatch(incompleteCount());
      setAddress((currentData) => currentData);

      setLoading(false);
    } catch (err) {
      console.error("Failed to save the post", err);
    } finally {
      seteditShow(false);
      setLoading(false);
    }
  };

  return (
    <>
      <NavigationBar sidebarWidth={sidebarWidth} />
      <main className={`content  ${mainWidth}`}>
        <TopBar toggleNavbar={toggleNavbar} />
        <div className="all">
          <div className="py-4">
            <div className="long d-none d-md-inline-block">
              <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                <li className="breadcrumb-item">
                  <Link className="font-weight-bolder text-dark" to="/admin/dashboard">
                    <svg className="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                    </svg>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <span className="font-weight-bolder text-dark">
                    <span className="text-capitalize">Un-Assigned</span>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/admin/verificate/unassigned/zone`} className="font-weight-bolder text-dark">
                    <span className="text-capitalize">Zone</span>
                  </Link>
                </li>
                {
                  <li className="breadcrumb-item">
                    <Link to={`/admin/verificate/unassigned_region/region?id=${loggedInUser.region.zone}`} className="font-weight-bolder text-dark">
                      <span className="text-capitalize">Region</span>
                    </Link>
                  </li>
                }
                {
                  <li className="breadcrumb-item">
                    <Link to={`/admin/verificate/unassigned_state/state?id=${loggedInUser.region.id}`} className="font-weight-bolder text-dark">
                      <span className="text-capitalize">State</span>
                    </Link>
                  </li>
                }
                <li className="breadcrumb-item">
                  <span className="font-weight-bolder text-dark">
                    <span className="text-capitalize">Manifest</span>
                  </span>
                </li>
              </ol>
            </div>
          </div>

          <div className="alert-waning ">
            <div className="col-12 child alert alert-light ">
              <div className="" data-title="Buttons_wrapper"></div>

              <section>
                <div className="table-responsive mt-2 bg-white p-1">
                  <form className="row form-group p-2" onSubmit={getSearchData}>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-1">
                          <label className="label-control">Per Page</label>
                          <input
                            list="per_page"
                            className="form-control"
                            value={searchPerPage}
                            onChange={(e) => {
                              setSearchPerPage(e.target.value);
                              setSearchPageNumber(1);
                            }}
                          />
                          <datalist id="per_page">
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                          </datalist>
                        </div>

                        <div className="col-3">
                          <label className="label-control">Select State</label>
                          <select
                            className="form-control"
                            value={searchState}
                            onChange={(e) => {
                              addSearchstate(e.target.value);
                              setSearchPageNumber(1);
                            }}
                          >
                            <option value="">Choose State</option>
                            {states.map((data, i) => {
                              return (
                                <option key={i} value={data.name}>
                                  {data.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="col-3">
                          <label className="label-control">Select LGA</label>
                          <select
                            className="form-control"
                            value={searchLga}
                            onChange={(e) => {
                              setSearchLga(e.target.value);
                              setSearchPageNumber(1);
                            }}
                          >
                            <option value="">Choose LGA</option>
                            {lgas.map((data, i) => {
                              return (
                                <option key={i} value={data.name}>
                                  {data.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="col-3">
                          <label className="label-control">LAND MARK/CITY</label>
                          <input
                            type="text"
                            placeholder="Type an Area within State"
                            className="form-control"
                            onChange={(e) => {
                              setSearchLandmark(e.target.value);
                              setSearchPageNumber(1);
                            }}
                          />
                        </div>

                        <div className="col-2">
                          <label className="label-control">&nbsp;</label>
                          {!loadingSearch ? (
                            <input
                              type="submit"
                              className="form-control btn btn-small bevel"
                              value="Fetch"
                              onClick={() => {
                                setSearchPageNumber(1);
                                setSend(true);
                              }}
                            />
                          ) : (
                            <input type="submit" className="form-control btn btn-small bevel" value="Loading..." disabled />
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="alert alert-warning m-2 bold text-uppercase text-center">{currentState} Manifests</div>
                  <div>
                    <span className="float-right mb-2"></span>
                  </div>
                  <table className="table  fs-13 table-sm">
                    {addresss.data && (
                      <caption>
                        <span className="text-left">
                          Showing {addresss.from} to {addresss.to} of {addresss.total} manifest(s)
                        </span>
                        <span className="float-right"></span>
                      </caption>
                    )}
                    <thead>
                      <tr className="bevel text-light">
                        <th className="">
                          <input
                            type="checkbox"
                            checked={checkedAllAddress}
                            onChange={() => {
                              checkedAllAddresses(addresss.data);
                              setCheckedAllAddress(!checkedAllAddress);
                            }}
                          />
                        </th>
                        <th>Address</th>
                        <th>City</th>
                        <th>Area/LandMark</th>
                        <th>L.G.A</th>
                        <th>State</th>
                        <th>Surname</th>
                        <th>FirstName</th>
                        <th>OtherName</th>
                        <th>Date</th>
                        <th>Creation State</th>
                        <th>Edit</th>
                      </tr>
                    </thead>

                    <tbody id="t_body">
                      {addresss.data &&
                        addresss.data.map((data, i) => {
                          return (
                            <tr key={i}>
                              <td className="sorting_1">
                                {data.status !== "incomplete" && (
                                  <input
                                    type="checkbox"
                                    checked={checkedAddressId.includes(data.id)}
                                    onChange={() => {
                                      checkedAddresses(data);
                                    }}
                                  />
                                )}
                              </td>
                              <td>{data.address}</td>
                              <td>{data.city}</td>
                              <td>{data.territory}</td>
                              <td>{data.lga}</td>
                              <td>{data.state}</td>
                              <td>{data.first_name}</td>
                              <td>{data.last_name}</td>
                              <td>{data.other_name}</td>
                              <td>{Moment(data.created_at).format("YYYY-MM-DD h:mm:ss a")}</td>
                              <td>{data.address_creator.states.name}</td>
                              <th>
                                {" "}
                                <i className="fa fa-book btn btn-danger" onClick={() => editData(data)}>
                                  Edit
                                </i>
                              </th>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    activePage={current_page}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns pt-5"}
                    previousLinkClassName={"previousBttn "}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  />
                </div>
                <div className="mt-5">
                  {checkedAddressId.length !== 0 ? (
                    <span className="fa fa-book btn btn-danger" onClick={handleShow}>
                      Create Manifest
                    </span>
                  ) : (
                    <input type="text" className="fa fa-book btn btn-danger" disabled value="Create Manifest" />
                  )}
                </div>
              </section>
            </div>
          </div>
          <FooterBar />
        </div>

        <Modal show={editshow} onHide={edithandleClose} size="lg" aria-labelledby="example-modal-sizes-title-lg">
          <form onSubmit={edithandleSubmit}>
            {/* <form> */}
            <Modal.Header closeButton>
              <h4 className="modal-title">New Manifest</h4>
            </Modal.Header>
            <Modal.Body>
              <section id="editFormModal">
                <div className="row">
                  <dl className="col-6">
                    <dt>First Name</dt>
                    <dd>
                      <input onChange={handleEdit} name="first_name" value={modalData.first_name} type="text" className="form-control" />
                    </dd>
                  </dl>

                  <dl className="col-6">
                    <dt> SURNAME</dt>
                    <dd>
                      <input onChange={handleEdit} name="last_name" value={modalData.last_name} type="text" className="form-control" />
                    </dd>
                  </dl>
                </div>
                <div className="row">
                  <dl className="col-6">
                    <dt>OTHERNAME</dt>
                    <dd>
                      <input onChange={handleEdit} value={modalData.other_name} name="other_name" type="text" className="form-control" />
                    </dd>
                  </dl>

                  <dl className="col-6">
                    <dt>ADDRESS</dt>
                    <dd>
                      <input onChange={handleEdit} value={modalData.address} name="address" type="text" className="form-control" />
                    </dd>
                  </dl>
                </div>
                <div className="row">
                  <dl className="col-6">
                    <dt>CITY</dt>
                    <dd>
                      <input onChange={handleEdit} value={modalData.city} name="city" type="text" className="form-control" />
                    </dd>
                  </dl>
                  <dl className="col-6">
                    <dt>LANDMARK </dt>
                    <dd>
                      <input onChange={handleEdit} value={modalData.territory} name="territory" type="text" className="form-control" />
                    </dd>
                  </dl>
                </div>

                <div className="row">
                  <dl className="col-6">
                    <dt> STATE</dt>
                    <dd>
                      <select className="form-control" value={modalData.state} name="state" onChange={handleEdit}>
                        <option value="">Choose State</option>
                        {states.map((data, i) => {
                          return (
                            <option key={i} value={data.name.toUpperCase()}>
                              {data.name}
                            </option>
                          );
                        })}
                      </select>
                    </dd>
                  </dl>
                  <dl className="col-6">
                    <dt>LGA</dt>
                    <select className="form-control" value={modalData.lga} name="lga" onChange={handleEdit}>
                      <option value="">Choose LGA </option>
                      {lgaData.map((data, i) => {
                        return (
                          <option key={i} value={data.name.toUpperCase()}>
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                  </dl>
                </div>
                <div className="row">
                  <dl className="col-6">
                    <dt>PHONE</dt>
                    <dd>
                      <input onChange={handleEdit} value={modalData.phone} name="phone" type="text" className="form-control" />
                    </dd>
                  </dl>

                  <dl className="col-6">
                    <dt>STATUS</dt>
                    <dd>
                      <select className="form-control" onChange={handleEdit} value={modalData.status} name="status">
                        <option value="good">Good</option>
                        <option value="incomplete">Incomplete</option>
                      </select>
                    </dd>
                  </dl>
                </div>
              </section>
            </Modal.Body>
            <Modal.Footer>
              {!loading ? <input type="submit" className="btn btn-success" value="update" /> : <input type="submit" className="btn btn-success" value="Loading..." disabled />}
              <button type="button" className="btn btn-dark" onClick={edithandleClose}>
                Close
              </button>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="example-modal-sizes-title-lg">
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <h4 className="modal-title">New Manifest</h4>
            </Modal.Header>
            <Modal.Body>
              <div id="error" className="row alert alert-danger d-none text-center"></div>
              <article className="container mb-2" id="manifest">
                <div className="row alert alert-danger">
                  <div className="col-4">
                    <label className="hundred bold p-1">Manifest Type:</label>
                    <select className="form-control" disabled>
                      <option>Verification</option>
                    </select>
                  </div>
                  <div className="col-4">
                    <label className="hundred bold p-1">Select Dispatch Rider: </label>
                    <select className="form-control" value={riderId} onChange={(e) => setRiderId(e.target.value)} required>
                      <option value="">Choose Rider</option>
                      {rider.map((data, i) => {
                        return (
                          <option key={i} value={data.id}>
                            {/* disabled={data.rider_inprogress_address_count + checkedAddressId.length >= 500}> */}
                            {data.first_name} {data.last_name}
                            {/* -- {data.rider_inprogress_address_count} Un-Verified Address */}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table table-bordered order-table table-sm fs-13" id="table_id">
                    <thead>
                      <tr>
                        <th>Applicant Surname</th>
                        <th>Applicant Firstname</th>
                        <th>Address</th>
                        <th>Town</th>
                        <th>LGA</th>
                        <th>State</th>
                        {/* <th>AWB</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {checkedAddress &&
                        checkedAddress.map((data, i) => {
                          return (
                            <tr key={i}>
                              <td>{data.first_name}</td>
                              <td>{data.last_name}</td>
                              <td>{data.address}</td>
                              <td>{data.territory}</td>
                              <td>{data.lga}</td>
                              <td>{data.state}</td>
                              {/* <td>NIB/GWP/</td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </article>
            </Modal.Body>
            <Modal.Footer>
              <h4 style={{ flex: "auto" }}>{checkedAddressId.length} Addresses</h4>
              {!loading ? <input type="submit" className="btn btn-success" value="Generate" disabled={!checkedAddressId.length} /> : <input type="submit" className="btn btn-success" value="Loading..." disabled />}
              <span
                type="submit"
                className="btn btn-danger"
                onClick={() => {
                  setCheckedAddress([]);
                  setCheckedAddressId([]);
                  setCheckedAllAddress(false);
                }}
              >
                Clear All
              </span>
              <button type="button" className="btn btn-dark" onClick={handleClose}>
                Close
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </main>
    </>
  );
}
