import React, {useState, useEffect} from 'react'
import { NavigationBar } from '../../../components/navigationbar/NavigationBar.jsx'
import { TopBar } from '../../../components/topbar/TopBar.jsx'
import Moment from 'moment';
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useSelector } from 'react-redux';
import axios from "axios";
import { siginUser } from '../../../reducers/userSlice.js';
import {FooterBar} from '../../../components/general_footer/footer'
import './UploadExcel.css'

const Uploading = () => {
    const userSignin = useSelector(siginUser);
    const { user} = userSignin;
    const { token} = user;
    
    const [upload, setUpload] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);


    const [sidebarWidth, setSidebarWidth] = useState('sidebar-width')
    const [mainWidth, setMainWidth] = useState('ml-10')
    const toggleNavbar = ()=>{
        sidebarWidth === 'sidebar-width' ? setSidebarWidth('') : setSidebarWidth('sidebar-width');
        mainWidth === 'ml-10' ? setMainWidth('') : setMainWidth('ml-10');
    }


    const backend_url = process.env.REACT_APP_API;

    useEffect(() => {
        const getData = async () => {
            const response = await axios.get(`${backend_url}/api/uploads?page=${pageNumber}`,
                {
                headers: { Authorization: `Bearer ${token}` },
                }
            ).catch((e)=>{
                console.log(e);
            });
        
            setUpload(response.data);
        }
        getData(pageNumber);
    }, [backend_url, token, pageNumber]);
    
    const {data, current_page, per_page, total, from } = upload
  
    const pageCount = Math.ceil(Number(total ? total : 0) / (Number(per_page?per_page:1)));
  
    const changePage = ({ selected }) => {
        let pageNumbe = selected + 1 
      setPageNumber(pageNumbe);
    };

    return (
        <>
        <NavigationBar sidebarWidth={sidebarWidth}/>
        <main className={`content  ${mainWidth}`}>
        <TopBar toggleNavbar={toggleNavbar}/>
        <div className="all">
        <div className="py-4">
            <div aria-label="breadcrumb" className="long  d-inline-block">
            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                <li className="breadcrumb-item">
                <Link className="fw-bold text-dark" to="/admin/dashboard">
                <svg className="fw-bold icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                </svg>
                <span>Dashboard</span>
                </Link>
                </li>
                <li className="breadcrumb-item">
                {/* <Link className="fw-bold text-dark" to="/admin/verificate/upload/excel"> */}
                <span className="text-dark">Pending Uploads</span>
                {/* </Link> */}
                </li>
            </ol>
            </div>
        </div>
            
        <div className="container-fluid">

            {/* <h2 className="text-center">Uploads</h2> */}

            <div  className="alert-waning ">
                <div  className="col-12 child alert alert-light ">
                    <section>
                        <div className="table-responsive mt-2 bg-white p-1">
                            <Link className="nav-link" to= "excel">
                                <span className="btn btn-dark sidebar-text text-light">Upload Excel</span>
                            </Link>

                            <div className="alert alert-warning m-2 bold text-uppercase text-center">Uploads</div>
                        
                            <table className="table fs-13 table-sm table-striped">
                                <thead>
                                <tr className="bevel text-light fw-bold">
                                    <th className="text-uppercase">sn</th>
                                    <th className="text-uppercase">filename</th>
                                    <th className="text-uppercase">Pushed From</th>
                                    <th className="text-uppercase">Pushed Date</th>
                                    <th className="text-uppercase">Uploaded By</th>
                                    <th className="text-uppercase">Uploaded Date</th>
                                    <th className="text-uppercase">status</th>
                                    <th className="text-uppercase">Total addresses</th>
                                    <th className="text-uppercase">Total Verified</th>
                                    <th className="text-uppercase">Total Incomplete</th>
                                    <th className="text-uppercase">Total unverified</th>
                                </tr>
                                </thead>

                                <tbody>
                                {data && data.map((d, index)=>{
                                    return <tr key={d.id}>
                                            <td>{index + from} </td>
                                            <td>
                                            {d.status === "upload" ? 
                                                <Link className="text-upload" to={`/admin/uploads/${d.filename}`}>
                                                    {d.filename}
                                                </Link> : d.filename }
                                            </td>                    
                                            <td className="text-uppercase">{d.push_from} </td>
                                            <td>{ Moment(d.created_at).format('YYYY-MM-DD h:mm:ss a') } </td>
                                            <td>{d.uploader ? d.uploader?.first_name + " " + d.uploader?.last_name : (d.addresses_count ? "Uploading in progress" : "Not updated yet") } </td>
                                            <td>{ Moment(d.updated_at).format('YYYY-MM-DD h:mm:ss a') } </td>
                                            <td>{d.status} </td>
                                            <td>{d.uploaded_count ? d.addresses_count : 0}</td>
                                            <td>{d.uploaded_count ? d.verified_addresses : 0}</td>
                                            <td>{d.uploaded_count ? d.incomplete_addresses : 0}</td>
                                            <td>{d.uploaded_count ? d.addresses_count - d.verified_addresses - d.incomplete_addresses  : 0}</td>
                                        </tr>	 					
                                    })
                                } 
                                </tbody>

                            </table>
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                activePage={current_page}
                                pageCount={pageCount}
                                onPageChange={changePage}
                                containerClassName={"paginationBttns pt-5"}
                                previousLinkClassName={"previousBttn "}
                                nextLinkClassName={"nextBttn"}
                                disabledClassName={"paginationDisabled"}
                                activeClassName={"paginationActive"}
                            />
                        </div>
                    </section>
                </div>
            </div>


        </div>


        <FooterBar />
        </div>
        </main>
        </>
    )
}

export default Uploading