import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  /* position: relative; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
`;

export const ImageContainer = styled.div`
  width: 95%;
  height: 95%;
  overflow: auto;
  display: flex;
  position: relative;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  /* width: 100%; */
  height: auto;
  
   max-width: 100%; 
  max-height: 100%;  
   object-fit: contain;
  object-position: center;
  z-index: 10;
  transition: transform 0.3s ease-in-out;
  
  &:hover {
    transform: scale(2.5); /* Adjust the value to control the zoom level */
  }
`;

export const ImageSpan = styled.span`
  position: absolute;
  bottom: 8px;
  right: 16px;
  background-color: ghostwhite;
`;

export const BtnDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 150px;
  /* width: 100%; */
  z-index: 150;
  position: absolute;
  bottom: 10px;
`;

export const CloseButton = styled.button`
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.55);
  color: #fff;
`;



// export const Wrapper = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(0, 0, 0, 0.75);
//   z-index: 1500;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// export const Content = styled.div`
//   width: 100%;
//   height: 100%;
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// `;

// export const Image = styled.img`
//   width: 100%;
//   height: auto;
//   max-width: 100%;
//   max-height: 100%;
//   object-fit: contain;
//   object-position: center;
//   z-index: 10;
// `;

// export const ImageSpan = styled.span`
//   position: absolute;
//   bottom: 8px;
//   right: 16px;
//   background-color: ghostwhite;
// `;

// export const BtnDiv = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 150px;
//   width: 100%;
//   z-index: 150;
//   position: absolute;
//   bottom: 10px;
// `;


// export const CloseButton = styled.button`
//  height:70px;
//  width:70px;
//  border-radius: 50%;
//  background: rgba(0,0,0,.55);
//  color:#fff;
// `;