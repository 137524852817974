import React from "react";
import Moment from "moment";
import "../AuditTray.css";
import { useState } from "react";

const AuditTable = ({ data, riders_description, meta, openImage }) => {
  const [activeButton, setActiveButton] = useState(null);

 
  return (
    <>
      <table className="table  fs-14 table-striped ">
        <thead className="fs-11 ">
          <tr className="bevel text-light fw-bold">
            <th className="text-uppercase">s/n</th>
            <th className="text-uppercase">name</th>
            <th className="text-uppercase">address</th>
            <th className="text-uppercase">phone</th>
            <th className="text-uppercase">LGA</th>
            <th className="text-uppercase">city</th>
            <th className="text-uppercase">state</th>
            <th className="text-uppercase">does address exist</th>
            <th className="text-uppercase">does applicant live there</th>
            <th className="text-uppercase">description</th>
            <th className="text-uppercase">remarks</th>
            <th className="text-uppercase">Verified date/time</th>
            <th className="text-uppercase">Uploaded Address</th>
            <th className="text-uppercase">VO</th>
            <th className="text-uppercase">LNG/LAT</th>
            <th className="text-uppercase">images</th>
          </tr>
        </thead>

        <tbody>
          {data &&
            data.map((data, i) => {
              // console.log(data);
              let address_img = "" + data?.trackers?.address_img + "";
              let landmark_img = "" + data?.trackers?.landmark_img + "";
              return (
                <tr role="row" className="odd" key={data.id}>
                  <td className="sorting_1">{i + meta.from}</td>
                  <td>
                    {data.first_name} {data.last_name} {data.other_name}
                  </td>
                  <td>{data.address}</td>
                  <td>{data.phone}</td>
                  <td>{data.lga}</td>
                  <td>{data.city}</td>
                  <td>{data.state}</td>
                  <td>{data.v_status}</td>
                  <td>{data.resident}</td>
                  <td>
                    {riders_description[data.descriptions] ?? data.descriptions}
                  </td>
                  <td>{data.remark}</td>
                  {data?.status.toLowerCase() !== "completed" ? (
                    <td>{"null"}</td>
                  ) : (
                    <td>
                      {data.updated_at.substring(0, 10)}/
                      {data.updated_at.substring(11, 16)}
                    </td>
                  )}
                  <td>
                    <span>{data.trackers?.address ?? "Not Avaliable"}</span>
                  </td>
                  <td>
                    <span>
                      {data.rider?.first_name} {data.rider?.last_name}
                    </span>
                  </td>
                  <td>
                    {data.trackers && data.trackers.latitude ? (
                      <span>
                        {data.trackers.latitude}/{data.trackers.longitude}
                      </span>
                    ) : (
                      <span>Not Avaliable</span>
                    )}
                  </td>

                  <td>
                    {data?.trackers?.address_img ? (
                      <button
                        className={`btn btn-sm btn-success bfs ${
                          activeButton === `LC_${data.id}` ? "Buttactive" : ""
                        }`}
                        title="Location Image"
                        onClick={() => {
                          setActiveButton(`LC_${data.id}`);
                          openImage(
                            data?.trackers?.address_img,
                            Moment(address_img.substring(59, 79)).format(
                              "YYYY-MM-DD"
                            )
                          );
                        }}
                      >
                        LC Image
                      </button>
                    ) : (
                      <span>Not Available</span>
                    )}

                    {data?.trackers?.landmark_img ? (
                      <button
                        className={`btn btn-sm btn-primary my-1 bfs ${
                          activeButton === `LM_${data.id}` ? "Buttactive" : ""
                        }`}
                        title="Landmark Image"
                        onClick={() => {
                          setActiveButton(`LM_${data.id}`);
                          openImage(
                            data?.trackers?.landmark_img,
                            Moment(landmark_img.substring(60, 80)).format(
                              "YYYY-MM-DD"
                            )
                          );
                        }}
                      >
                        LM Image
                      </button>
                    ) : (
                      <span>Not Available</span>
                    )}
                  </td>

                  {/* <td>
                    {data?.trackers?.address_img ? (
                      <button
                        className="btn btn-sm btn-success bfs "
                        title="Location Image"
                        onClick={() =>
                          openImage(
                            data?.trackers?.address_img,
                            Moment(address_img.substring(59, 79)).format(
                              "YYYY-MM-DD"
                            )
                          )
                        }
                      >
                        {" "}
                        LC Image
                      </button>
                    ) : (
                      <span>Not Available </span>
                    )}
                    {data?.trackers?.landmark_img ? (
                      <button
                        className="btn btn-sm btn-primary my-1 bfs"
                        title="Landmark Image"
                        onClick={() =>
                          openImage(
                            data?.trackers?.landmark_img,
                            Moment(landmark_img.substring(60, 80)).format(
                              "YYYY-MM-DD"
                            )
                          )
                        }
                      >
                        LM Image
                      </button>
                    ) : (
                      <span>Not Available </span>
                    )}
                  </td> */}
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

export default AuditTable;
