import axios from "axios";
import React, { useState } from 'react'
import { NavigationBar } from '../../../../components/navigationbar/NavigationBar'
import { TopBar } from '../../../../components/topbar/TopBar'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect} from 'react'
import { siginUser } from "../../../../reducers/userSlice";
import UnassignedTable from "../../../../components/unassignedTable/UnassignedTable";
import {FooterBar} from '../../../../components/general_footer/footer'
// import { getAddress } from '../../../../reducers/addressSlice'



const UnassignedAddressZone = () => {

  const userSignin = useSelector(siginUser);
  // const navigate = useNavigate();
  //  console.log(userSignin)
  const { user} = userSignin;
  // setloggedInUser(user.user)
  const {  token} = user;
  const [loading, setLoading] = useState(false)
  // console.log( loading, error)

  const {type} = useParams();

  const [zone, setZone] = useState([]);
  // const [type2, setType] = useState(type);


  
  // const handleChange = (e) => {
  //   console.log(type);
  //   if (type === 'assigned') {
  //     navigate('/admin/verificate/assigned/zone')
  //   }else if (type === 'unassigned'){
  //     navigate('/admin/verificate/unassigned/zone')
  //   }
  //   // setType(e.target.value)
  // }

  // console.log(type)
  // const typeId = searchParams.get('id')
  // const dispatch = useDispatch();

  const backend_url = process.env.REACT_APP_API;


  const [sidebarWidth, setSidebarWidth] = useState('sidebar-width')
  const [mainWidth, setMainWidth] = useState('ml-10')

  const toggleNavbar = ()=>{
      sidebarWidth === 'sidebar-width' ? setSidebarWidth('') : setSidebarWidth('sidebar-width');
      mainWidth === 'ml-10' ? setMainWidth('') : setMainWidth('ml-10');
  }
  // useEffect(() => {

  //   let cancel = false
    
  
  //   return () => {
  //     cancel = true
  //   }
  // }, [])
  

  useEffect(() => {

    const fetchZones = async () => {
      setLoading(true)
      const zoneData = await axios.get(`${backend_url}/api/zone`, 
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      ).catch(() => {

      });
      if (zoneData && zoneData.data) {
        // setData(response.data);
        setZone(zoneData.data.data)
      }
      setLoading(false)
      // setZone(zoneData.data.data)
    
      // setZone(zoneData.data.data)
    }
    fetchZones();
    

  }, [token,backend_url])
  


  // console.log(zone)
  return (
    <>
    <NavigationBar sidebarWidth={sidebarWidth}/>
    <main className={`content ${mainWidth}`}>
    <TopBar toggleNavbar={toggleNavbar}/>
      <div className="all">
      <div className="py-4">
          <div aria-label="breadcrumb" className="long d-none d-md-inline-block">
            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
              <li className="breadcrumb-item">
                <Link className="font-weight-bolder text-dark" to="/admin/dashboard">
                  <svg className="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                  </svg>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <span className="font-weight-bolder text-dark">
                  {/* <select className='text-capitalize' value={type} onChange={handleChange}>
                    <option value="assigned">Assigned</option>
                    <option value="unassigned">UnAssigned</option>
                  </select> */}
                  <span className='text-capitalize'>UnAssigned</span>
                </span>
              </li>
              <li className="breadcrumb-item">
                {/* <Link to={`/admin/verificate/${manifest}/zone`} className="font-weight-bolder text-dark">
                  <span className='text-capitalize'>Zone</span>
                </Link> */}
              </li>
           
            
              <li className="breadcrumb-item"></li>
            </ol>
          </div>
      </div>
      <UnassignedTable  datas={zone} type={type} loading={loading} />
      <FooterBar />
      </div>
     
    </main>
    </>
  )
}

export default UnassignedAddressZone
