import React from 'react'
import defaultProfImg from '../../assets/img/prof.png'
// import defaultImg from '../../assets/img/nlogo.png'
import { useDispatch } from 'react-redux';
// import { signout } from '../../actions/userActions';
import {  useSelector } from 'react-redux';
import {  Link } from "react-router-dom";
import { siginUser, signout } from '../../reducers/userSlice';
import {
    FaBars,
  } from "react-icons/fa";


const NewworksTopbar = ({toggleNavbar})=>{
  const userSignin = useSelector(siginUser);
  const { user } = userSignin;
  const { user: loggedInUser} = user;


// console.log(loggedInUser);
  const dispatch = useDispatch();
  const signoutHandler = () => {
     dispatch(signout());

   };
 return(
  <>
   <div className="topbar-items">
        <nav className="navbar navbar-top navbar-expand navbar-dashboard navbar-dark ps-0 pe-2 pb-0 shadow-sm mt-2">
          <div className="container-fluid px-0">
            <div className="w-100" id="navbarSupportedContent">
            <div className="d-flex align-item-center justify-content-between w-100">
              <div className='d-flex align-items-center'>
                <button className="toggle-ico btn border-0" onClick={ ()=> toggleNavbar()}>
                  <FaBars className=' icon-md icon-fa' />
                </button>
                {/* <img className="avatar-md rounded-circle" alt='' src={defaultImg} width="85" height="100"/> */}
              </div>
              <div className="d-flex align-items-center">
                {loggedInUser && <img className="avatar-sm rounded-circle" alt='' src={defaultProfImg} />}
                <div className=" ms-2 text-dark align-items-center d-none d-sm-block me-3">
                {loggedInUser && <span className="mb-0 font-small fw-bold text-gray-900">{loggedInUser.first_name+' '+ loggedInUser.last_name}</span>}
                </div>
                {loggedInUser && <button className="btn d-flex align-items-center" onClick={signoutHandler}>
                  <svg className=" text-danger me-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="25px" height="25">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                  </svg> Logout
                </button>}
                {!loggedInUser && <Link to="/" className="btn d-flex align-items-center">
                  <svg className=" text-info me-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="25px" height="25">
                    <path fill="currentColor" strokeWidth=".08"  d="M10,17V14H3V10H10V7L15,12L10,17M10,2H19A2,2 0 0,1 21,4V20A2,2 0 0,1 19,22H10A2,2 0 0,1 8,20V18H10V20H19V4H10V6H8V4A2,2 0 0,1 10,2Z" />
                  </svg> Login
                </Link>}
              </div>
            </div>
            </div>
          </div>
        </nav>
      </div>
  </>
 )
}

export {NewworksTopbar};