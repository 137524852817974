import React from 'react'
import _ from "lodash";
const AssignedDashboard = ({datas, type}) => {


    // function totalAssigned(datas) {
    //     return datas.reduce((total, value) => total += value.addressAssigned, 0)
    //   }
    // const result = (datas) => { {
    //     return datas.reduce((total, value) => total += value.addressAssigned, 0)
    //   }}
    //   updateHandler()

      const result =  _.sumBy(datas, 'addressAssigned');
      const verified =  _.sumBy(datas, 'verifiedAssigned');
      const unverified =  _.sumBy(datas, 'unverifiedAssigned');

   
    // const result = datas.map((state) => ({
    //     // region: state[0].region,
    //     client: _.sumBy(state, 'addressAssigned'),
    //     pharamcy: _.sumBy(state, 'verifiedAssigned')
    //   }))



    // console.log(verified)
  return (
    
    <div className="row">
    <div className="col-12 col-sm-6 col-xl-4 mb-4">
      <div className="card border-0 shadow card-color" >
          <div className="card-body" >
              <div className="row d-block d-xl-flex align-items-center">
                  <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                      <div className="icon-shape icon-shape-primary rounded me-4 me-sm-0">
                          <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z" clipRule="evenodd"></path>
                          </svg>
                      </div>
                  </div>
                  <div className="col-12 col-xl-7 px-xl-0">
                      <div className="">
                          <h2 className="h5 fw-bold">Total Assigned</h2>
                          <h3 className="fw-extrabold mb-1 font-weight-bold">
                          { result }
                          </h3>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-xl-4 mb-4">
        <div className="card border-0 shadow card-color" >
            <div className="card-body" >
            <div className="row d-block d-xl-flex align-items-center">
                <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                <div className="icon-shape icon-shape-secondary rounded me-4 me-sm-0">
                    <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>
                    <path fillRule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clipRule="evenodd"></path>
                    </svg>
                </div>
                
                </div>
                <div className="col-12 col-xl-7 px-xl-0">
                <div className="">
                    <h2 className="h5 fw-bold">Verified</h2>
                    <h3 className="fw-extrabold mb-1 font-weight-bold">
                        { type === 'zone' ?
                            verified
                        //    totalAssigned()
                          : (type === 'region' ?
                          verified
                          : verified
                          ) }
                    </h3>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    <div className="col-12 col-sm-6 col-xl-4 mb-4">
        <div className="card border-0 shadow card-color" >
            <div className="card-body">
                <div className="row d-block d-xl-flex align-items-center">
                    <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                        <div className="icon-shape icon-shape-tertiary rounded me-4 me-sm-0">
                            <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                            </svg>
                        </div>
                        
                    </div>
                    <div className="col-12 col-xl-7 px-xl-0">
                        <div className="">
                        <h2 className="h5 fw-bold">UnVerified</h2>
                        <h3 className="fw-extrabold mb-1 font-weight-bold">{unverified}</h3>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div> 
  )
}

export default AssignedDashboard
