import axios from "axios";
import React, { useState } from 'react'
import AssignedTable from '../../../../components/assignedTable/AssignedTable'
import { NavigationBar } from '../../../../components/navigationbar/NavigationBar'
import { TopBar } from '../../../../components/topbar/TopBar'
import AssignedDashboard from '../../../../components/assignedDashboard/AssignedDashboard'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect} from 'react'
import { siginUser } from "../../../../reducers/userSlice";
import {FooterBar} from '../../../../components/general_footer/footer'
// import Spinner from "../../../../components/spinner/Spinner.jsx"

// import _ from "lodash";
// import { getAddress } from '../../../../reducers/addressSlice'



const AssignedAddressZone = () => {

  const userSignin = useSelector(siginUser);

  const { user} = userSignin;
 
  const {  token} = user;

  // console.log( loading, error)


  const [zone, setZone] = useState([]);

  const {type} = useParams();
  // console.log(type)
  // const typeId = searchParams.get('id')
  // const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

  const backend_url = process.env.REACT_APP_API;

  const [sidebarWidth, setSidebarWidth] = useState('sidebar-width')
  const [mainWidth, setMainWidth] = useState('ml-10')

  const toggleNavbar = ()=>{
      sidebarWidth === 'sidebar-width' ? setSidebarWidth('') : setSidebarWidth('sidebar-width');
      mainWidth === 'ml-10' ? setMainWidth('') : setMainWidth('ml-10');
  }

  // useEffect(() => {

  //   let cancel = false
    
  
  //   return () => {
  //     cancel = true
  //   }
  // }, [])
 
  

  useEffect(() => {

    const fetchZones = async () => {
      setLoading(true)
      const zoneData = await axios.get(`${backend_url}/api/zone`, 
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      ).catch(() => {

      });
      if (zoneData && zoneData.data) {
        // setData(response.data);
        setZone(zoneData.data.data)
      }
      setLoading(false)
      // setZone(zoneData.data.data)
    }
    fetchZones();
    

  }, [token,backend_url])
  
  let num = zone.length

  // console.log(num)
  return (
    <>
    <NavigationBar sidebarWidth={sidebarWidth}/>
    <main className={`content  ${mainWidth}`}>
    <TopBar toggleNavbar={toggleNavbar}/>
      <div className="all">
      <div className="py-4">
          <div aria-label="breadcrumb" className="long d-none d-md-inline-block">
            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
              <li className="breadcrumb-item">
                <Link className="font-weight-bolder text-dark" to="/admin/dashboard">
                  <svg className="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                  </svg>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <span className="font-weight-bolder text-dark">
                  <span className='text-capitalize'>Assigned</span>
                </span>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/admin/verificate/assigned/zone`} className="font-weight-bolder text-dark">
                  <span className='text-capitalize'>Zone</span>
                </Link>
              </li>
           
            
              <li className="breadcrumb-item"></li>
            </ol>
          </div>
      </div>
      {/* {zone.map((datas) => ( */}
      {/* {loading
        ? <div  className="d-flex justify-content-center" style={{ width:"100%"}}> <Spinner  /></div>
        :
        <> */}
      <AssignedDashboard key={num} datas={zone} type={type}/>
      {/* ))} */}
      <AssignedTable  datas={zone} type={type} loading={loading} />
      {/* </> } */}
      <FooterBar />
      </div>
      
    </main>
    </>
  )
}

export default AssignedAddressZone
