import './TopBar.css'
import axios from "axios"
import { Link } from "react-router-dom"
import React, { useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from "react-router-dom"
import defaultImg from '../../assets/img/glogo.png'
import defaultProfImg from '../../assets/img/prof.png'
import { useDispatch, useSelector } from 'react-redux'
import { siginUser, signout } from '../../reducers/userSlice'
// import {
//     FaBars,
//   } from "react-icons/fa";

const RiderTopBar = ({toggleNavbar})=>{
  const userSignin = useSelector(siginUser);
  const { user } = userSignin;
  const { token } = user;
  const { user: loggedInUser, } = user;

  const backend_url = process.env.REACT_APP_API;
  const [error, setError] = useState("")
  const [errors, setErrors] = useState([])
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("")
  const [old_password, setOldPassword] = useState("")
  const [loadingPass, setLoadingPass] = useState(false)
  const [email, setEmail] = useState(loggedInUser.email)
  const [loadingProfile, setLoadingProfile] = useState(false)
  const [address, setAddress] = useState(loggedInUser.address)
  const [username, setUsername] = useState(loggedInUser.username)
  const [last_name, setLastName] = useState(loggedInUser.last_name)
  const [first_name, setFirstName] = useState(loggedInUser.first_name)
  const [password_confirmation, setPasswordConfirmation] = useState("")

  const [currentUser, setCurrentUser] = useState(loggedInUser.first_name+' '+ loggedInUser.last_name)
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const hClose = () => setOpen(false);
  const hShow = () =>setOpen(true);

  const handleSubmitPass = async (event) => {
    event.preventDefault();
    try {
      setError('')
      setErrors([])
      setLoadingPass(true)
      await axios.post(`${backend_url}/api/auth/change-password`,
        {
          old_password: old_password,
          password: password,
          password_confirmation: password_confirmation,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setLoadingPass(false)
      setOpen(false)
      dispatch(signout());

    } catch (error) {
      setLoadingPass(false)
      if (error.response.data.errors) {
        const err = Object.entries(error.response.data.errors)
        setErrors(err)
      }else{
        setError(error.response.data.message)
      }

    }
  };

  const handleSubmitProfile = async (event) => {
    event.preventDefault();
    try {
      setError('')
      setErrors([])
      setLoadingProfile(true)
      let user = JSON.parse(localStorage.getItem('user'))
      await axios.put(`${backend_url}/api/user/edit/${loggedInUser.id}`,
        {
          first_name: first_name,
          last_name: last_name,
          email: email,
          username: username,
          address: address,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      user.user.first_name = first_name
      user.user.last_name = last_name
      user.user.email = email
      user.user.username = username
      if(user.user.type === 'rider'){user.user.address = address}
      localStorage.setItem('user', JSON.stringify(user));
      setCurrentUser(first_name+' '+ last_name)

      setShow(false)
      setLoadingProfile(false)

    } catch (error) {
      setLoadingProfile(false)
      if (error.response.data.errors) {
        const err = Object.entries(error.response.data.errors)
        setErrors(err)
      }else{
        setError(error.response.data.message)
      }
    }
  };


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signoutHandler = () => {
    dispatch(signout());
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkIfStillAuthorized();
    }, 60000); // Run every 5 seconds (5000 milliseconds)

    const checkIfStillAuthorized = async () => {
      try {
        await axios.get(`${backend_url}/api/authorized`, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(signout());
          navigate('/');
        }
      }
    };
    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
  }, [token, backend_url,dispatch, navigate]);

  // const toggleNabar = ()=>{
  //   console.log('nav toggled');
  // }
 return(
  <>
    <div className="topbar-items bg-white">
      <nav className="bg-white navbar navbar-top navbar-expand navbar-dashboard navbar-dark ps-0 pe-2 pb-0 border-bottom shadow-sm">
        <div className="container-fluid px-0">
          <div className="w-100" id="navbarSupportedContent">
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center">
              {/* <button className="toggle-ico btn border-0" onClick={ ()=> toggleNavbar()}>
                <FaBars className=' icon-md icon-fa' />
              </button> */}
              <Link to="/rider/dashboard">
                <img className="avatar-md rounded-circle" alt='' src={defaultImg} />
              </Link>
            </div>
            <div className="d-flex align-items-center">
              <div  className="d-flex" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false" >
                <img className="avatar-sm rounded-circle d-none d-sm-block " alt='' src={defaultProfImg} />
                
                <div className=" ms-2 text-dark align-items-center  me-3 wid">
                  <span className="mb-0 font-small fw-bold text-gray-900">{ currentUser }</span>
                  <br/>
                  <span className="fs-12 font-small right mr-3">
                    { loggedInUser.state_name }
                    {
                      loggedInUser.level === '1' ? '(HO)' :
                      (loggedInUser.level === '2' ? '(ZM)' :
                      (loggedInUser.level === '3' ? '(RM)' :
                      (loggedInUser.level === '4' && '(DEO)')))
                    }
                  </span>
                </div>
                
                <ul className="dropdown-menu drop-left">
                    <li className="dropdown-item" onClick={handleShow}>Edit Profile</li>
                    <li className="dropdown-item" onClick={hShow}>Change Password </li>
                </ul>
                <button className="btn d-flex align-items-center" onClick={signoutHandler}>
                  <svg className=" text-danger me-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="25px" height="25">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                  </svg> Logout
                </button>
              </div>
            </div>
          </div>
          </div>
        </div>
      </nav>
      <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="example-modal-sizes-title-lg">
        <form onSubmit={handleSubmitProfile}>
          <Modal.Header closeButton>
            <h4 className="modal-title">Edit Profile</h4>
          </Modal.Header>
          <Modal.Body>
            { error && <div className="text-center text-danger">{ error }</div> }
            { errors && <div className="text-center text-danger">{ 
              errors.map((data, i) => {
                return <span key={i}>{data}<br/></span>
              }) }
            </div> }
            <div className="row">
                <dl className="col-6">
                    <dt>First Name</dt>
                    <dd><input type="text" className="form-control" value={first_name} onChange={(e) => setFirstName(e.target.value)} /></dd>
                </dl>
                
                <dl className="col-6">
                    <dt>Last Name</dt>
                    <dd><input type="text" className="form-control" value={last_name} onChange={(e) => setLastName(e.target.value)} /></dd>
                </dl>
            </div>
            <div className="row">
                <dl className="col-6">
                    <dt>Email</dt>
                    <dd><input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} /></dd>
                </dl>

                <dl className="col-6">
                    <dt>Username</dt>
                    <dd><input type="text" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} /></dd>
                </dl>
            </div>
            { loggedInUser.type === 'rider' && <div className="row">
              <dl className="col-12">
                  <dt>Home Address</dt>
                  <dd><input type="text" className="form-control" value={address} onChange={(e) => setAddress(e.target.value)} /></dd>
              </dl>
            </div> }
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-dark" onClick={handleClose}>Close</button>
            { !loadingProfile ? <input type="submit" className="btn bevel" value="Save changes"/> :
            <input type="submit" className="btn bevel" value="Loading..." disabled/> }
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={open} onHide={hClose} size="sm" aria-labelledby="example-modal-sizes-title-lg">
        <form onSubmit={handleSubmitPass}>
          <Modal.Header closeButton>
            <h4 className="modal-title">Edit Profile</h4>
          </Modal.Header>
          <Modal.Body>
            { error && <div className="text-center text-danger">{ error }</div> }
            { errors && <div className="text-center text-danger">{ 
              errors.map((data, i) => {
                return <span key={i}>{data}<br/></span>
              }) }
            </div> }
            <dl className="d-flex justify-content-center">
              <img className="avatar-lg rounded-circle " alt='' src={defaultProfImg} />
            </dl>
            <div className="row">
                <dl className="col-12">
                  <dt>Old Password</dt>
                  <dd><input type="password" required minLength="6" className="form-control" onChange={(e) => setOldPassword(e.target.value)} /></dd>
                </dl>
            </div>
            <div className="row">
                <dl className="col-12">
                  <dt>Password</dt>
                  <dd><input type="password" required minLength="6" className="form-control" onChange={(e) => setPassword(e.target.value)} /></dd>
                </dl>

                <dl className="col-12">
                  <dt>Confirm Password</dt>
                  <dd><input type="password" required minLength="6" className="form-control" onChange={(e) => setPasswordConfirmation(e.target.value)} /></dd>
                </dl>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-dark" onClick={hClose}>Close</button>
            { !loadingPass ? <input type="submit" className="btn bevel" value="Save changes"/> :
            <input type="submit" className="btn bevel" value="Loading..." disabled/> }
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  </>
 )
}

export {RiderTopBar};