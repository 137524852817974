import React from 'react';
import { useSelector } from 'react-redux';
// import {  Navigate, Route } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router-dom'
import { siginUser } from '../reducers/userSlice';

export default function RiderRoute() {

  const userSignin = useSelector(siginUser);
  const { user, } = userSignin;
  
  const { user: loggedInUser, } = user;
 
 
  return (

    loggedInUser && loggedInUser.type === "rider" ? <Outlet/> : <Navigate to='/'/>
  
  );
}