// import axios from "axios";
// import React, { useState } from 'react'
// import { useSelector } from 'react-redux';
import { FaLongArrowAltLeft } from "react-icons/fa";
// import { siginUser } from '../../reducers/userSlice';
import {RiderTopBar} from '../../components/topbar/RiderTopbar'
import { Link} from "react-router-dom";

import {WebcamCapture} from '../../components/webcamcapture/WebcamCapture'
// import {PhoneCam} from '../../components/webcamcapture/phoneCam'
import './riderIndex.css'


export default function CamPage() {
 
    // navigate(`/rider/index`);
  

  return (
    <>
    <main className="ridercontent">
        <RiderTopBar />
        <div className="riderall">
            <div className="row py-4">
                <div className="col-12">
                    <Link to={`/rider/update`} className="card-title fw-bolder text-oran text-ora mid p-2 h2"><FaLongArrowAltLeft className=' icon-md' />Back</Link>
                    <div className="card text-center border-0 shadow">
                        <div className="card-body p-3 enitre">
                            <WebcamCapture /> 
                           {/* <PhoneCam /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
    </main>
    </>
  )
}
