import React from 'react';
import { useSelector } from 'react-redux';
// import {  Navigate, Route } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router-dom'
import { siginUser } from '../reducers/userSlice';

export default function NewworksRoute() {

  const userSignin = useSelector(siginUser);
  const { user } = userSignin;
  
  const { user: loggedInUser } = user;
  // const { user: loggedInUser } = user;
 
//  console.log(loggedInUser);
  return (

    loggedInUser && loggedInUser.type === "newworks" ? <Outlet/> : <Navigate to='/'/>
  
  );
}