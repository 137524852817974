import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const backend_url = process.env.REACT_APP_API;

const initialState = {
    incomplete: 0,
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    loading: false,
 
}


        // export const fetchIncomplete = createAsyncThunk('fetchIncomplete', async (initialPost, {  rejectWithValue, getState }) => {
        //     // console.log(initialPost)
        //     const {
        //         user: { user },
        //     } = getState();
        //      const { token} = user;
        
        //     try {
        //         const { data } = await axios.get(
        //             `${backend_url}/api/user/admins/${level}`, 
        //             {
        //               headers: { Authorization: `Bearer ${token}` },
        //             }
        //           );

        //           return data
           
             
        //     } catch (err) {

        //         return err.response.data.message;

        //         // rejectWithValue(err.response.data.message);
        //     //    const mess =   error.response && error.response.data.message
        //     //         ? error.response.data.message
        //     //         : error.message,

                 
        //     //     // return initialPost; // only for testing Redux!
        //     }
        // }); 

        
        export const incompleteCount = createAsyncThunk('incompleteCount', async (initialPost,{getState}) => {
            const {
                user: { user },
            } = getState();
            const { token} = user;
            const { data } = await axios.get(
                `${backend_url}/api/addresses/upload/incomplete/count`, 
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              );

              return data
        })







export const incompleteSlice = createSlice({
    name: 'incomplete',
    initialState,
    reducers: {
    
    },
    extraReducers(builder) {
        builder
            .addCase(incompleteCount.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(incompleteCount.fulfilled, (state, action) => {
                // state.loading = false;     
                state.incomplete = action.payload
             
                // console.log(current(state.incomplete))
            })
            .addCase(incompleteCount.rejected, (state, action) => {
                state.loading = false;
                // state.error = action.error.message
                // state.error =  "Incorrect Credentials"
                // console.log(action);
            })

            // .addCase(incompleteCount.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.incomplete = action.payload
            //     // state.user = {};
            // })
        }
    })

    export const incompleteCounter = (state) => state.incomplete;

     export default incompleteSlice