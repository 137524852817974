import './riderIndex.css'
import axios from "axios"
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { siginUser, signout } from '../../reducers/userSlice'
import Spinner from "../../components/spinner/Spinner.jsx"
import {RiderTopBar} from '../../components/topbar/RiderTopbar'
import {FooterBar} from '../../components/general_footer/footer'
import {  FaLongArrowAltLeft, FaUserCircle } from "react-icons/fa"
import { Link, useNavigate, useSearchParams } from "react-router-dom"

export default function RiderDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id')

  const userSignin = useSelector(siginUser);
  const { user } = userSignin;

  const { token } = user;
  const [address, setAddress] = useState([]);
  const [error, setError] = useState('');
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false)
  const [loadingBtn, setLoadingBtn] = useState(false)
  const backend_url = process.env.REACT_APP_API;

  useEffect(() => {
    const handleError = (error) => {
      console.log("Error getting location:", error.message);
    };
    
    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position.coords.latitude
      const lng = position.coords.longitude
      localStorage.setItem('latlng', JSON.stringify({lat, lng}));
    },handleError);

    
    const fetchAddress = async () => {
      setLoading(true)
      const response = await axios.get(`${backend_url}/api/addresses/${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      ).catch((e) => {
        if (e.response.status === 401) {
          dispatch(signout());
          return navigate('/')
        }
      });
      
      if (response && response.data) {
        setAddress(response.data)
      }
      setLoading(false)
    }
    fetchAddress();
  }, [setAddress, token, backend_url, id, dispatch, navigate]);

  const updateAddress = async () => {
    // const latlng = JSON.parse(localStorage.getItem('latlng'))
    
    // let add = `${address.address} ${address.territory} ${address.lga} ${address.state}`
    try {
      setLoadingBtn(true)
      // const responseapi = await axios.get(`https://api.positionstack.com/v1/forward?access_key=b2c573d3780e3aba1a77626e2633a472&query=${add}`)
      // const addlat = responseapi.data.data ? responseapi.data.data[0].latitude : ''
      // const addlng = responseapi.data.data ? responseapi.data.data[0].longitude : ''
      
      // const difflat = Math.abs(latlng.lat - addlat)
      // const difflng = Math.abs(latlng.lng - addlng)
      
      setLoadingBtn(false)
      // if (difflat <= 0.04 && difflng <= 0.04) {
        navigate(`/rider/update?id=${id}`);
      // }else{
      //   alert('You are not close to the address')
      // }
      
    } catch (error) {
      console.error(error)
      setLoadingBtn(false)
      if (error.response.data.errors) {
        const err = Object.entries(error.response.data.errors)
        setErrors(err)
      }else{
        setError(error.response.data.message)
      }
    }
  }

  const assignAddress = async () =>{
    if (window.confirm('Are you sure you want to assign this address to yourself')) {
      try {
        setLoading(true)
        await axios.post(`${backend_url}/api/user/rider/manifest/create`,
          {
            id: id
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        
        setLoading(false)
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Assigned successful',
            showConfirmButton: false,
            timer: 2500
        })
        navigate(`/rider/unassigned`);
      } catch (error) {
        setLoading(false)
        console.log(error);
        if (error.response.data.errors) {
          const err = Object.entries(error.response.data.errors)
          setErrors(err)
        }else{
          setError(error.response.data.message)
        }
      }
    }
  }

  return (
    <>
    <main className="ridercontent">
      <RiderTopBar />
      <div className="riderall">
        <div className="row py-4">
      
          <div className="col-12">
            <Link to={address.rider_id === null ? "/rider/unassigned" : "/rider/unverified"} className="card-title fw-bolder text-oran text-ora mid p-2 h2"><FaLongArrowAltLeft className=' icon-md' />Back</Link>
            {loading ? <div className="d-flex justify-content-center" style={{ width:"100%"}}> <Spinner /></div> :
              <>
              <div className="card text-center border-0 shadow">
                <div className="card-body p-4 enitre">
                    <h1><FaUserCircle className=' icon-md text-oran icon-size' /></h1>
                    <p className="card-text lh-sm mid fw-bolder">{ address.first_name } { address.last_name }</p>
                    <p className="card-text lh-sm small ">{ address.address }</p>
                    <p className="card-text lh-sm small"><span className=" text-capitalize fw-bolder ">Territory &nbsp;</span> { address.territory }</p>
                    <p className="card-text lh-sm small"><span className=" text-capitalize fw-bolder ">LGA &nbsp;</span>{ address.lga }</p>
                    <p className="card-text lh-sm small"><span className=" text-capitalize fw-bolder">State &nbsp;</span>{ address.state }</p>
                    {address.rider_id != null ?? <p className="card-text lh-sm small text-danger fw-bold"><span className=" text-capitalize fw-bolder text-dark">Status &nbsp;</span>{ address.status }</p>}
                    <p className="card-text lh-sm small fw-bolder">{ address.phone }</p>

                    <div className="d-grid gap-2">
                      { !loadingBtn ? 
                         address.rider_id === null ? <span className="btn btn-primary but" type="button" onClick={assignAddress}>Assign Address</span> 
                          : <span className="btn btn-primary but" type="button" onClick={updateAddress}>Update Address</span>  :
                        <input type="submit" className="btn btn-primary but" value="Loading..." disabled/>
                      }
                    </div>
                    <div className="form-group mt-3">
                        { error && <div className="text-center text-danger">{ error }</div> }
                        { errors && <div className="text-center text-danger">{ 
                            errors.map((data, i) => {
                            return <span key={i}>{data[1]}<br/></span>
                            }) }
                        </div> }
                    </div>

                </div>
              </div>
              </>
            }
          </div>
          
        </div>
      </div>
      <FooterBar />
    </main>
    </>
  )
}
