import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Login from "./pages/login/Login";

import AdminRoute from "./components/AdminRoute";
import RiderRoute from "./components/RiderRoute";
import NisRoute from "./components/NisRoute";
import AdminDashboard from "./pages/gwx_admin/dashboard/Dashboard";

import NisDashboard from "./pages/nis/dashboard/Dashboard";
import RiderDashboard from "./pages/rider/dashboard/dashboard";
import RiderIndex from "./pages/rider/riderIndex";
import RiderUnassigned from "./pages/rider/riderUnassigned";
import RiderDetails from "./pages/rider/riderDetails";
import RiderUpdate from "./pages/rider/riderUpdate";
import RiderVerified from "./pages/rider/riderVerified";
import CamPage from "./pages/rider/camPage";


import UploadExcel from "./pages/gwx_admin/uploadexcel/UploadExcel";
import NisExcelUpload from "./pages/nis/uploadexcel/UploadExcel";

import NewworksRoute from "./components/NewworksRoute";
import NewworksDashboard from "./pages/newworksportal/dashboard/Dashboard";
import NewworksExcelUpload from "./pages/newworksportal/uploadexcel/UploadExcel";
import NewworksAuditTray from "./pages/newworksportal/auditTray/AuditTray";
import NewworksUpload from "./pages/newworksportal/uploadexcel/Upload";

import TaskManifest from "./pages/gwx_admin/address/taskManifest";
import AssignedManifests from "./pages/gwx_admin/address/assignedManifest";
import AddUsers from "./pages/gwx_admin/addusers/addUsers";
import ShowUsers from "./pages/gwx_admin/addusers/showUsers";
import AddRiders from "./pages/gwx_admin/addRider/addRiders";
import ShowRiders from "./pages/gwx_admin/addRider/showRiders";
import NisAuditTray from "./pages/nis/auditTray/AuditTray";
import UnassignedManifests from "./pages/gwx_admin/address/unassignedManifest";
import Incomplete from "./pages/gwx_admin/uploadexcel/Incomplete";
import Upload from "./pages/nis/uploadexcel/Upload";
import Uploading from "./pages/gwx_admin/uploadexcel/Uploading";
import AdminReport from "./pages/gwx_admin/auditTray/AuditTray";
import AssignedAddressZone from "./pages/gwx_admin/address/assignedAdress/AssignedAddressZone";
import AssignedAdressRegions from "./pages/gwx_admin/address/assignedAdress/AssignedAdressRegions";
import AssignedAdressState from "./pages/gwx_admin/address/assignedAdress/AssignedAdressState";

import UnassignedAddressZone from "./pages/gwx_admin/address/unassignedAdress/UnassignedAddressZone";
import UnassignedAdressRegions from "./pages/gwx_admin/address/unassignedAdress/UnassignedAdressRegions";
import UnassignedAdressState from "./pages/gwx_admin/address/unassignedAdress/UnassignedAdressState";
import UploadFile from "./pages/gwx_admin/uploadexcel/UploadFile";




function App() {

  return (
    <Router>
      <Routes>       
        <Route path="*" element={ <Login />}></Route>
        <Route path="/" element={ <Login />}></Route>

        <Route path="admin" element={<AdminRoute/>}>
          <Route path="dashboard" element={ <AdminDashboard /> }></Route>
          <Route path="verificate/assigned/:type" element={ <AssignedAddressZone /> }></Route>         
          <Route path="verificate/assigned_region/:type" element={ <AssignedAdressRegions /> }></Route>
          <Route path="verificate/assigned_state/:type/*" element={ <AssignedAdressState /> }></Route>
          <Route path="verificate/unassigned/:type/*" element={ <UnassignedAddressZone /> }></Route>
          <Route path="verificate/unassigned_region/:type" element={ <UnassignedAdressRegions /> }></Route>
          <Route path="verificate/unassigned_state/:type/*" element={ <UnassignedAdressState /> }></Route>
          <Route path="verificate/assigned/manifests" element={ <AssignedManifests /> }></Route>
          <Route path="verificate/unassigned/manifests" element={ <UnassignedManifests /> }></Route>
          <Route path="verificate/manifest" element={ <TaskManifest /> }></Route>
          <Route path="verificate/upload/incomplete" element={ <Incomplete /> }></Route>
          <Route path="users/:level" element={ <AddUsers /> }></Route>
          <Route path="ShowUsers" element={ <ShowUsers /> }></Route>
          <Route path="riders" element={ <AddRiders /> }></Route>
          <Route path="ShowRiders" element={ <ShowRiders /> }></Route>
          <Route path="uploads" element={ <Uploading /> }></Route>
          <Route path="report" element={ <AdminReport /> }></Route>
          <Route path="uploads/:filename" element={ <UploadExcel /> }></Route>
          <Route path="uploads/excel" element={ <UploadFile /> }></Route>
        </Route>


        <Route path="nis" element={<NisRoute/>}>  
            <Route path="dashboard"   element={ <NisDashboard />}></Route>
            <Route  path="upload/excel" element={ <NisExcelUpload /> }></Route>
            <Route path="uploads" element={ <Upload /> }></Route>
            <Route  path="record/search" element={ <NisAuditTray /> }></Route>
        </Route>
  
        <Route path="newworks" element={ <NewworksRoute />}>
          <Route path="dashboard"   element={ <NewworksDashboard />}></Route>
          <Route  path="upload/excel" element={ <NewworksExcelUpload /> }></Route>
          <Route path="uploads" element={ <NewworksUpload /> }></Route>
          <Route  path="record/search" element={ <NewworksAuditTray /> }></Route>
        </Route>
        
        
        <Route element={<RiderRoute/>}>
           <Route path="/rider/dashboard" element={ <RiderDashboard /> }></Route>
           <Route path="/rider/verified" element={ <RiderVerified /> }></Route>
           <Route path="/rider/unverified" element={ <RiderIndex /> }></Route>
           <Route path="/rider/unassigned" element={ <RiderUnassigned /> }></Route>
           <Route path="/rider/details" element={ <RiderDetails /> }></Route>
           <Route path="/rider/update" element={ <RiderUpdate /> }></Route>
           <Route path="/rider/campage" element={ <CamPage /> }></Route>
        </Route>

      </Routes>
   </Router>
  );
}

export default App;
