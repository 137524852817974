import React from 'react'


const ThisweekCard = ({VerifiedThisWeek}) => {
  return (
    <>
      <div className="card border-0 shadow" >
        <div className="card-header">
          <h6>Weekly Report</h6>
        </div>
        <div className="card-body" >
            <div className="row align-items-center" style={{"height": "108px"}}>
                <div className="col-5  text-xl-center mb-3 mb-xl-0 align-items-center justify-content-center">
                    <div className="icon-shape icon-shape-secondary rounded me-4 me-sm-0">
                        <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd"></path>
                        </svg>
                    </div>
                </div>
                <div className="col-7  px-xl-0">
                    <div className="">
                        <h5 className="fs-6 fw-inter text-decoration-underline" style={{"color": "green"}}>This Week</h5>
                       {!VerifiedThisWeek && <h4 className="fw-extrabold mb-1 font-weight-bold fw-inter" style={{"color": "green"}}><span>Verified: </span>0</h4>}
                       {VerifiedThisWeek && <h4 className="fw-extrabold mb-1 font-weight-bold fw-inter" style={{"color": "green"}}><span>Verified: </span>{VerifiedThisWeek?.verified_thisWeek}</h4>}
                    </div>
                </div>
            </div>
        </div>
       </div>
    </>
  )
}

export default ThisweekCard