import React from 'react'

const ReportCard = ({report, year}) => {
  return (
    <>
      <div className="card border-0 shadow" >
              <div className="card-header">
                  <h5>Progress Report {year}</h5>
              </div>
              <div className="card-body">
                  <div className="d-flex">
                    <div className="col-6 border-bottom pt-2">
                      <div className="d-flex flex-column align-items-center">
                        <div className="icon-shape mb-1" style={{"color": "green"}}>
                        <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path  fillRule="evenodd" clipRule="evenodd"  d="M18.303,4.742l-1.454-1.455c-0.171-0.171-0.475-0.171-0.646,0l-3.061,3.064H2.019c-0.251,0-0.457,0.205-0.457,0.456v9.578c0,0.251,0.206,0.456,0.457,0.456h13.683c0.252,0,0.457-0.205,0.457-0.456V7.533l2.144-2.146C18.481,5.208,18.483,4.917,18.303,4.742 M15.258,15.929H2.476V7.263h9.754L9.695,9.792c-0.057,0.057-0.101,0.13-0.119,0.212L9.18,11.36h-3.98c-0.251,0-0.457,0.205-0.457,0.456c0,0.253,0.205,0.456,0.457,0.456h4.336c0.023,0,0.899,0.02,1.498-0.127c0.312-0.077,0.55-0.137,0.55-0.137c0.08-0.018,0.155-0.059,0.212-0.118l3.463-3.443V15.929z M11.241,11.156l-1.078,0.267l0.267-1.076l6.097-6.091l0.808,0.808L11.241,11.156z"></path>
                        </svg>

                        </div>
                        <h6 className='fw-inter'>Total Verfied</h6>
                        {report && <h4 className='fw-inter'>{report?.total_verified}</h4>}
                        
                      </div>
                    </div>
                    <div className="col-6 border-bottom pt-2">
                      <div className="d-flex flex-column align-items-center">
                        <div className="icon-shape  mb-1" style={{"color": "green"}}>
                        <svg className="icon icon-md mb-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          
                          <path  fillRule="evenodd" clipRule="evenodd" d="M10,1.375c-3.17,0-5.75,2.548-5.75,5.682c0,6.685,5.259,11.276,5.483,11.469c0.152,0.132,0.382,0.132,0.534,0c0.224-0.193,5.481-4.784,5.483-11.469C15.75,3.923,13.171,1.375,10,1.375 M10,17.653c-1.064-1.024-4.929-5.127-4.929-10.596c0-2.68,2.212-4.861,4.929-4.861s4.929,2.181,4.929,4.861C14.927,12.518,11.063,16.627,10,17.653 M10,3.839c-1.815,0-3.286,1.47-3.286,3.286s1.47,3.286,3.286,3.286s3.286-1.47,3.286-3.286S11.815,3.839,10,3.839 M10,9.589c-1.359,0-2.464-1.105-2.464-2.464S8.641,4.661,10,4.661s2.464,1.105,2.464,2.464S11.359,9.589,10,9.589"></path>
                        </svg>

                        </div>
                        <h6 className='fw-inter'>Total Address</h6>
                       {!report && <h4 className='fw-inter'>{0}</h4>}
                       {report && <h4 className='fw-inter'>{report?.total_address}</h4>}
                      </div>
                    </div>
                  </div>
              </div>
          </div>
    </>
  )
}

export default ReportCard