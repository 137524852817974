import React, {useState, useEffect} from 'react'
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import axios from "axios";
import ReactPaginate from "react-paginate";

import { NavigationBar } from '../../../components/navigationbar/NsiNavbar.jsx'
import { NsiTopBar } from '../../../components/topbar/NsiTopbar.jsx'
// import _ from 'lodash';
import './AuditTray.css'
import ImageModal from '../../../components/ImageModal'
import {FooterBar} from '../../../components/general_footer/footer'


import AuditTable from './component/AuditTable';
import DataReport from './component/DataReport';
import AuditSearch from './component/AuditSearch.jsx';

import { siginUser } from '../../../reducers/userSlice';
function AuditTray() {

  const userSignin = useSelector(siginUser);
  const { user} = userSignin;
  const { token, riders_description } = user
  const backend_url = process.env.REACT_APP_API;


  const [addresses, setAddresses] = useState([]);
  const [checkedValue, setCheckedValue] = useState(null);
  const [metaData, setMetaData] = useState({})

  const {from, current_page, per_page, total } = metaData

  const [PerPage, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  // const [send, setSend] = useState(false)
  
  const [filterWord, setFilterWord] = useState('');
  const [filterKey, setFilterKey] = useState('')

  const [sidebarWidth, setSidebarWidth] = useState('sidebar-width')
  const [mainWidth, setMainWidth] = useState('ml-10')
  const toggleNavbar = ()=>{
      sidebarWidth === 'sidebar-width' ? setSidebarWidth('') : setSidebarWidth('sidebar-width');
      mainWidth === 'ml-10' ? setMainWidth('') : setMainWidth('ml-10');
  }


    // for imgae component display open condition
  const [imgUrl, setImgUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  // def function for imgae display and open and close components

  const openImage = (imgUrl)=>{
    setImgUrl(imgUrl);
    setIsModalOpen(true)
  }

  const closeImage = ()=>{
    setImgUrl('');
    setIsModalOpen(false)
    // console.log('close');
  }

  // const pagesVisited = pageNumber * Number(per_page);
  
  // console.log(Math.ceil(Number(total/per_page)));
  
  const onpagChanged = e => setPerPage(Number(e.target.value))

  const pageCount = Math.ceil(Number(total/per_page));

  const changePage = ({ selected }) => {
    let pageNumbe = selected + 1;
    setPageNumber(pageNumbe);

  };
  // console.log(addresses.data);



  const handleChecked = (e, num, key) =>{
    if(e.target.checked === true){
      setCheckedValue(num)
      setFilterKey(key)
      // setIndex(num)
    }else{
      setCheckedValue(null)
      // setIndex(null)
    }
  }

  const handleChangeInput =(filter) =>{
    if(checkedValue === null){setFilterWord('')}
    else{setFilterWord(filter)} 
  }

  useEffect(()=>{
    const fetchAddreses = async ()=>{
      if(checkedValue === 0){setFilterKey('first_name')}
      
      const response = await axios.get(`${backend_url}/api/address/search?page=${pageNumber}&per_page=${PerPage}&filters=${filterWord}&filter_key=${filterKey}`, 
      {
        headers: { Authorization: `Bearer ${token}` },
      }
      );
      setAddresses(response.data)
      setMetaData(response.data.meta)
    }

    fetchAddreses();

    let interval = setInterval(()=>{
      fetchAddreses();
    }, 1000*60*60)

    return ()=>{
      fetchAddreses()
      clearInterval(interval)
    }

}, [setAddresses, backend_url , token, PerPage, filterWord,filterKey, checkedValue, pageNumber])

  // console.log(filterIndex);
  return (
    <>
     <NavigationBar sidebarWidth={sidebarWidth}/>
     <main className={`content  ${mainWidth}`}>
      <NsiTopBar toggleNavbar={toggleNavbar}/>
      <div className="all">
       <div className="py-4">
         <div aria-label="breadcrumb" className="long  d-inline-block">
           <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
             <li className="breadcrumb-item">
               <Link className="fw-bold text-dark" to="/nis/dashboard">
               <svg className="fw-bold icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
               </svg>
               <span>Dashboard</span>
               </Link>
             </li>
             <li className="breadcrumb-item">
               <Link className="fw-bold text-dark" to="">
               <span>Audit Tray</span>
               </Link>
             </li>
           </ol>
         </div>
       </div>

      {/* onChange={onpagChanged}  */}
      <div className="col-12 mb-4">
          <div className="row justify-content-between">
            <div className="col-2 col-lg-1">
              <label className="label-control">No/Page</label>
              <select name="pp" onChange={onpagChanged} className="form-control">
                <option value='10'>10</option>
                <option value='20'>20</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
                <option value='500'>500</option>
              </select>
            </div>
            <div className="col-6">
              <DataReport data={addresses} meta={metaData}/>
            </div>
          </div>
        </div>
        
       <div className="">
        <div className="d-flex justify-content-between w-50">
         {/* <p className="text-primary cursor">0 verified addresses found</p> */}
         {/* <p className="text-secondary cursor">Total Result: {!addresses.data?.length && <span className='text-danger'>--</span>} {addresses.data?.length && <span className='text-success'>{addresses.data?.length}</span>}</p> */}
        </div>
        
        <section className='row align-items-start bg-white'>
          <div className="table-responsive border-radius-2 col-12 col-lg-12 order-1 order-lg-0">
            <div className="row  justify-content-between p-2">
              <div className="d-flex col-12 col-lg-5 al align-items-center">
                <button className='btn btn-outline-primary me-1'>CSV</button>
                <button className='btn btn-outline-danger me-1'>PDF</button>
                <button className='btn btn-outline-primary me-1'>COPY</button>
                <button className='btn btn-outline-warning'>PRINT</button>
              </div>
              <div className='d-flex flex-column mt-1 align-items-start col-12 col-lg-5'>
                <AuditSearch handleChecked={handleChecked} handleChangeInput={handleChangeInput} checkedValue={checkedValue} />
              </div>
              <div className='d-flex flex-column mt-1 align-items-start justify-content-center col-12 col-lg-2'>
                <label htmlFor="table-search" className="form-label me-1 fs-6x">Search by Date: </label> 
                <input type="date" className='form-control table-search mt-0' id='table-search' placeholder='click any filter key to search table' onChange={(e)=>setFilterWord(e.target.value.toString())}/>
              </div>
            </div>
            <AuditTable Data={addresses?.data} riders_description={riders_description} from={from} openImage={openImage}/>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              activePage={Number(current_page)}
              pageCount={Number(pageCount)}
              onPageChange={changePage}
              containerClassName={"paginationBttns pt-5"}
              previousLinkClassName={"previousBttn "}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
              /> 
          </div>
          <div className="d-flex flex-column justify-content-center mb-4 col-12 col-lg-2 order-0 order-lg-1">
          {/* <DataReport data={addresses} meta={metaData}/> */}
          </div>
        </section>
       </div>

        {isModalOpen && <ImageModal imgUrl={imgUrl} closeImage={closeImage}/>}
      </div>
      <FooterBar />
     </main>
    </>
  )
}

export default AuditTray