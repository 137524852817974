import React, { useState, useRef, useEffect } from "react";
import {
  Wrapper,
  Content,
  Image,
  CloseButton,
  BtnDiv,
  ImageContainer,
} from "./ImageModal.style";

const Index = ({ imgUrl, time, closeImage }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
    setPosition({ x: 0, y: 0 }); // Reset position when toggling zoom
  };

  const handleMouseDown = (event) => {
    if (isZoomed) {
      event.preventDefault();
      imageRef.current.style.cursor = "grabbing";
      const startX = event.clientX - position.x;
      const startY = event.clientY - position.y;

      const handleMouseMove = (event) => {
        const newX = event.clientX - startX;
        const newY = event.clientY - startY;
        setPosition({ x: newX, y: newY });
      };

      const handleMouseUp = () => {
        imageRef.current.style.cursor = "grab";
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      };

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }
  };

  const handleClickOutsideModal = (event) => {
    if (imageRef.current && !imageRef.current.contains(event.target)) {
      closeImage();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideModal);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideModal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <Wrapper>
      <Content>
        <ImageContainer>
          <Image
            src={imgUrl}
            alt="location image"
            className={isZoomed ? "zoomed" : ""}
            onClick={toggleZoom}
            onMouseDown={handleMouseDown}
            ref={imageRef}
            style={{
              transform: `scale(${isZoomed ? 2.5 : 1}) translate(${position.x}px, ${position.y}px)`,
            }}
          />
          <BtnDiv>
            <CloseButton onClick={() => closeImage()} title="Close Image...">
              X
            </CloseButton>
          </BtnDiv>
          {/* <ImageSpan>{time}</ImageSpan> */}
        </ImageContainer>
      </Content>
    </Wrapper>
  );
};

export default Index;
