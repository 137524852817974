// import _ from "lodash";
import "./AuditTray.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import React, { useState, useEffect } from "react";
import { siginUser } from "../../../reducers/userSlice";
import { TopBar } from "../../../components/topbar/TopBar";
import { NavigationBar } from "../../../components/navigationbar/NavigationBar";

import AuditTable from "./component/AuditTable";
// import DataReport from './component/DataReport';
import { useCallback } from "react";
import ImageModal from "../../../components/ImageModal";
// import AuditSearch from './component/AuditSearch.jsx';

function AuditTray() {
  const userSignin = useSelector(siginUser);
  const { user } = userSignin;
  const { token, riders_description } = user;
  const { user: userInfo } = user;
  const { access_state: states } = userInfo;

  const [addresses, setAddresses] = useState([]);
  const [send, setSend] = useState(true);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [rider, setRider] = useState([]);
  const [report, setReport] = useState({
    per_page: 10,
    name: "",
    status: "completed",
    state: "",
    start_date: "",
    end_date: "",
    address: "",
    city: "",
    phone: "",
    report: "",
    rider: "",
    type: "",
  });

  const [sidebarWidth, setSidebarWidth] = useState("sidebar-width");
  const [mainWidth, setMainWidth] = useState("ml-10");
  const toggleNavbar = () => {
    sidebarWidth === "sidebar-width" ? setSidebarWidth("") : setSidebarWidth("sidebar-width");
    mainWidth === "ml-10" ? setMainWidth("") : setMainWidth("ml-10");
  };

  // for imgae component display open condition
  const [imgUrl, setImgUrl] = useState("");
  const [time, setTime] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  // def function for imgae display and open and close components

  const openImage = (imgUrl, time) => {
    setImgUrl(imgUrl);
    setTime(time);
    setIsModalOpen(true);
  };

  const closeImage = () => {
    setImgUrl("");
    setTime("");
    setIsModalOpen(false);
    // console.log('close');
  };

  const onReportChange = (e) => setReport((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const exportExcel = (type) => {
    fetchAddreses();
    setSend(true);
    // setReport(prev => ({...prev, "type":"Excel"}))
    type === "Excel" ? setReport((prev) => ({ ...prev, type: "Excel" })) : setReport((prev) => ({ ...prev, type: "pdf" }));
  };

  const fetchAddreses = useCallback(async () => {
    setPageNumber(1);
    const backend_url = process.env.REACT_APP_API;
    if (send) {
      const response = await axios
        .get(`${backend_url}/api/addresses/reports?page=${pageNumber}`, {
          params: report,
          headers: { Authorization: `Bearer ${token}`, "Content-Type": `application/json` },
        })
        .catch((e) => {
          console.log(e);
        });
      setLoading(false);

      if (report.type !== "") {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        var fileExt = report.type === "Excel" ? "csv" : "pdf";

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "addresses." + fileExt);
        document.body.appendChild(fileLink);

        fileLink.click();
        window.alert("File downloaded");
      } else {
        setAddresses(response.data);
      }
    }
  }, [token, send, report, pageNumber]);

  useEffect(() => {
    const backend_url = process.env.REACT_APP_API;
    const fetchRider = async () => {
      const riderData = await axios.get(`${backend_url}/api/user/rider/manifest-report`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRider(riderData.data);
    };

    fetchRider();
  }, [token]);

  useEffect(() => {
    fetchAddreses();
    return () => {
      setSend(false);
    };
  }, [fetchAddreses]);

  const { meta } = addresses;
  const pageCount = Math.ceil(Number(meta?.total ? meta?.total : 0) / Number(meta?.per_page ? meta?.per_page : 1));

  const changePage = ({ selected }) => {
    let pageNumbe = selected + 1;
    setLoading(true);
    setReport((prev) => ({ ...prev, type: "" }));
    setSend(true);
    setPageNumber(pageNumbe);
  };

  return (
    <>
      <NavigationBar sidebarWidth={sidebarWidth} />
      <main className={`content ${mainWidth}`}>
        <TopBar toggleNavbar={toggleNavbar} />
        <div className="all">
          <div className="py-4">
            <div aria-label="breadcrumb" className="long  d-inline-block">
              <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                <li className="breadcrumb-item">
                  <Link className="fw-bold text-dark" to="/admin/dashboard">
                    <svg className="fw-bold icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                    </svg>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link className="fw-bold text-dark" to="">
                    <span>Report</span>
                  </Link>
                </li>
              </ol>
            </div>
          </div>

          {/* <div className="  mb-4   ">
            <DataReport data={addresses} />
          </div> */}

          {/* onChange={onpagChanged}  */}
          <div className="col-12 mb-3 pe-3">
            <div className="justify-content-first">
              <span className="text-primary">Total Address {addresses?.meta?.total}</span>
            </div>
            <div className="row justify-content-end">
              <button className="col-1 mr-2 btn btn-outline-primary me-1" disabled={addresses?.meta?.total > 5000} onClick={() => exportExcel("Excel")}>
                Excel
              </button>
              <button className="col-1 mr-2 btn btn-outline-danger me-1" disabled={addresses?.meta?.total > 5000} onClick={() => exportExcel("pdf")}>
                PDF
              </button>
              {/* <button className='col-1 mr-2 btn btn-outline-info me-1' disabled={addresses?.meta?.total > 5000} onClick={()=>exportExcel('pdf')}>COPY</button> */}
              {/* <button className='col-1 mr-2 btn btn-outline-warning me-1' disabled={addresses?.meta?.total > 5000} onClick={()=>exportExcel('print')}>PRINT</button> */}
            </div>
          </div>

          <div className="">
            <section className="row bg-white mt-5 border-radius-2 ">
              <div className="table-responsive  col-12 col-lg-12 order-1 order-lg-0 ">
                <div className="justify-content-between  p-2 w-100">
                  <div className="row   al my-2 w-100">
                    <div className="col-md-1">
                      <label className="label-control">No/Page</label>
                      <select name="per_page" value={report.per_page} onChange={onReportChange} className="form-control">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                      </select>
                    </div>

                    <div className="col-md-2">
                      <label htmlFor="table-search" className="label-control">
                        {" "}
                        Name{" "}
                      </label>
                      <input type="text" name="name" value={report.name} onChange={onReportChange} className="form-control" placeholder="search table" />
                    </div>

                    <div className="col-md-2">
                      <label htmlFor="table-search" className="label-control">
                        {" "}
                        phone{" "}
                      </label>
                      <input type="text" name="phone" value={report.phone} onChange={onReportChange} className="form-control" placeholder="search table" />
                    </div>

                    <div className="col-md-2">
                      <label htmlFor="table-search" className="label-control">
                        {" "}
                        City{" "}
                      </label>
                      <input type="text" name="city" value={report.city} onChange={onReportChange} className="form-control" placeholder="search table" />
                    </div>

                    <div className="col-md-2">
                      <label className="label-control">State</label>
                      <select name="state" value={report.state} onChange={onReportChange} className="form-control">
                        <option value="">--Select--</option>
                        {/* <option value='all'>all</option> */}

                        {states?.map((data, i) => {
                          return (
                            <option key={i} value={data.name}>
                              {data.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-md-2">
                      <label htmlFor="table-search" className="label-control">
                        {" "}
                        Address{" "}
                      </label>
                      <input type="text" name="address" value={report.address} onChange={onReportChange} className="form-control" placeholder="search table" />
                    </div>
                  </div>

                  <div className="row col-12  al align-items-center w-100 ">
                    {/* <div className="col-md-2">
                  <label className="label-control">Status</label>
                  <select name="status" onChange={onReportChange} value={report.status} className="form-control">
                    <option value='completed'>verified</option>
                  </select>
                </div> */}

                    {/* <div className="col-md-2">
                  <label className="label-control">Query For</label>
                  <select name="report" value={report.report} onChange={onReportChange} className="form-control">
                    <option value=''>--Select--</option>
                    <option value='Yesterday'>Yesterday</option>
                    <option value='Today'>Today</option>
                  </select>
                </div> */}

                    <div className="col-md-2">
                      <label className="label-control">VO</label>
                      <select name="rider" value={report.rider} onChange={onReportChange} className="form-control">
                        {/* <select className="form-control" value={riderId} onChange={(e) => setRiderId(e.target.value)} required> */}
                        <option value="">Choose Rider</option>
                        {rider.map((data, i) => {
                          return (
                            <option key={i} value={data.id}>
                              {data.first_name} {data.last_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-md-2">
                      <label htmlFor="table-search" className="label-control">
                        {" "}
                        Start Date{" "}
                      </label>
                      <input type="date" name="start_date" value={report.start_date} onChange={onReportChange} className="form-control" id="table-search" placeholder="search table" />
                    </div>

                    <div className="col-md-2">
                      <label htmlFor="table-search" className="label-control">
                        {" "}
                        End Date{" "}
                      </label>
                      <input type="date" name="end_date" value={report.end_date} onChange={onReportChange} className="form-control" id="table-search" placeholder="search table" />
                    </div>
                    {/* <div className='d-flex flex-column mt-1 align-items-end col-12 col-lg-2'>
                  <AuditSearch handleChecked={handleChecked} handleChangeInput={handleChangeInput} checkedValue={checkedValue} />
                </div> */}
                    <div className="col-md-2">
                      <label htmlFor="table-search" className="label-control">
                        .
                      </label>
                      <input
                        type="submit"
                        onClick={() => {
                          setLoading(true);
                          fetchAddreses();
                          setSend(true);
                          setReport((prev) => ({ ...prev, type: "" }));
                        }}
                        className="form-control btn btn-warning"
                        value={loading ? "Loading..." : "Sort"}
                        disabled={loading}
                      />
                    </div>
                  </div>
                </div>
                <AuditTable data={addresses.data} riders_description={riders_description} meta={addresses.meta} openImage={openImage} />
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns pt-5"}
                  previousLinkClassName={"previousBttn "}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </div>
              {/* <div className="d-flex flex-column justify-content-center mb-4 col-12 col-lg-2 order-0 order-lg-1">
            <DataReport data={addresses} />
          </div> */}
            </section>
          </div>

          {isModalOpen && <ImageModal imgUrl={imgUrl} time={time} closeImage={closeImage} />}
        </div>
      </main>
    </>
  );
}

export default AuditTray;
