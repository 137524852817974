// import {  compose, applyMiddleware, combineReducers } from 'redux';
// import { legacy_createStore as createStore} from 'redux'
// import { compose, applyMiddleware, combineReducers } from 'redux';
import { configureStore } from "@reduxjs/toolkit";
import addressSlice from "./reducers/addressSlice";
import adminSlice from "./reducers/adminSlice";
import incompleteSlice from "./reducers/incompleteSlice";
// import thunk from 'redux-thunk';

import { userSlice } from './reducers/userSlice';
// import { addressLitReducer } from './reducers/addressReducer';
// import { assignedAddressReducer } from './reducers/addressReducer';

// import { 
//     // userRegisterReducer,
//     userSigninReducer,
  
   
//   } from './reducers/userReducers';




// const initialState = {
//     userSignin: {
//       user: window.localStorage.getItem('user')
//         ? JSON.parse(localStorage.getItem('user'))
//         : null,
//     },

    
   
//   };

 


  // const reducer = combineReducers({
  //   userSignin: userSigninReducer,
  //   assignedAddress: assignedAddressReducer,
  //   // productList: productListReducer,
 
  
  // });
  // const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  export const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        address: addressSlice.reducer,
        staff: adminSlice.reducer,
        incomplete: incompleteSlice.reducer,

        // adminSlice
    }
})



// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(
//   reducer,
//   initialState,
//   composeEnhancer(applyMiddleware(thunk))
// );

export default store;