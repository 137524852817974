import './riderIndex.css'
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import ReactPaginate from "react-paginate"
import React, { useState, useEffect } from 'react'
import { FaLongArrowAltLeft} from "react-icons/fa"
import { siginUser, signout } from '../../reducers/userSlice'
import {RiderTopBar} from '../../components/topbar/RiderTopbar'
import Spinner from "../../components/spinner/Spinner.jsx"
import {FooterBar} from '../../components/general_footer/footer'


export default function RiderVerified() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userSignin = useSelector(siginUser);
  const { user } = userSignin;

  const { token } = user;
  const [address, setAddress] = useState([]);
  const [pageNumber, setPageNumber] = useState(1)
  const [loading, setLoading] = useState(false)
  const backend_url = process.env.REACT_APP_API;

  const { current_page, per_page, total } = address
  const pageCount = Math.ceil(Number(total ? total : 0) / (Number(per_page ? per_page : 1)));

  const changePage = ({ selected }) => {
    let pageNumbe = selected + 1;
    setPageNumber(pageNumbe);
  };

  useEffect(() => {
    const fetchAddress = async () => {
      setLoading(true)
      const response = await axios.get(`${backend_url}/api/rider/address/completed?page=${pageNumber}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      ).catch((e) => {
        if (e.response.status === 401) {
          dispatch(signout());
          return navigate('/')
        }
      });
      if (response && response.data) {
        // setData(response.data);
        setAddress(response.data)
      }
      setLoading(false)
    

    }
    fetchAddress();
  }, [setAddress, token, backend_url, pageNumber, dispatch, navigate]);
  
  return (
    <>
    <main className="ridercontent">
      <RiderTopBar />
      <div className="riderall">
        <div className="row py-4">
          <div className="col-12">
            <Link to={`/rider/dashboard`} className="card-title fw-bolder text-oran text-ora mid p-2 h2"><FaLongArrowAltLeft className=' icon-md' />Back</Link>
            
            <div className="col-12 child alert alert-light ">
              <section>
                <div className="alert alert-warning m-2 bold text-uppercase text-center">Address verified</div>
                <div className="table-responsive mt-2 mt-2 bg-white p-1">
                  {/* <form className="row form-group p-2">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-6 col-lg-4">
                          <label className="label-control">From</label>
                          <input type="date" className="form-control" name="searchFrom" />
                        </div>

                        <div className="col-6 col-lg-4">
                          <label className="label-control">To</label>
                          <input type="date" className="form-control" name="searchTo" />
                        </div>
                        
                        <div className="col-6 col-lg-1">
                          <label className="label-control">Per Page</label>
                          <select className="form-control" >
                            <option value='10'>10</option>
                            <option value='20'>20</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='500'>500</option>
                          </select>
                        </div>

                        <div className="col-6 col-lg-3">
                          <label className="label-control">&nbsp;</label>
                          <input type="submit" className="form-control btn btn-small bevel" value="Sort" />                        
                        </div>
                      </div>
                    </div>
                  </form> */}
                    
                  <table className="table fs-13 table-sm table-striped">
                      {address && <caption>
                        <span className="text-left">Showing {address.from} to {address.to} of {address.total} address(s)</span>
                      </caption>}
                    <thead>
                      <tr className="bevel text-light fw-bold">
                        <th className="text-uppercase">SN</th>
                        <th className="text-uppercase">firstname</th>
                        <th className="text-uppercase">lastname</th>
                        <th className="text-uppercase">address</th>
                        <th className="text-uppercase">city</th>
                        <th className="text-uppercase">landmark</th>
                        <th className="text-uppercase">lga</th>
                        <th className="text-uppercase">state</th>
                      </tr>
                    </thead>
                    {loading
                        ? <div  className="d-flex justify-content-center" style={{ width:"100%"}}> <Spinner  /></div>
                        :
                        <>
                    <tbody>
                      { address?.data?.map((data, i) => {
                        return <tr key={i}>
                          <td>{ i + address.from }</td>
                          <td>{ data.first_name }</td>
                          <td>{ data.last_name }</td>
                          <td>{ data.address }</td>
                          <td>{ data.city }</td>
                          <td>{ data.territory }</td>
                          <td>{ data.lga }</td>
                          <td>{ data.state }</td>
                        </tr>
                      })}
                    </tbody>
                    </>}   
                  </table>
                     
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    activePage={current_page}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns pt-5"}
                    previousLinkClassName={"previousBttn "}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  />
                    
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <FooterBar />
    </main>
    </>
  )
}
