import React from 'react'

const TodayCard = ({VerifiedToday}) => {
  return (
    <>
     <div className="card border-0 shadow" >
       <div className="card-header">
         <h6>Daily Report</h6>
       </div>
       <div className="card-body" >
           <div className="row align-items-center" style={{"height": "108px"}}>
               <div className="col-5 text-xl-center mb-3 mb-xl-0 align-items-center justify-content-center">
                   <div className="icon-shape icon-shape-primary rounded me-4 me-sm-0">
                       <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                       <path fill="currentColor" d="M21 11.11V5C21 3.9 20.11 3 19 3H14.82C14.4 1.84 13.3 1 12 1S9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.11 3.9 21 5 21H11.11C12.37 22.24 14.09 23 16 23C19.87 23 23 19.87 23 16C23 14.09 22.24 12.37 21 11.11M12 3C12.55 3 13 3.45 13 4S12.55 5 12 5 11 4.55 11 4 11.45 3 12 3M5 19V5H7V7H17V5H19V9.68C18.09 9.25 17.08 9 16 9H7V11H11.1C10.5 11.57 10.04 12.25 9.68 13H7V15H9.08C9.03 15.33 9 15.66 9 16C9 17.08 9.25 18.09 9.68 19H5M16 21C13.24 21 11 18.76 11 16S13.24 11 16 11 21 13.24 21 16 18.76 21 16 21M16.5 16.25L19.36 17.94L18.61 19.16L15 17V12H16.5V16.25Z" />
                       </svg>
                   </div>
               </div>
               <div className="col-7  px-xl-0">
                   <div className="">
                       <h6 className="h5 fs-6 fw-inter text-decoration-underline" style={{"color": "#F58634"}}>Today</h6>
                        {!VerifiedToday && <h4 className="fw-bold mb-1 font-weight-bold fw-inter" style={{"color": "#F58634"}}><span>Verified: </span> {0}</h4>}
                       {VerifiedToday && <h4 className="fw-extrabold mb-1 font-weight-bold fw-inter" style={{"color": "#F58634"}}><span>Verified: </span> {VerifiedToday?.verified_today}</h4>}
                   </div>
               </div>
           </div>
       </div>
       </div>
    </>
  )
}

export default TodayCard