// import React from 'react'

const AuditSearch = ({handleChecked, handleChangeInput, checkedValue}) => {

 
 
  return (
    <>
     <label htmlFor="table-search mt-1" className="form-label me-1 fs-6x d-flex flex-wrap">
      <span className='d-flex align-items-center me-1'>name <input type="checkbox" checked={ checkedValue === 0 } className='form-control form-check-input' onChange={(e)=> handleChecked(e, 0, 'first_name')}/></span>
      <span className='d-flex align-items-center me-1'>address<input type="checkbox" checked={ checkedValue === 1} className='form-control form-check-input' onChange={(e)=> handleChecked(e, 1, 'address' )}/></span> 
      <span className='d-flex align-items-center me-1'>city<input type="checkbox" checked={ checkedValue === 2}className='form-control form-check-input' onChange={(e)=> handleChecked(e, 2, 'city')}/></span> 
      <span className='d-flex align-items-center me-1'>state<input type="checkbox" checked={ checkedValue === 3} className='form-control form-check-input' onChange={(e)=> handleChecked(e, 3, 'state')}/></span> 
      <span className='d-flex align-items-center'>remark<input type="checkbox" checked={ checkedValue === 4} className='form-control form-check-input' onChange={(e)=> handleChecked(e, 4, 'remark')}/></span> 
                
     </label>
     <input type="search" className='form-control table-search mt-0' id='table-search' placeholder='click any filter key to search table' onChange={(e)=>handleChangeInput(e.target.value.toString())} />
    </>
  )
}

export default AuditSearch