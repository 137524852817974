import React from 'react'
// import gbar from '../../../../assets/img/gbar.png'
// import pbar from '../../../../assets/img/pbar.png'
// import _ from 'lodash'
const DataReport = ({data, meta}) => {
  return (
    <>
    <div className="row">
      <div className="col-12 mb-3">
        <div className="card border-0" >
          <div className="card-header">
            <h6>Total Address</h6>
          </div>
          <div className="card-body" >
              <div className="row  d-xl-flex align-items-center" style={{"height": "40px"}}>
                  {/* <div className="col-8 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-start">
                      <div className="icon-shape icon-shape-primary rounded me-4 me-sm-0">
                          <img src={gbar} alt="" />
                      </div>
                  </div> */}
                  <div className="col-12 text-center px-xl-0">
                      <span className="badge text-dark fs-5 p-2">{meta?.total}</span>
                  </div>
              </div>
          </div>
        </div>
      </div>
     
     {/* <div className="col-6 mb-3">
         <div className="card border-0" >
           <div className="card-header">
             <h6>Verified</h6>
           </div>
             <div className="card-body" >
                 <div className="row d-xl-flex align-items-center" style={{"height": "40px"}}>
                     <div className="col-12 text-center px-xl-0">
                         <span className="badge text-dark fs-5 p-2">{_.size(data?.data?.filter((d) => d.status.toLowerCase() === 'completed'))}</span>
                     </div>
                 </div>
             </div>
         </div>
     </div> */}
    </div>
    </>
  )
}

export default DataReport