import './addRiders.css'
import { useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { TopBar } from '../../../components/topbar/TopBar'
import { RidersTable } from '../../../components/RidersTable'
import { adminStaff, fetchRiders } from '../../../reducers/adminSlice'
import { NavigationBar } from '../../../components/navigationbar/NavigationBar'
import {FooterBar} from '../../../components/general_footer/footer'


export default function AddRider() {

  const staff = useSelector(adminStaff);
  const dispatch = useDispatch();

  const [sidebarWidth, setSidebarWidth] = useState('sidebar-width')
  const [mainWidth, setMainWidth] = useState('ml-10')
  const toggleNavbar = ()=>{
    sidebarWidth === 'sidebar-width' ? setSidebarWidth('') : setSidebarWidth('sidebar-width');
    mainWidth === 'ml-10' ? setMainWidth('') : setMainWidth('ml-10');
  }

  useEffect(() => {
    dispatch(fetchRiders());
  }, [dispatch]);
  
  return (
    <>
      <NavigationBar sidebarWidth={sidebarWidth}/>
      <main className={`content  ${mainWidth}`}>
        <TopBar toggleNavbar={toggleNavbar}/>
        <div className="all">
          <div className="py-4">
            <div className="long d-none d-md-inline-block">
              <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                <li className="breadcrumb-item">
                  <Link className="font-weight-bolder text-dark" to="">
                  <svg className="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                  </svg>
                  <span>Dashboard</span>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <span className="font-weight-bolder text-dark">
                    <span className='text-capitalize'> Verification Officer’s</span>
                  </span>
                </li>
              
              </ol>
            </div>
          </div>

          <RidersTable  staff={staff}/>

        </div>
        <FooterBar />
      </main>
    </>
  )
}
