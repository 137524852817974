// import { stubString } from 'lodash';
import React from 'react'
import Moment from 'moment';
import Spinner from '../../../../components/spinner/Spinner'
const AuditTable = ({Data, riders_description, from, openImage}) => {
  return (
    <>
     <table className="table  fs-13 table-sm table-striped ">
              <thead>
                <tr className="bevel text-light fw-bold">
                  <th className="text-uppercase">s/n</th>
                  <th className="text-uppercase">name</th>
                  <th className="text-uppercase">address</th>
                  <th className="text-uppercase">phone</th>
                  <th className="text-uppercase">city</th>
                  <th className="text-uppercase">state</th>
                  <th className="text-uppercase">does address exist</th>
                  <th className="text-uppercase">does applicant live there</th>
                  <th className="text-uppercase">description</th>
                  <th className="text-uppercase">remarks</th>
                  <th className="text-uppercase">last updated</th>
                  <th className="text-uppercase">images</th>
                </tr>
              </thead>

              <tbody>				
 		
                {!Data ? 
                <Spinner />
                :
                Data.map((data, i)=>{
                  // console.log(data);
                    let address_img = ''+data?.trackers?.address_img+''
                    let landmark_img = ''+data?.trackers?.landmark_img+''
                return <tr role="row" className="odd" key={data.id}>
                  <td className="sorting_1">{i + from}</td>
                  <td>{data.first_name} { data.last_name} { data.other_name}</td>
                  <td>{data.address}</td>
                  <td>{data.phone}</td>
                  <td>{data.city}</td>
                  <td>{data.state}</td>
                  <td>{data.v_status}</td>
                  <td>{data.resident}</td>
                  <td>{data.descriptions}</td>
                  <td>{data.remark}</td>
                  {data?.status.toLowerCase() !== 'completed' ? <td>{'null'}</td> :<td>{data.updated_at.substring(0, 10)}</td>}
                  <td>
                    {data?.trackers?.address_img ? <button className="btn btn-sm btn-success mb-1" title='Location Image' onClick={()=>openImage(data?.trackers?.address_img, Moment(address_img.substring(59,79)).format('YYYY-MM-DD'))}> LC Image</button> : <span>Not Available </span>}
                    {data?.trackers?.landmark_img ? <button className="btn btn-sm btn-primary my-1 bfs" title='Landmark Image' onClick={()=>openImage(data?.trackers?.landmark_img, Moment(landmark_img.substring(60,80)).format('YYYY-MM-DD'))}>LM Image</button> : <span>Not Available </span>}
                  </td>
                </tr>})}
             
              </tbody>

            </table>
    </>
  )
}

export default AuditTable