import React from 'react'
// import defaultAltech from '../../assets/img/alogo.png'
// import { Link } from "react-router-dom";
import "./footer.css"

const FooterBar = ()=>{

    return(
     <>

        {/* <div className="bg-transparent">

            <footer className="d-flex justify-content-center ">

            <a href="http://alltsnetwork.com/" target="_blank" rel="noopener noreferrer" className="nav-link d-flex align-items-center">

                <p className="row p-3 text-dark fw-bold blk">
                  Powered by: &nbsp;
                  <span className="f-icon t-icon">
                    <img src={defaultAltech} height="25" width="25" alt="Logo"></img>
                  </span> &nbsp; All-Tech Systems & Co.
                  </p>

                </a>
                

            </footer>

        </div> */}
     </>
    )
    }

export { FooterBar};