import axios from "axios"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import ReactPaginate from "react-paginate"
// import _ from "lodash";
import Swal from 'sweetalert2'
import { siginUser } from '../../../reducers/userSlice.js'
import { TopBar } from '../../../components/topbar/TopBar.jsx'
import React, { useState, useEffect, useCallback } from 'react'
import { NavigationBar } from '../../../components/navigationbar/NavigationBar.jsx'
import { incompleteCount } from "../../../reducers/incompleteSlice.js"
import Spinner from "../../../components/spinner/Spinner.jsx"
import {FooterBar} from '../../../components/general_footer/footer'


function Incomplete() {
  const userSignin = useSelector(siginUser)
  const { user } = userSignin
  const { token } = user
  const { user: userInfo } = user
  const { access_state: states } = userInfo
  const [lgas, setLga] = useState([])
  const [data, setData] = useState([])
  const [send, setSend] = useState(false)
  const [address, setAddress] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchPerPage, setSearchPerPage] = useState(10)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [searchPageNumber, setSearchPageNumber] = useState(1);
  const backend_url = process.env.REACT_APP_API;
 
  const dispatch = useDispatch();

  const { current_page, per_page, total } = data
  const pageCount = Math.ceil(Number(total ? total : 0) / (Number(per_page ? per_page : 1)));

  const changePage = ({ selected }) => {
    let pageNumbe = selected + 1;
    if(searchState || searchFrom || searchTo) {
      setSend(true)
      setSearchPageNumber(pageNumbe)
    }else{
      setPageNumber(pageNumbe);
    }
  };

  const [sidebarWidth, setSidebarWidth] = useState('sidebar-width')
  const [mainWidth, setMainWidth] = useState('ml-10')
  const toggleNavbar = ()=>{
      sidebarWidth === 'sidebar-width' ? setSidebarWidth('') : setSidebarWidth('sidebar-width');
      mainWidth === 'ml-10' ? setMainWidth('') : setMainWidth('ml-10');
  }


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [modalData, setModalData] = useState({
    id: "",
    last_name: "",
    first_name: "",
    other_name: "",
    lga: "",
    state: "",
    city: "",
    territory: "",
    manifest_no: "",
    phone: "",
    status: ""
  })
  const { last_name, first_name, other_name, lga, id, status, phone, state, territory } = modalData;

  const handleEdit = (e) => {
    setModalData((prev => ({ ...prev, [e.target.name]: e.target.value })))
  }

  const editData = (d) => {
    setModalData(d)
    handleShow()
  }

  const saveChanges = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      const rep =  await axios.put(`${backend_url}/api/addresses/${id}`,
        {
          id, last_name, first_name, other_name, lga, phone, status, state, territory,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
     
      if (rep && rep.data) {
        dispatch(incompleteCount())
        await getData()
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: 'Update successful ',
          showConfirmButton: false,
          timer: 2500
        })
      }
     
    } catch (err) {
      console.error('Failed to save the post', err)
    } finally {
      handleClose()
      setLoading(false)
      setModalData({
        id: "",
        last_name: "",
        first_name: "",
        other_name: "",
        lga: "",
        state: "",
        city: "",
        territory: "",
        manifest_no: "",
        phone: "",
        status: ""
      });
    }

    setModalData({
      id: "",
      last_name: "",
      first_name: "",
      other_name: "",
      lga: "",
      state: "",
      city: "",
      territory: "",
      manifest_no: "",
      phone: ""
    });
    handleClose()
  }

  const [searchTo, setSearchTo] = useState('')
  const [searchLga, setSearchLga] = useState('')
  const [searchFrom, setSearchFrom] = useState('')
  const [searchState, setSearchState] = useState('')
  const [searchLandmark, setSearchLandmark] = useState("")

  const addSearchstate = (name) => {
    setSearchState(name)
    states.forEach((el)=>{
      if (el.name === name) {
        setLga(el.lga)
      }
    })
  }

  const getSearchData = useCallback(async (event) => {
    event && event.preventDefault()
    if(send){
      setLoadingSearch(true)
      setLoading(true)
      const response = await axios.post(`${backend_url}/api/addresses/search/upload/incomplete?page=${searchPageNumber}`,
        {
          state: searchState,
          lga: searchLga,
          landmark: searchLandmark,
          perPage: searchPerPage,
          from: searchFrom,
          to: searchTo,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response && response.data) {
        setData(response.data);
        setAddress(response.data.data)
      }
      setLoadingSearch(false)
      setLoading(false)
    }
  }, [backend_url, token, send, searchPageNumber, searchState, searchLga, searchLandmark, searchPerPage, searchFrom, searchTo])

  useEffect(() => {
    getSearchData()
    return () => {
      setSend(false)
    }
  }, [searchPageNumber, getSearchData]);

  const getData = async () => {
    setLoading(true)
    const response = await axios.get(`${backend_url}/api/addresses/upload/incomplete?page=${pageNumber}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).catch(() => {
  
    });
  
    if (response && response.data) {
      setData(response.data);
      setAddress(response.data.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [backend_url, token, pageNumber]);


  return (
    <>
      <NavigationBar sidebarWidth={sidebarWidth}/>
      <main className={`content ${mainWidth}`}>
        <TopBar toggleNavbar={toggleNavbar}/>
        <div className="all">
          <div className="py-4">
            <div aria-label="breadcrumb" className="long d-inline-block">
              <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                <li className="breadcrumb-item">
                  <Link className="fw-bold text-dark" to="/admin/dashboard">
                    <svg className="fw-bold icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                    </svg>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <span className="fw-bold text-dark">Incomplete</span>
                </li>
              </ol>
            </div>
          </div>

          <div className="container-fluid">
            <div className="alert-waning ">
              <div className="col-12 child alert alert-light ">
                <section>
                  <div className="alert alert-warning m-2 bold text-uppercase text-center">Incomplete Addresses</div>
                  <div className="table-responsive mt-2 mt-2 bg-white p-1">
                    <form className="row form-group p-2" onSubmit={getSearchData}>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-1">
                            <label className="label-control">PerPage</label>
                            <select className="form-control" value={searchPerPage} onChange={(e) =>{ setSearchPerPage(e.target.value); setSearchPageNumber(1) }}>
                              <option value='10'>10</option>
                              <option value='20'>20</option>
                              <option value='50'>50</option>
                              <option value='100'>100</option>
                              <option value='500'>500</option>
                            </select>
                          </div>
                            
                          <div className="col-2">
                            <label className="label-control">Select State</label>
                            <select className="form-control" value={searchState} onChange={(e) =>{ addSearchstate(e.target.value); setSearchPageNumber(1) }}>
                              <option value="">Choose State</option>

                              { states?.map((data, i) => {
                                return <option key={i} value={data.name}>{data.name}</option>

                              })}
                            </select>
                          </div>

                          <div className="col-2">
                            <label className="label-control">Select LGA</label>
                            <select className="form-control" value={searchLga} onChange={(e) =>{ setSearchLga(e.target.value); setSearchPageNumber(1) }}>
                              <option value="">Choose LGA</option>
                              { lgas.map((data, i) => {
                                return <option key={i} value={data.name}>{ data.name }</option>
                              })}
                            </select>
                          </div>

                          <div className="col-2">
                              <label className="label-control">LAND MARK/CITY</label>
                              <input type="text" placeholder="Type an Area within State" className="form-control" onChange={(e) =>{ setSearchLandmark(e.target.value); setSearchPageNumber(1) }} />
                          </div>

                          <div className="col-2">
                            <label className="label-control">From</label>
                            <input type="date" className="form-control" name="searchFrom" value={searchFrom} onChange={(e) => {setSearchFrom(e.target.value); setSearchPageNumber(1)}} />
                          </div>
 
                          <div className="col-2">
                            <label className="label-control">To</label>
                            <input type="date" className="form-control" name="searchTo" value={searchTo} onChange={(e) => {setSearchTo(e.target.value); setSearchPageNumber(1)}} />
                          </div>

                          <div className="col-1">
                            <label className="label-control">&nbsp;</label>
                            {!loadingSearch ? <input type="submit" className="form-control btn btn-small bevel" value="Sort" onClick={() => { setSearchPageNumber(1); setSend(true) }} /> :
                              <input type="submit" className="form-control btn btn-small bevel" value="Loading..." disabled />}
                          </div>
                        </div>
                      </div>
                    </form>
                    
                     {loading
                        ? <div  className="d-flex justify-content-center" style={{ width:"100%"}}> <Spinner  /></div>
                        :
                        <>
                    <table className="table fs-13 table-sm table-striped">
                      {data.data && <caption>
                        <span className="text-left">Showing {data.from} to {data.to} of {data.total} manifest(s)</span>
                      </caption>}
                      <thead>
                        <tr className="bevel text-light fw-bold">
                          <th className="text-uppercase">SN</th>
                          <th className="text-uppercase">PHONE</th>
                          <th className="text-uppercase">address</th>
                          <th className="text-uppercase">city</th>
                          <th className="text-uppercase">landmark</th>
                          <th className="text-uppercase">lga</th>
                          <th className="text-uppercase">state</th>
                          <th className="text-uppercase">surname</th>
                          <th className="text-uppercase">firstname</th>
                          <th className="text-uppercase">othername</th>
                          <th className="text-uppercase">status</th>
                          <th className="text-uppercase"></th>
                        </tr>
                      </thead>

                      <tbody>
                        {address?.map((d, index) => {
                          return (
                            <tr key={d.id}>
                              <td> {index + data.from} </td>
                              <td> {d.phone} </td>
                              <td> {d.address} </td>
                              <td> {d.city} </td>
                              <td> {d.territory} </td>
                              <td> {d.lga} </td>
                              <td> {d.state} </td>
                              <td> {d.last_name} </td>
                              <td> {d.first_name} </td>
                              <td> {d.other_name} </td>
                              <td> {d.status} </td>
                              <td> <button className='btn btn-sm btn-danger' onClick={() => editData(d)}>Edit</button> </td>
                            </tr>
                          );
                        })
                        }

                      </tbody>

                    </table>
                     
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      activePage={current_page}
                      pageCount={pageCount}
                      onPageChange={changePage}
                      containerClassName={"paginationBttns pt-5"}
                      previousLinkClassName={"previousBttn "}
                      nextLinkClassName={"nextBttn"}
                      disabledClassName={"paginationDisabled"}
                      activeClassName={"paginationActive"}
                    />
                   
                   </> 
                  }
                   
                  </div>
                 
                </section>
              </div>
            </div>
          </div>
          <FooterBar />
        </div>

        <Modal show={show} onHide={handleClose} size="lg">
          <form onSubmit={saveChanges}>
            <Modal.Header closeButton>
              <h4 className="modal-title">Upload</h4>
            </Modal.Header>
            <Modal.Body>


              <section id="editFormModal">
                <div className="row">
                  <dl className="col-6">
                    <dt>First Name</dt>
                    <dd><input readOnly name="first_name" value={modalData.first_name} type="text" className="form-control" /></dd>
                  </dl>

                  <dl className="col-6">
                    <dt> SURNAME</dt>
                    <dd><input readOnly name="last_name" value={modalData.last_name} type="text" className="form-control" /></dd>
                  </dl>
                </div>
                <div className="row">
                  <dl className="col-6">
                    <dt>OTHERNAME</dt>
                    <dd><input readOnly value={modalData.other_name} name="other_name" type="text" className="form-control" /></dd>
                  </dl>

                  <dl className="col-6">
                    <dt>ADDRESS</dt>
                    <dd><input onChange={handleEdit} value={modalData.address} name="address" type="text" className="form-control" /></dd>
                  </dl>
                </div>
                <div className="row">
                  <dl className="col-6">
                    <dt>CITY</dt>
                    <dd><input onChange={handleEdit} value={modalData.city} name="city" type="text" className="form-control" /></dd>
                  </dl>

                  <dl className="col-6">
                    <dt>LGA</dt>
                    <dd><input onChange={handleEdit} value={modalData.lga} name="lga" type="text" className="form-control" /></dd>
                  </dl>
                </div>

                <div className="row">
                  <dl className="col-6">
                    <dt>LANDMARK </dt>
                    <dd><input onChange={handleEdit} value={modalData.territory} name="territory" type="text" className="form-control" /></dd>
                  </dl>
                  <dl className="col-6">
                    <dt> STATE</dt>
                    <dd><input onChange={handleEdit} value={modalData.state} name="state" type="text" className="form-control" /></dd>
                  </dl>

                </div>
                <div className="row">
                  <dl className="col-6">
                    <dt>PHONE</dt>
                    <dd><input readOnly value={modalData.phone} name="phone" type="text" className="form-control" /></dd>
                  </dl>

                  <dl className="col-6">
                    <dt>STATUS</dt>
                    <dd>
                      <select className="form-control" onChange={handleEdit} value={modalData.status} name="status">
                        <option value="pending">Pending</option>
                        <option value="incomplete">Incomplete</option>
                      </select>
                    </dd>
                  </dl>
                </div>
              </section>


            </Modal.Body>
            <Modal.Footer>
              {!loading ? <input type="submit" className="btn btn-success" value="Update" /> :
                <input type="submit" className="btn btn-success" value="Loading..." disabled />}
              <button type="button" className="btn btn-dark" onClick={handleClose}>Close</button>
              {/* <button type="button" className="btn bevel" onClick={saveChanges}>Save changes</button> */}
            </Modal.Footer>
          </form>
        </Modal>
      </main>
    </>
  )
}

export default Incomplete