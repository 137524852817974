// import _ from "lodash";
import axios from "axios";
import Moment from "moment";
import Swal from "sweetalert2";
import "./assignedManifest.css";
import { FaListAlt } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { siginUser } from "../../../reducers/userSlice";
import { Link, useSearchParams } from "react-router-dom";
import Spinner from "../../../components/spinner/Spinner";
import { TopBar } from "../../../components/topbar/TopBar";
import React, { useCallback, useEffect, useState } from "react";
import { FooterBar } from "../../../components/general_footer/footer";
import { RescheduleAssignedAdress } from "../../../reducers/addressSlice";
import { NavigationBar } from "../../../components/navigationbar/NavigationBar";

export default function Manifest() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const userSignin = useSelector(siginUser);

  const { user } = userSignin;
  const { user: loggedInUser, token } = user;
  const { access_state: states } = loggedInUser;

  const [state, setState] = useState(searchParams.get("s"));
  const [send, setSend] = useState(true);
  const [loading, setLoading] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const [addressesData, setAddressesData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const [searchPage, setSearchPage] = useState(10);
  const [searchState, setSearchstate] = useState(state);
  const [searchManifestNo, setSearchManifestNo] = useState("");

  const [loadingSearch, setLoadingSearch] = useState(false);
  const [verified, setVerified] = useState(0);
  const [unVerified, setUnVerified] = useState(0);

  const fetchManifest = useCallback(async () => {
    const backend_url = process.env.REACT_APP_API;
    if (send) {
      setLoading(true);
      const response = await axios
        .post(
          `${backend_url}/api/manifest/state/${searchState}?page=${pageNumber}`,
          {
            perPage: searchPage,
            manifestNo: searchManifestNo,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .catch((e) => {
          console.log(e);
        });
      setLoading(false);

      setVerified(response.data.completed);
      setUnVerified(response.data.pending);
      setAddresses(response.data[0]);

      setAddressesData(response.data[0].data);
    }
  }, [token, send, pageNumber, searchState, searchManifestNo, searchPage]);
  // console.log(addressesData);

  useEffect(() => {
    fetchManifest();
    return () => {
      setSend(false);
    };
  }, [fetchManifest]);

  // const updateHandler = (manifest) => {
  //   try {
  //     dispatch(updateAssignedAdress({manifest})).unwrap()
  //     Swal.fire({
  //       position: 'top-end',
  //       icon: 'success',
  //       title: 'Update successful ',
  //       showConfirmButton: false,
  //       timer: 2500
  //     })
  //   } catch (err) {
  //     console.error('Failed to save the post', err)
  //   } finally {}
  // };

  const RescheduleHandler = (manifest) => {
    let reschedule = window.confirm("Are you sure you want to reschedule this manifest");
    if (reschedule) {
      try {
        dispatch(RescheduleAssignedAdress({ manifest }));
        setSearchManifestNo("");
        fetchManifest();
        setSend(true);
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Update successful ",
          showConfirmButton: false,
          timer: 2500,
        });
      } catch (err) {
        console.error("Failed to save the post", err);
      } finally {
      }
    }
  };

  const searchSubmit = async (event) => {
    event.preventDefault();
    setPageNumber(1);
    setLoadingSearch(true);
    setState(searchState);

    fetchManifest();
    setSend(true);

    setLoadingSearch(false);
  };

  const { per_page, total } = addresses;
  const pageCount = Math.ceil(Number(total ? total : 0) / Number(per_page ? per_page : 1));

  const changePage = ({ selected }) => {
    let pageNumbe = selected + 1;

    setSend(true);
    setPageNumber(pageNumbe);
  };

  const [sidebarWidth, setSidebarWidth] = useState("sidebar-width");
  const [mainWidth, setMainWidth] = useState("ml-10");
  const toggleNavbar = () => {
    sidebarWidth === "sidebar-width" ? setSidebarWidth("") : setSidebarWidth("sidebar-width");
    mainWidth === "ml-10" ? setMainWidth("") : setMainWidth("ml-10");
  };

  return (
    <>
      <NavigationBar sidebarWidth={sidebarWidth} />
      <main className={`content  ${mainWidth}`}>
        <TopBar toggleNavbar={toggleNavbar} />
        <div className="all">
          <div className="py-4">
            <div className="long d-none d-md-inline-block">
              <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                <li className="breadcrumb-item">
                  <Link className="font-weight-bolder text-dark" to="/admin/dashboard">
                    <svg className="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                    </svg>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <span className="font-weight-bolder text-dark">
                    <span className="text-capitalize">Assigned</span>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/admin/verificate/assigned/zone`} className="font-weight-bolder text-dark">
                    <span className="text-capitalize">Zone</span>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <span className="font-weight-bolder text-dark">
                    <span className="text-capitalize">Region</span>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <span className="font-weight-bolder text-dark">
                    <span className="text-capitalize">State</span>
                  </span>
                </li>
                <li className="breadcrumb-item">
                  <span className="font-weight-bolder text-dark">
                    <span className="text-capitalize">Manifest</span>
                  </span>
                </li>
              </ol>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-6 col-xl-4 mb-4">
              <div className="card border-0 shadow card-color">
                <div className="card-body">
                  <div className="row d-block d-xl-flex align-items-center">
                    <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                      <div className="icon-shape icon-shape-primary rounded me-4 me-sm-0">
                        <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z" clipRule="evenodd"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="col-12 col-xl-7 px-xl-0">
                      <div className="">
                        <h2 className="h5 fw-bold">Total Addresses</h2>
                        <h3 className="fw-extrabold mb-1 font-weight-bold">{unVerified + verified}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-4">
              <div className="card border-0 shadow card-color">
                <div className="card-body">
                  <div className="row d-block d-xl-flex align-items-center">
                    <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                      <div className="icon-shape icon-shape-secondary rounded me-4 me-sm-0">
                        <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>
                          <path fillRule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clipRule="evenodd"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="col-12 col-xl-7 px-xl-0">
                      <div className="">
                        <h2 className="h5 fw-bold">Verified</h2>
                        <h3 className="fw-extrabold mb-1 font-weight-bold">{verified}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-4 mb-4">
              <div className="card border-0 shadow card-color">
                <div className="card-body">
                  <div className="row d-block d-xl-flex align-items-center">
                    <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                      <div className="icon-shape icon-shape-tertiary rounded me-4 me-sm-0">
                        <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div className="col-12 col-xl-7 px-xl-0">
                      <div className="">
                        <h2 className="h5 fw-bold">UnVerified</h2>
                        <h3 className="fw-extrabold mb-1 font-weight-bold">{unVerified}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="alert-waning ">
            <div className="col-12 child alert alert-light ">
              <div className="" data-title="Buttons_wrapper"></div>

              <section>
                <div className="alert alert-warning m-2 bold text-uppercase text-center">TOTAL manifest IN {state}</div>
                <div className="table-responsive mt-2 bg-white p-1">
                  <form className="row form-group p-2" onSubmit={searchSubmit}>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-2">
                          <label className="label-control">No/Page</label>
                          <select className="form-control" onChange={(e) => setSearchPage(e.target.value)}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                          </select>
                        </div>

                        <div className="col-3">
                          <label className="label-control">State</label>
                          <select className="form-control" value={searchState} onChange={(e) => setSearchstate(e.target.value)}>
                            <option value="">Choose State</option>
                            {states.map((data, i) => {
                              return (
                                <option key={i} value={data.name}>
                                  {data.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="col-3">
                          <label className="label-control">Manifest No</label>
                          <input type="number" name="mn" placeholder="Min of 6 nos" className="form-control" onChange={(e) => setSearchManifestNo(e.target.value)} />
                        </div>

                        <div className="col-4">
                          <label className="label-control">&nbsp;</label>
                          {!loadingSearch ? <input type="submit" className="form-control btn btn-small bevel" value="Sort" /> : <input type="submit" className="form-control btn btn-small bevel" value="Loading..." disabled />}
                        </div>
                      </div>
                    </div>
                  </form>
                  <div>
                    <span className="float-right mb-2"></span>
                  </div>
                  {loading ? (
                    <div className="d-flex justify-content-center" style={{ width: "100%" }}>
                      {" "}
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      <table className="table  fs-13 table-sm">
                        <caption>
                          <span className="float-right"></span>
                        </caption>
                        <thead>
                          <tr className="bevel text-light">
                            <th>S/N</th>
                            <th>Manifest No</th>
                            <th> Verification Officer’s Name</th>
                            <th>Assigned</th>
                            <th>Verified</th>
                            <th>Unverified</th>
                            <th>Assigned Date</th>
                            <th>Creator</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody id="t_body">
                          {addressesData.map((data, i) => {
                            return (
                              <tr key={i}>
                                <td>{i + addresses.from}</td>
                                <td>
                                  <Link to={`/admin/verificate/manifest?m=${data.manifest_no}`}>{data.manifest_no}</Link>
                                </td>
                                <td>
                                  {data.rider && data.rider.first_name} {data.rider && data.rider.last_name}
                                </td>
                                <td>{data.total_count}</td>
                                <td>{data.completed_count}</td>
                                <td>{data.pending_count}</td>
                                <td>{Moment(data.manifest_no.substring(0, 8) + "T" + data.manifest_no.substring(8, 14)).format("YYYY-MM-DD")}</td>
                                <td>
                                  {data.manifest_creator && data.manifest_creator.first_name} {data.manifest_creator && data.manifest_creator.last_name}
                                </td>
                                <th>
                                  {data.pending_count !== "0" && (
                                    <div>
                                      <button className="btn btn-danger btn-sm " onClick={() => RescheduleHandler(data.manifest_no)} title="Reschedule Addresses">
                                        <FaListAlt className="text-light " />
                                      </button>
                                    </div>
                                  )}
                                </th>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {addresses && (
                        <span>
                          <span className="text-left">
                            Showing {addresses.from} to {addresses.to} of {addresses.total} manifest(s)
                          </span>
                        </span>
                      )}

                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        forcePage={pageNumber - 1} // Subtract 1 to match the zero-based index
                        pageCount={pageCount}
                        onPageChange={changePage}
                        containerClassName={"paginationBttns pt-5"}
                        previousLinkClassName={"previousBttn "}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                      />
                    </>
                  )}
                </div>
              </section>
            </div>
          </div>
          <FooterBar />
        </div>
      </main>
    </>
  );
}
