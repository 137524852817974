import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const backend_url = process.env.REACT_APP_API;


const initialState = {
    address: [],
    loading: false,
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    update: null
}


export const fetchAssignedAdress = createAsyncThunk('fetchAssignedAdress', async (initialPost,{getState}) => {
    const {
        user: { user },
      } = getState();
      const { token} = user;
      const { searchState } = initialPost;
    //   console.log(initialPost);
    const response = await axios.get(`${backend_url}/api/manifest/state/${searchState}`,
      
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return response.data
    //   console.log(data)
})


export const updateAssignedAdress = createAsyncThunk('updateAssignedAdress', async (initialPost,{getState}) => {
    const {
        user: { user },
      } = getState();
      const { token} = user;
      const { manifest } = initialPost;

     await axios.get(`${backend_url}/api/manifest/incomplete/${manifest}`,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return  manifest
    //   console.log(data)
})

export const RescheduleAssignedAdress = createAsyncThunk('RescheduleAssignedAdress', async (initialPost,{getState}) => {
    const {
        user: { user },
      } = getState();
      const { token} = user;
      const { manifest } = initialPost;

     await axios.get(`${backend_url}/api/manifest/reschedule/${manifest}`,

        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return  manifest
    //   console.log(data)
})





export const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAssignedAdress.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchAssignedAdress.fulfilled, (state, action) => {
                state.loading = false;   
                state.status = 'succeeded'  
                state.address = action.payload
                // postsAdapter.upsertMany(state, action.payload)
                // console.log(action.payload)
            })
            .addCase(fetchAssignedAdress.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message
            })
            .addCase(updateAssignedAdress.pending, (state, action) => {
                state.loading = true;
                // console.log(action.payload)
            })
            .addCase(updateAssignedAdress.fulfilled, (state, action) => {
                if (!action.payload) {
                    // console.log('Update could not complete')
                    state.error = "Update could not complete"
                    // console.log(action.payload)
                    return;
                }
                state.loading = false;   
                state.status = 'succeeded'
                state.update = action.payload
            })
            .addCase(updateAssignedAdress.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message
                // console.log(action.payload.manifest)
            })
            .addCase(RescheduleAssignedAdress.pending, (state, action) => {
                state.loading = true;
                // console.log(action.payload)
            })
            .addCase(RescheduleAssignedAdress.fulfilled, (state, action) => {
                // if (!action.payload?.manifest) {
                //     console.log('Update could not complete')
                //     state.error = "Update could not complete"
                //     console.log(action.payload)
                //     return;
                // }
                state.loading = false;   
                state.status = 'succeeded'
                state.update = action.payload
            })
            .addCase(RescheduleAssignedAdress.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message
                // state.update = 'yes'
                // console.log(action.payload.manifest)
            })


            

        }
    })



    export const assignedAdress = (state) => state.address;

    export default addressSlice