import React from 'react'
import { Line } from 'react-chartjs-2'
import {Chart as ChartJS, registerables} from 'chart.js'; 
// import  Data from "../../../assets/sampledatafiles/addresses.json"
ChartJS.register(...registerables);



function DataChart({ChartData}) {
//  console.log(ChartData);
  return (
    <>
     <Line 
      data={ChartData}
      height={285}
      width={900}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        animation:{
          tension: {
          duration: 1500,
          easing: 'linear',
          from: 1,
          to: 0,
          loop: true
        }
        }
      }}
     />
    
    </>
  )
}

export default DataChart