import React from 'react'
// import _ from 'lodash'

 const MonthCard = ({VerifiedThisMonth}) => {
  return (
   <>
    <div className="card border-0 shadow"  >
                <div className="card-header">
                  <h6>Monthly Report</h6>
                </div>
                  <div className="card-body">
                      <div className="row align-items-center" style={{"height": "108px"}}>
                          <div className="col-5 text-xl-center mb-3 mb-xl-0 align-items-center justify-content-xl-center">
                              <div className="icon-shape icon-shape-tertiary rounded me-4 me-sm-0">
                                  <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path>
                                  </svg>
                              </div>
                              
                          </div>
                          <div className="col-7 col-xl-7 px-xl-0">
                              <div className="">
                              <h5 className=" fs-6 fw-inter text-decoration-underline" style={{"color": "red"}}>This Month</h5>
                              {!VerifiedThisMonth && <h4 className="fw-extrabold mb-1 font-weight-bold fw-inter" style={{"color": "red"}}><span>Verified: </span>{0}</h4>}
                              {VerifiedThisMonth && <h4 className="fw-extrabold mb-1 font-weight-bold fw-inter" style={{"color": "red"}}><span>Verified: </span>{VerifiedThisMonth?.verified_thisMonth}</h4>}
                              
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
   </>
  )

}
export default MonthCard
