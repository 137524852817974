import './dashboard.css'
import axios from "axios"
import { Link } from "react-router-dom"
import React, { useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { siginUser } from '../../../reducers/userSlice'
import {RiderTopBar} from '../../../components/topbar/RiderTopbar'
import { FaClipboardCheck, FaClipboard } from "react-icons/fa";
import {FooterBar} from '../../../components/general_footer/footer'

export default function RiderDashboard() {
    const userSignin = useSelector(siginUser);
    const { user} = userSignin;
 
    const { token } = user;
    const [addressCount, setAddressCount] = useState([]);
    const backend_url = process.env.REACT_APP_API;
  
    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            const lat = position.coords.latitude
            const lng = position.coords.longitude
            localStorage.setItem('latlng', JSON.stringify({lat, lng}));
        });

        const fetchAddressCount = async () => {
            const response = await axios.get(`${backend_url}/api/rider/address/count`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setAddressCount(response.data)
        }
        fetchAddressCount();
    }, [setAddressCount, token, backend_url]);
  return (
    <>
    {/* <NavigationBar /> */}
    <main className="ridercontent">
      {/* <TopBar /> */}
      <RiderTopBar />
    <div className="riderall">

        <div className="row py-5">
            {/* <div className="col-12 col-sm-4 col-xl-3 mb-4">
                <div className="card border-0 shadow" style={{"height": "166px"}}>
                    <div className="card-body" >
                        <div className="row d-block d-xl-flex align-items-center" style={{"height": "138px"}}>
                            <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                <div className="icon-shape icon-shape-primary rounded me-4 me-sm-0">
                                    <FaClipboardList className=' icon-md icon-fa' />
                                </div>
                            </div>
                            <div className="col-12 col-xl-7 px-xl-0">
                                <div className="">
                                    <h2 className="h5 top-dash" style={{"color": "#F58634"}}>Total Address Given</h2>
                                    <h3 className=" mb-1 low-dash" style={{"color": "#F58634"}}>{ addressCount.address }</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <Link to="/rider/verified" className="dashboard-card col-12 col-sm-4 col-xl-4 mb-4">
                <div className="card border-0 shadow" style={{"height": "166px"}}>
                    <div className="card-body" >
                        <div className="row d-block d-xl-flex align-items-center" style={{"height": "138px"}}>
                            <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                <div className="icon-shape icon-shape-secondary rounded me-4 me-sm-0">
                                    {/* <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd"></path>
                                    </svg> */}
                                    <FaClipboardCheck className=' icon-md icon-fa' />
                                </div>
                            
                            </div>
                            <div className="col-12 col-xl-7 px-xl-0">
                                <div className="">
                                    <h2 className="h5 top-dash" style={{"color": "green"}}>Verified Addresses</h2>
                                    <h3 className=" mb-1 low-dash" style={{"color": "green"}}>{ addressCount.addressVerified }</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
            <Link to="/rider/unverified" className=" dashboard-card col-12 col-sm-4 col-xl-4 mb-4">
                <div className="card border-0 shadow" style={{"height": "166px"}} >
                    <div className="card-body">
                        <div className="row d-block d-xl-flex align-items-center" style={{"height": "138px"}}>
                            <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                <div className="icon-shape icon-shape-tertiary rounded me-4 me-sm-0">
                                    {/* <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                                    </svg> */}
                                    <FaClipboard className=' icon-md icon-fa' />
                                </div>
                                
                            </div>
                            <div className="col-12 col-xl-7 px-xl-0">
                                <div className="">
                                <h2 className="h5 top-dash" style={{"color": "red"}}>UnVerified Addresses</h2>
                                <h3 className="mb-1 low-dash" style={{"color": "red"}}>{ addressCount.addressUnVerified }</h3>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
            <Link to="/rider/unassigned" className=" dashboard-card col-12 col-sm-4 col-xl-4 mb-4">
                <div className="card border-0 shadow" style={{"height": "166px"}} >
                    <div className="card-body">
                        <div className="row d-block d-xl-flex align-items-center" style={{"height": "138px"}}>
                            <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                <div className="icon-shape icon-shape-tertiary rounded me-4 me-sm-0">
                                    {/* <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                                    </svg> */}
                                    <FaClipboard className=' icon-md icon-fa' />
                                </div>
                                
                            </div>
                            <div className="col-12 col-xl-7 px-xl-0">
                                <div className="">
                                <h2 className="h5 top-dash" style={{"color": "red"}}>Unassigned Addresses</h2>
                                <h3 className="mb-1 low-dash" style={{"color": "red"}}>{ addressCount.addressUnAssigned }</h3>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
       
        </div>
    </div>
    <FooterBar />
    </main>
    </>
  )
}
