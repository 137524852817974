import { createSlice, createAsyncThunk  } from "@reduxjs/toolkit";
import axios from "axios";
// import _ from "lodash";


        const backend_url = process.env.REACT_APP_API;


            const initialState = {
                staff: [],
                status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
                error: null,
                remove: false,
                loading: false,
            }

                export const fetchStaff = createAsyncThunk('fetchStaff', async (initialPost,{getState}) => {
                    const {
                        user: { user },
                    } = getState();
                    const { token} = user;

                    const { level } = initialPost;

                    //   console.log(token)
                    //   let level = '1'

                    const { data } = await axios.get(
                        `${backend_url}/api/user/admins/${level}`, 
                        {
                          headers: { Authorization: `Bearer ${token}` },
                        }
                      );

                      return data
                })


                export const fetchRiders = createAsyncThunk('fetchRiders', async (initialPost,{getState}) => {
                  const {
                      user: { user },
                  } = getState();
                  const { token} = user;
                  const { data } = await axios.get(
                      `${backend_url}/api/user/rider/all`, 
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      }
                    );

                    return data
              })


            export const addNewStaff = createAsyncThunk('addNewStaff', async (initialPost,{getState}) => {
                const {
                    user: { user },
                  } = getState();
                  const { token} = user;
                    const rep = await axios.post(
                      `${backend_url}/api/auth/register`,
                      initialPost,
                      
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      }
                    );
                    return rep.data


            })

            export const updateStaff = createAsyncThunk('updateStaff', async (initialPost,{getState}) => {
                const {
                    user: { user },
                  } = getState();
                  const { token} = user; 
                  const { id } = initialPost;               
                const rep = await axios.put(
                    `${backend_url}/api/user/edit/${id}`,
                    initialPost,
                   
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  );


                  return rep.data
            })

            export const deleteStaff = createAsyncThunk('deleteStaff', async (initialPost,{getState}) => {
                const {
                  user: { user },
                  } = getState();
                  const { token} = user; 
               
                  const { id } = initialPost;  
                              
                const rep = await axios.post(
                    `${backend_url}/api/user/delete/${id}`,
                    initialPost,
                   
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  );


                  return rep.data
            })

            export const resetPasswork = createAsyncThunk('resetPasswork', async (initialPost,{getState}) => {
              const {
                user: { user },
                } = getState();
                const { token} = user; 
             
                const { id, newPass } = initialPost;  
                // console.log(token);
                            
              const rep = await axios.post(
                  `${backend_url}/api/auth/resetpassword/${id}/${newPass}`,
                  initialPost,
                 
                  {
                    headers: { Authorization: `Bearer ${token}` },
                  }
                );


                return rep.data
          })




            const adminSlice = createSlice({
                name: 'staff',
                initialState,
                reducers: {
                 
                },
                extraReducers(builder) {
                    builder
                              // get all admin staff
                        .addCase(fetchStaff.pending, (state, action) => {
                            state.status = 'loading'
                            state.loading = true
                        })
                        .addCase(fetchStaff.fulfilled, (state, action) => {
                            state.loading = false
                            state.status = 'succeeded'
                            state.staff = action.payload
                        })
                        .addCase(fetchStaff.rejected, (state, action) => {
                            state.status = 'failed';
                            state.loading = false
                            state.error = action.error.message
                        })
                              // get all rider
                        .addCase(fetchRiders.pending, (state, action) => {
                          state.status = 'loading'
                          state.loading = true
                      })
                      .addCase(fetchRiders.fulfilled, (state, action) => {
                          state.loading = false
                          state.status = 'succeeded'
                          state.staff = action.payload
                      })
                      .addCase(fetchRiders.rejected, (state, action) => {
                          state.status = 'failed';
                          state.loading = false
                          state.error = action.error.message
                      })
                            //  add new stafff and riders
                      .addCase(addNewStaff.pending, (state, action) => {
                        state.status = 'loading'
                        state.loading = true
                    })
                        .addCase(addNewStaff.fulfilled, (state, action) => {
                            state.loading = false
                            state.status = 'succeeded'
                          //  console.log(action.payload.user)
                          
                            state.staff.data.push(action.payload.user)
                            // console.log(current(state))
                        })
                        .addCase(addNewStaff.rejected, (state, action) => {
                          state.status = 'failed';
                          state.loading = false
                          // const staffs = error.response && error.response.data.message
                          // ? error.response.data.message
                          // : error.message

                          // console.log(current(action))
                          
                          // state.error = action.error.message
                          // state.error = action.error.message
                      })
                         .addCase(updateStaff.fulfilled, (state, action) => {
                            // if (!action.payload?.id) {
                            //     console.log('Update could not complete')
                            //     console.log(action.payload)
                            //     return;
                            // }
                            // state.loading = false
                            // console.log(action.payload.user)
                            // console.log(current(state.staff.data))
                            state.status = 'succeeded'
                            const {id} = action.payload.user;
                           
                            const staff = state.staff.data.filter(staf => staf.id !== id);

                            // console.log(staff);
                         
                            state.staff.data = [...staff, action.payload.user];
                            //   state.staff = staff;

                           
                         })
                         .addCase(deleteStaff.fulfilled, (state, action) => {                           
                     
                           const id = Number(action.payload.status); // state.remove = true
                            const staffs = state.staff.data.filter(staf => staf.id !== id);
                            
                            if (staffs) {
                              state.staff.data = staffs;
                            }


                        
                        })
                        .addCase(resetPasswork.fulfilled, (state, action) => {                           
                     
                              state.loading = false
                              state.status = 'Reset succeeded'

                       
                       })
                     
                }
            })

           
            export const adminStaff = (state) => state.staff;

            // console.log(adminStaff)
            // export const adminStaff = (state) => state.staff;

            export default adminSlice