import './WebcamCapture.css'
import React, { useState } from 'react';
import Webcam from "react-webcam";
import {PhoneCam} from './phoneCam'
import {
  FaCamera,
} from "react-icons/fa";

import {
  MdFlipCameraIos
} from "react-icons/md";


// const WebcamComponent = () => <Webcam />;


// const videoConstraints = {
//     width: 220,
//     height: 200,
//     facingMode: "user"
// };

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
  facingMode: FACING_MODE_USER,
  width: 620,
  height: 600,
};

// const WebcamSwitch = () => {
 

const WebcamCapture = () => {
      const [image,setImage]=useState('')
      const webcamRef = React.useRef(null);

      
  
    const capture = React.useCallback(
      () => {
        const imageSrc = webcamRef.current.getScreenshot();

        setImage(imageSrc)
      },
  
      [webcamRef]
     
     
    );
    const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

    const handleClick = React.useCallback(() => {
      setFacingMode(
        prevState =>
          prevState === FACING_MODE_USER
            ? FACING_MODE_ENVIRONMENT
            : FACING_MODE_USER
      );
    }, []);
    
    return (
      
        <div className="webcam-container">
            <div className="webcam-img">
                {image ===''?<Webcam 
                audio={false}
                // height={600} 
                ref={webcamRef} 
                screenshotFormat="image/jpeg" 
                // width={520}
                className="webcam-size"
                videoConstraints={{...videoConstraints,facingMode}}
                />:<img alt="captured" src={image}/>}
                <PhoneCam className="" />
            </div>
            <div className="d-flex justify-content-center">
              <MdFlipCameraIos className="webcam h2 dak"  onClick={handleClick}>Switch camera</MdFlipCameraIos>
              <FaCamera className="h2 cul">
                  {image!==''?
                  <button onClick={(e)=>
                  {
                      e.preventDefault();
                      setImage('')
                      }}
                      className="webcam-btn btn btn-primary but">
                      Retake Image</button>:
                      <button onClick={(e)=>
                      {e.preventDefault();
                      capture();
                      }}
                      className="webcam-btn btn btn-primary but">Capture</button>
                  }
              </FaCamera>
              
            </div>
        </div>
    
      
    );
  };

  export {WebcamCapture};