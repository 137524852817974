import React from 'react'
import { Link } from 'react-router-dom';
import _ from "lodash";
import Spinner from "../../components/spinner/Spinner.jsx"



const UnassignedTable = ({datas, type ,loading}) => {

  const result =  _.sumBy(datas, 'unverifiedUnassigned');
  // console.log(result)
  return (
   
        <div className="alert-waning ">
          <div className="col-12 child alert alert-light ">
             <section>
              <div className="alert alert-warning m-2 text-uppercase text-center fw-bold">
              ALL {result} UN-ASSIGNED ADDRESS(ES) IN { type }'s</div>
              <div className="table-responsive mt-2 ">
                <table className="table fs-13 table-sm table-striped">
                    <thead>
                      <tr className="bevel text-light fw-bold">
                        <th className='text-capitalize'>{ type }</th>
                        <th>{ type === 'zone' ? 'Zonal Manager' : (type === 'region' ? 'Regional Manager' : 'Data Entry Officer') }</th>
                        <th>Total Unassiged Address </th> 
                        <th>Uploaded Date</th>
                        <th>Creator</th>
                      </tr>
                    </thead>
                    {loading
                      ? <div  className="d-flex justify-content-center" style={{ width:"100%"}}> <Spinner  /></div>
                      :
                      <>
                    <tbody>

                    {datas.map((data, i) => (
                         <tr key={i}>
                          <td>
                          { type === 'zone' ?
                           <Link to={`/admin/verificate/unassigned_region/region?id=${data.id}`}>
                           <u className='text-bevel fw-bold'>{ data.name }</u>
                         </Link> 
                          : (type === 'region' ?
                          <Link to={`/admin/verificate/unassigned_state/state?id=${data.id}`}>
                          <u className='text-bevel fw-bold'>{ data.name }</u>
                        </Link>
                          : <Link to={`/admin/verificate/unassigned/manifests?s=${data.name}`}>
                          <u className='text-bevel fw-bold'>{ data.name }</u>
                        </Link>
                          ) }
                          
                          </td>
                          
                          <td className='text-capitalize'>{data.manager.map((data, i) => (
                            <span key={i}>{ data.first_name } { data.last_name }, </span>
                          ))}</td>
                          <td>{data.addressUnassigned } </td>
                          <td> </td>
                          <td> </td>
                        </tr> 
                       ))}
                      
                     
                    
                    </tbody>
                    </> }
                  </table>
              </div>
            </section>
    </div>
    </div>
  )
}

export default UnassignedTable
