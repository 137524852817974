import Swal from 'sweetalert2'
import Spinner from "./spinner/Spinner"
import Modal from 'react-bootstrap/Modal'
import _ from "lodash";
import { siginUser } from "../reducers/userSlice"
import ReactPaginate from "react-paginate"
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { FaExpandAlt, FaEdit, FaTrashAlt,  FaRegistered } from "react-icons/fa"
import { addNewStaff, deleteStaff, resetPasswork, updateStaff } from "../reducers/adminSlice"


export default function UserTable({staff, level , update}) {

    const {staff:adminstaff, loading} = staff
    const { data, current_page } = adminstaff
    // const users = data.slice().sort((a, b) => b.date.localeCompare(a.date))
    const [load, setLoad] = useState(false);
    // const [states, setStates] = useState([])
    const [type, settype] = useState([])
    const [admin_level, setadmin_level] = useState('NULL')
    const [eventType, seteventType] = useState([])
    const [pageNumber, setPageNumber] = useState(0)
    const [PerPag, setPerPag] = useState(10);
    const [searchName, setSearchName] = useState("")
    const [newPass, setNewPass] = useState("")

    const userSignin = useSelector(siginUser);
    const { user} = userSignin;
    const {  user:loggedInUser, } = user;
    const { access_state: states, access_region: regions } = loggedInUser;
    const dispatch = useDispatch();
    const [modalData, setModalData] = useState({
      // id: "",
      last_name: "",
      first_name: "",
      // other_name: "",
      email: "",
      username: "",
      password: "",
      confirm_password: "",
      state: "",
      region: "",
      // type: "",
      // phone: "",
      // admin_level: ""
    }) 
    const handleEdit = (e) => {
      setModalData((prev=>({...prev, [e.target.name]:e.target.value})))
    }

    const addState = (id) => {
      setModalData(prev=>({...prev, 'state':id}))
      states.forEach((el)=>{
        if (el.id === Number(id)) {
          setModalData((prev=>({...prev, 'region':el.region_id})))
        }
      })
    }

    const { last_name, first_name, email, password, state, region, confirm_password, username, id } = modalData;
    const [show, setShow] = useState(false);
    const [error, setError] = useState('');
    const [passShow, setpassShow] = useState(false);
    const passhandleShow = () => setpassShow(true);
    const handleClose = () => {
      setShow(false);
      seteventType("");
      setModalData({
          last_name: "",
          first_name: "",
          // other_name: "",
          email: "",
          username: "",
          passwor: "",
          confirm_password: "",
          state: "",
          region: "",
        });
    }

    const passhandleClose = () => { 
      setpassShow(false);
      setModalData({
        id: "",
      });
    }
    const handleShow = () => setShow(true);
          
    const editData = (d) => {
      // console.log(d);
      setModalData(d)
      seteventType("edit")
      handleShow()
    }

    function generateRandomPassword() {
      const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789@#$%&*_?';
      const uppercaseCharset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const lowercaseCharset = 'abcdefghijklmnopqrstuvwxyz';
      const numberCharset = '123456789';
      const specialCharset = '@$_';

      let result = '';

      // Generate first character (not a special character or number)
      const firstCharSet = uppercaseCharset + lowercaseCharset;
      result += firstCharSet[Math.floor(Math.random() * firstCharSet.length)];


      // Ensure at least 1 character from each category
      result += uppercaseCharset[Math.floor(Math.random() * uppercaseCharset.length)];
      result += lowercaseCharset[Math.floor(Math.random() * lowercaseCharset.length)];
      result += numberCharset[Math.floor(Math.random() * numberCharset.length)];
      result += specialCharset[Math.floor(Math.random() * specialCharset.length)];

      // Generate remaining characters
      for (let i = 5; i < 7; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        result += charset[randomIndex];
      }
      
      
      // Shuffle the characters
      let shuffledPassword = result.split('').sort(() => 0.5 - Math.random()).join('');
      const randomUppercase = Math.floor(Math.random() * uppercaseCharset.length);
      shuffledPassword = uppercaseCharset[randomUppercase] + shuffledPassword;
      
      setNewPass(shuffledPassword)
    }

    const passData = (d) => {
      // console.log(d);
      generateRandomPassword()
      setModalData(d)
      passhandleShow()
    }

    const removeData = (id) => {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
          dispatch(deleteStaff({
            id
          })).unwrap()
        }
      })
    }

    const showData = (d) => {
      // console.log(d);
      setModalData(d)
      seteventType("show")
      handleShow()
    }

    //  Admin create users details
    const saveChanges = async (e) => {
      e.preventDefault();
      setError("")
      if (confirm_password !== password) {
        setError("Passwords don't match!")
      } else {
        try {
          setLoad(true)
          await dispatch(
            addNewStaff({last_name, first_name, email, username, admin_level, password, state, region, type})
          ).unwrap()
          handleClose()
          setLoad(false)
        } catch (err) {
          console.error('Failed to save the post', err)
          setLoad(false)
          console.log(err);
        } 
      }
    }

    //  Admin edit users details
    const editChanges = async (e) => {
      e.preventDefault();
      // console.log('working');
      setLoad(true)
      try {   
        dispatch(updateStaff({
          last_name,
          first_name, 
          email, 
          username,
          admin_level,
          password, 
          state,
          region,
          type,
          id
        })).unwrap()
        Swal.fire('updated successful')
      } catch (err) {
        console.error('Failed to save the post', err)
        setLoad(false)
      } finally {
        handleClose()
        // seteditShow(false)
        setLoad(false)
        setModalData({
          last_name: "",
          first_name: "",
          // other_name: "",
          email: "",
          username: "",
          passwor: "",
          confirm_password: "",
          state: "",
          region: "",
        });
      }
    }
    
    useEffect(() => {
      if (level === '1') {
        settype('admin');
        setadmin_level('1')
      }else if (level === '2') {
        settype('admin');
        setadmin_level('2')
      }else if (level === '3') {
        settype('admin');
        setadmin_level('3')
      } else if (level === '4') {
        settype('admin');
        setadmin_level('4')
      } else  {
        settype('rider');
        // setadmin_level('4')
      }
    }, [level]);

    const Resetpassword = async (e) => {

      try {
        dispatch(resetPasswork({
          id, newPass
        })).unwrap()
        passhandleClose()
        Swal.fire('Reset password successful')
        
      } catch (error) {
        console.log(error);
        passhandleClose()
      }
      

    }

    const onpagChanged = e => setPerPag(Number(e.target.value))
    const addressPerPage =  Number(PerPag);
    const pagesVisited = Number(pageNumber) * Number(addressPerPage);
    const addressTotal =  _.size(data);
    // const addressTotal = 4;
    // console.log(addressTotal);
    const pageCount = Math.ceil(addressTotal / addressPerPage);
    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };

    // search by name
    const keys = ["first_name", "last_name", "email"];

    const search = (data) => {
      if(data) {
        return data.filter((item) =>
        keys.some((key) => item[key]?.toLowerCase().includes(searchName.toLowerCase()))
        )
      }
    };

  return (
    <>
      <div  className="alert-waning ">
        <div  className="col-12 child alert alert-light ">
          <div className="" data-title="Buttons_wrapper"></div>
          <section>
            <div className="table-responsive mt-2 bg-white p-1">
              <form className="row form-group p-2" >
                <div className="col-12">
                  <div className="col-3">
                    {/* <input type="" value="Add super Admin"  className="form-control btn btn-small btn-dark" data-bs-toggle="modal" data-bs-target="#exampleModal" /> */}
                    <button type="button" className="btn btn-small btn-dark" onClick={handleShow}>Add { level === '1' ? 'Super Admin' : level === '2' ? 'ZM' : level === '3' ? 'RM' :  level === '4' ? 'DEO' : 'Rider' }</button>
                  </div>
                </div>
              </form>
              
              <div className="alert alert-warning m-2 bold text-uppercase text-center">
                { level === '1' ? "Manage Super Admin's Account" : level === '2' ? "Manage Zonal Manager's Account" : level === '3' ? "Manage Regional Manager's Account" :  level === '4' ? "Manage Data Entry Officer's Account" : "Rider" }
              </div>
              <div><span className="float-right mb-2"></span></div>
              <div className="row mb-3 d-flex justify-content-between">
                <div className="col-1">
                  <label className="label-control">No/Page</label>
                  <select name="pp" onChange={onpagChanged} value={PerPag} className="form-control">
                    <option value='10'>10</option>
                    <option value='20'>20</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                    <option value='500'>500</option>
                  </select>
                </div>
                <div className="col-3 mt-2">
                  <label className="label-control">search</label>
                  <input type="text" name="mn" placeholder="search by Name or Email" className="form-control" onChange={(e) => setSearchName(e.target.value)}/>
                </div>
              </div>
              <>
                {loading
                  ? <div  className="d-flex justify-content-center" style={{ width:"100%"}}> <Spinner  /></div>
                  :
                  <table className="table  fs-13 table-sm">
                    <caption>
                      <span className="float-right"></span>
                    </caption>
                    <thead>
                      <tr className="bevel text-light">
                        <th className="">
                            S/N
                        </th>
                        <th className="">Action</th>
                        <th>Firstname</th>
                        <th>Lastname</th>
                        <th>Email</th>
                        <th>Username</th> 
                      </tr>
                    </thead>
                
                    <tbody id="t_body">
                      { search(data)?.slice(pagesVisited, pagesVisited + addressPerPage)?.map((data, i) => (
                        <tr  key={i}>
                          <td className="sorting_1">
                            { ++i }
                          </td>
                          <td>
                            <span className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                              <span className="btn btn-dark me-2" title="Show User"  type="button" onClick={()=>showData(data)} ><FaExpandAlt /></span>
                              <span className="btn btn-dark me-2" title="Edit User" onClick={()=>editData(data)} ><FaEdit/></span>
                              {loggedInUser.level === "1" &&
                              <button className="btn btn-dark me-2" title="Reset User Password" onClick={()=>passData(data)}><FaRegistered/></button>
                              }
                              <button className="btn btn-dark" title="Delete User" type="button" onClick={()=>removeData(data.id)}><FaTrashAlt/></button>
                            </span>
                          </td>
                          <td>{data.first_name}</td>
                          <td>{data.last_name}</td>
                          <td>{data.email}</td>
                          <td>{data.username}</td>  
                        </tr>
                      ))}
                    </tbody>
                  </table>  
                }
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  activePage={current_page}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns pt-5"}
                  previousLinkClassName={"previousBttn "}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              </>
            </div>
          </section>
        </div>
        
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="example-modal-sizes-title-lg">
          <form onSubmit={ eventType === 'edit' ? editChanges : saveChanges}>
            <Modal.Header closeButton>
                <h4 className="modal-title">{ eventType === 'edit' ? 'Edit User' : eventType === 'show' ? 'Show user Detail' : 'Add New User'}</h4>
            </Modal.Header>
            <Modal.Body> 
                    <div className="row">
                    <dl className="col-6">
                          <dt>First Name</dt>
                          <dd><input onChange={handleEdit} readOnly={eventType === "show" ? true : false} required name="first_name" value={modalData.first_name} type="text" className="form-control" /></dd>
                      </dl>
                        
                        <dl className="col-6">
                            <dt>Last Name</dt>
                            <dd><input onChange={handleEdit} readOnly={eventType === "show" ? true : false} required name="last_name" value={modalData.last_name} type="text" className="form-control" /></dd>
                        </dl>
                    </div>
                    <div className="row">
                        <dl className="col-6">
                          <dt>Email</dt>
                          <dd><input onChange={handleEdit} readOnly={eventType === "show" ? true : false} value={modalData.email} required name="email" type="text" className="form-control" /></dd>
                      </dl>


                        <dl className="col-6">
                            <dt>Username</dt>
                            <dd><input onChange={handleEdit} readOnly={eventType === "show" ? true : false} value={modalData.username} required name="username" type="text" className="form-control" /></dd>
                        </dl>
                    </div> 
                    { eventType !== "show" && eventType !== "edit" ? 

                      <div className="row">
                          <dl className="col-6">
                              <dt>Password</dt>
                              <dd><input onChange={handleEdit} value={modalData.password} required name="password" type="password" className="form-control" /></dd>
                          </dl>

                          <dl className="col-6">
                              <dt>Confirm Password</dt>
                              <dd><input onChange={handleEdit} value={modalData.confirm_password} required name="confirm_password" type="password" className="form-control" /></dd>
                          </dl>
                      </div>
                      
                      : <></>
                    
                    }

                    <div className="row">
                    <dl className="col-6">
                        <dt>State</dt>
                        <dd>
                            <select className="form-control" onChange={(e) =>{ addState(e.target.value)} } disabled={eventType === "show" ? true : false} value={modalData.state} required name="state" >
                                <option value="">Select State</option>
                                { states?.map((data, i) => {
                                return <option key={i} value={data.id}>{ data.name }</option>
                                })}
                                {/* {stateOptions} */}
                            </select>
                        </dd>
                    </dl>
                    <dl className="col-6">
                      <dt>Region</dt>
                      <dd>
                        <select className="form-control" onChange={handleEdit} disabled value={modalData.region} required name="region" >
                          <option value="">Select Region</option>
                          { regions?.map((data, i) => {
                            return <option key={i} value={data.id}>{ data.name }</option>
                          })}
                        </select>
                      </dd>
                    </dl>
                    </div>
                {/* </form> */}
                { error && <div className="text-center text-danger">{ error }</div> }
                
            </Modal.Body>
            <Modal.Footer>
              { eventType !== 'show' ?
                <>
                { !load ? <input type="submit" className="btn btn-success" value="Generate"/> :
                <input type="submit" className="btn btn-success" value="Loading..." disabled/> }

                <button type="button" className="btn btn-dark" onClick={handleClose}>Close</button>
                </>
              :
              <table className="table  fs-13 table-sm">
                <caption>
                    <span className="float-right"></span>
                </caption>
                <thead>
                  <tr className="bevel text-light">
                    <th className="">S/N</th>
                    <th className="">Action</th>
                    <th>Firstname</th>
                    <th>Lastname</th>
                    <th>Email</th>
                    <th>Username</th>
                  </tr>
                </thead>
                <tbody id="t_body">  
                  { search(data)?.slice(pagesVisited, pagesVisited + addressPerPage)?.map((data, i) => (
                    <tr  key={i}>
                      <td className="sorting_1">
                        { ++i }
                      </td>
                      <td>
                        <span className="btn-group btn-group-sm" role="group" aria-label="Basic example">
                          <span className="btn btn-danger me-2" title="Show User"  type="button" onClick={()=>showData(data)}>
                            <FaExpandAlt/>
                          </span>
                          <span className="btn btn-danger me-2" title="Edit User" onClick={()=>editData(data)} ><FaEdit/></span>
                          {
                            loggedInUser.level === "1" &&
                            <button className="btn btn-danger me-2" title="Reset User Password" onClick={()=>passData(data)}><FaRegistered/></button>
                          }
                          <button className="btn btn-danger" title="Delete User" type="button" onClick={()=>removeData(data.id)}><FaTrashAlt/></button>
                        </span>
                      </td>
                      <td>{data.first_name}</td>
                      <td>{data.last_name}</td>
                      <td>{data.email}</td>
                      <td>{data.username}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            }
            </Modal.Footer>
          </form>
        </Modal>

        <Modal show={passShow} onHide={passhandleClose} size="lg"   aria-labelledby="example-modal-sizes-title-lg">
        
            <form >
                <Modal.Header closeButton>
                    <h4 className="modal-title ">Rest Password</h4>
                </Modal.Header>
                <Modal.Body> 
                  
                <div className='d-flex flex-column justify-content-center'>
                  <div className='mb-2'> <span className='fw-bolder fs-6 ms-1'>{first_name}</span>
                  <span className='fw-bolder fs-6 mx-1'>{last_name}</span> 
                  Password would be reset as:  <span className='fw-bolder fs-5 ms-1'>{newPass}</span> </div>

                  <button type="button" style={{ width:"50%", margin:"auto", background:"green"}} className="btn btn-dark d-flex justify-content-center p-2"  onClick={Resetpassword}>Reset Password</button>
                </div>    
                  
              </Modal.Body>
              <Modal.Footer>

                      <button type="button" className="btn btn-dark "  onClick={passhandleClose}>Close</button>
                      
              </Modal.Footer>
            </form>
        </Modal>
        
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="example-modal-sizes-title-lg">
          <form onSubmit={ eventType === 'edit' ? editChanges : saveChanges}>
            <Modal.Header closeButton>
              <h4 className="modal-title">{ eventType === 'edit' ? 'Edit User' : eventType === 'show' ? 'Show user Detail' : 'Add New User'}</h4>
            </Modal.Header>
            <Modal.Body> 
              <div className="row">
                <dl className="col-6">
                  <dt>First Name</dt>
                  <dd><input onChange={handleEdit} readOnly={eventType === "show" ? true : false} required name="first_name" value={modalData.first_name} type="text" className="form-control" /></dd>
                </dl>
                <dl className="col-6">
                  <dt>Last Name</dt>
                  <dd><input onChange={handleEdit} readOnly={eventType === "show" ? true : false} required name="last_name" value={modalData.last_name} type="text" className="form-control" /></dd>
                </dl>
              </div>
              <div className="row">
                <dl className="col-6">
                  <dt>Email</dt>
                  <dd><input onChange={handleEdit} readOnly={eventType === "show" ? true : false} value={modalData.email} required name="email" type="text" className="form-control" /></dd>
                </dl>
                <dl className="col-6">
                  <dt>Username</dt>
                  <dd><input onChange={handleEdit} readOnly={eventType === "show" ? true : false} value={modalData.username} required name="username" type="text" className="form-control" /></dd>
                </dl>
              </div> 
              { eventType !== "show" && eventType !== "edit" ?
                <div className="row">
                  <dl className="col-6">
                    <dt>Password</dt>
                    <dd><input onChange={handleEdit} value={modalData.password} required name="password" type="password" className="form-control" /></dd>
                  </dl>
                  <dl className="col-6">
                    <dt>Confirm Password</dt>
                    <dd><input onChange={handleEdit} value={modalData.confirm_password} required name="confirm_password" type="password" className="form-control" /></dd>
                  </dl>
                </div>
                : <></>
              }
              <div className="row">
                <dl className="col-6">
                  <dt>State</dt>
                  <dd>
                    <select className="form-control" onChange={(e) =>{ addState(e.target.value)} } disabled={eventType === "show" ? true : false} value={modalData.state} required name="state" >
                      <option value="">Select State</option>
                      { states?.map((data, i) => {
                        return <option key={i} value={data.id}>{ data.name }</option>
                      })}
                    </select>
                  </dd>
                </dl>
                <dl className="col-6">
                  <dt>Region</dt>
                  <dd>
                    <select className="form-control" onChange={handleEdit} disabled value={modalData.region} required name="region" >
                      <option value="">Select Region</option>
                      { regions?.map((data, i) => {
                        return <option key={i} value={data.id}>{ data.name }</option>
                      })}
                    </select>
                  </dd>
                </dl>
              </div>
              { error && <div className="text-center text-danger">{ error }</div> }
            </Modal.Body>
            <Modal.Footer>
              { eventType !== 'show' ?
                <>
                { !load ? <input type="submit" className="btn btn-success" value="Generate"/> :
                <input type="submit" className="btn btn-success" value="Loading..." disabled/> }
                <button type="button" className="btn btn-dark" onClick={handleClose}>Close</button>
                </>
                :
                <button type="button" className="btn btn-dark" onClick={handleClose}>Close</button>
              }
            </Modal.Footer>
          </form>
        </Modal>

        <Modal show={passShow} onHide={passhandleClose} size="lg"   aria-labelledby="example-modal-sizes-title-lg">
          <form >
            <Modal.Header closeButton>
              <h4 className="modal-title ">Rest Password</h4>
            </Modal.Header>
            <Modal.Body>
              <div className='d-flex flex-column justify-content-center'>
                <div className='mb-2'>
                  <span className='fw-bolder fs-6 ms-1'>{first_name}</span>
                  <span className='fw-bolder fs-6 mx-1'>{last_name}</span> 
                  Password would be reset as:  <span className='fw-bolder fs-5 ms-1'>{newPass}</span>
                </div>
                <button type="button" style={{ width:"50%", margin:"auto", background:"green"}} className="btn btn-dark d-flex justify-content-center p-2"  onClick={Resetpassword}>Reset Password</button>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-dark "  onClick={passhandleClose}>Close</button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </>
  );
}
export {UserTable};