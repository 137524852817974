import axios from "axios";
import "./taskmanifest.css";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";
import { siginUser } from "../../../reducers/userSlice";
import { Link, useSearchParams } from "react-router-dom";
import { TopBar } from "../../../components/topbar/TopBar";
import { FooterBar } from "../../../components/general_footer/footer";
import { NavigationBar } from "../../../components/navigationbar/NavigationBar";

export default function Taskmanifest() {
  const userSignin = useSelector(siginUser);
  const { user } = userSignin;
  const { token, riders_description } = user;

  const [searchParams] = useSearchParams();
  const manifest_no = searchParams.get("m");

  const backend_url = process.env.REACT_APP_API;
  const [lgaData, setLGAData] = useState([]);
  const [states, setState] = useState([]);
  const [error, setError] = useState([]);
  const [addresses, setAddress] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const { current_page, per_page, total } = addresses;
  const pageCount = Math.ceil(Number(total ? total : 0) / Number(per_page ? per_page : 1));

  const changePage = ({ selected }) => {
    let pageNumbe = selected + 1;
    setPageNumber(pageNumbe);
  };

  const [sidebarWidth, setSidebarWidth] = useState("sidebar-width");
  const [mainWidth, setMainWidth] = useState("ml-10");
  const toggleNavbar = () => {
    sidebarWidth === "sidebar-width" ? setSidebarWidth("") : setSidebarWidth("sidebar-width");
    mainWidth === "ml-10" ? setMainWidth("") : setMainWidth("ml-10");
  };

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [addressImgFile, setAddressImgFile] = useState([]);
  const [landmarkImgFile, setLandmarkImgFile] = useState([]);
  const [modalData, setModalData] = useState({
    id: "",
    first_name: "",
    last_name: "",
    address: "",
    territory: "",
    lga: "",
    state: "",
    status: "",
    v_status: "",
    resident: "",
    remark: "",
    descriptions: "",
  });

  const { id, first_name, last_name, address, territory, lga, state, status, v_status, resident, remark, descriptions } = modalData;

  const [showEdit, setShowEdit] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleEditClose = () => setShowEdit(false);
  const handleUpdateClose = () => setShowUpdate(false);

  const editData = (d) => {
    setModalData(d);
    setShowEdit(true);
    getStateLga(d.state);
  };

  const updateData = (d) => {
    setModalData(d);
    setAddressImgFile([]);
    setLandmarkImgFile([]);
    setShowUpdate(true);
  };

  const handleEdit = (e) => {
    setModalData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    e.target.name === "state" && getStateLga(e.target.value);
  };

  const submitEdit = async (event) => {
    event.preventDefault();
    try {
      setLoadingEdit(true);
      await axios.put(
        `${backend_url}/api/addresses/${id}`,
        {
          first_name,
          last_name,
          address,
          territory,
          lga,
          state,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const index = addresses.data.findIndex((object) => {
        return object.id === modalData.id;
      });
      addresses.data[index] = modalData;
      setAddress((currentData) => currentData);

      setLoadingEdit(false);
    } catch (err) {
      console.error("Failed to save the post", err);
    } finally {
      setShowEdit(false);
      setLoadingEdit(false);
    }
  };

  const submiUpdate = async (event) => {
    event.preventDefault();
    let address = "";
    let addressImg = "";
    let landmarkImg = "";
    setError("");
    if (
      (v_status &&
        remark &&
        descriptions &&
        landmarkImgFile.length !== 0 &&
        ((v_status === "Yes" && resident) || (v_status === "No" && !resident) || (v_status === "No" && resident)) &&
        ((v_status === "Yes" && addressImgFile.length !== 0) || (v_status === "No" && !addressImgFile.length !== 0) || (v_status === "No" && addressImgFile.length !== 0))) ||
      status === "incomplete"
    ) {
      setLoadingUpdate(true);

      const res = new FormData();
      res.append("address_img", addressImgFile);
      res.append("landmark_img", landmarkImgFile);

      try {
        const { data } = await axios.post(`${backend_url}/api/address/upload-image`, res, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });
        addressImg = data && data["address_img"] ? `https://fortressimages.fra1.digitaloceanspaces.com/${data["address_img"]}` : "";
        landmarkImg = data && data["landmark_img"] ? `https://fortressimages.fra1.digitaloceanspaces.com/${data["landmark_img"]}` : "";

        // const latlng = JSON.parse(localStorage.getItem("latlng"));

        // if (latlng) {
        //   const { data } = await axios.get(`https://api.positionstack.com/v1/reverse?access_key=b2c573d3780e3aba1a77626e2633a472&query=${latlng.lat},${latlng.lng}`);
        //   address = data.data[0].street + " " + data.data[0].county + " " + data.data[0].region;
        // } else if (latitude && longitude) {
        //   axios.get(`https://api.positionstack.com/v1/reverse?access_key=b2c573d3780e3aba1a77626e2633a472&query=${latitude},${longitude}`).then(
        //     (response) => {
        //       address = (response.data.data[0].street && response.data.data[0].street) + " " + response.data.data[0].county + " " + response.data.data[0].region;
        //     },
        //     (error) => {
        //       console.error(error);
        //       // setLoading(false)
        //       setError(error.response.data);
        //     }
        //   );
        // }

        try {
          await axios.post(
            `${backend_url}/api/manifest/update`,
            {
              id: id,
              status: status,
              v_status: v_status,
              resident: resident,
              remark: remark,
              descriptions: descriptions,
              address: address,
              address_img: addressImg,
              landmark_img: landmarkImg,
              latitude: latitude,
              longitude: longitude,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          const index = addresses.data.findIndex((object) => {
            return object.id === modalData.id;
          });
          addresses.data[index] = modalData;
          setAddress((currentData) => currentData);

          setShowUpdate(false);
          setLoadingUpdate(false);
        } catch (err) {
          setLoadingUpdate(false);
          setError(err.response.data.message);
          console.error("Failed to save the post", err);
        }
      } catch (error) {
        console.log(error);
        setLoadingUpdate(false);
        setError("Couldn't Update check your connection and try again");
      }
    } else {
      if (landmarkImgFile.length === 0) setError("Upload Landmark Image");
      if (v_status === "Yes" && addressImgFile.length === 0) setError("Upload Address Image");
      if (!descriptions) setError("Description of location field is required");
      if (v_status === "Yes" && !resident) setError("Does the Applicant reside there field is required");
      if (!v_status) setError("Does the Address Exist field is required");
    }
  };

  const getStateLga = (name) => {
    states.map((data, i) => {
      return data.name.toLowerCase() === name.toLowerCase() && setLGAData(data.lga);
    });
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  }, []);

  useEffect(() => {
    const fetchAddress = async () => {
      const addressData = await axios.get(`${backend_url}/api/manifest/${manifest_no}?page=${pageNumber}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAddress(addressData.data);
    };

    const fetchStates = async () => {
      const { data } = await axios.get(`${backend_url}/api/state`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setState(data);
    };

    fetchAddress();
    fetchStates();
  }, [token, backend_url, manifest_no, pageNumber]);

  return (
    <>
      <NavigationBar sidebarWidth={sidebarWidth} />
      <main className={`content  ${mainWidth}`}>
        <TopBar toggleNavbar={toggleNavbar} />
        <div className="all">
          <div className="py-4">
            <div className="long d-none d-md-inline-block">
              <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                <li className="breadcrumb-item">
                  <Link className="font-weight-bolder text-dark" to="">
                    <svg className="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                    </svg>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li className="breadcrumb-item"></li>
              </ol>
            </div>
          </div>

          <div className="alert-waning " style={{ fontSize: "13px" }}>
            <div className="col-12 child alert alert-light">
              <div className="" data-title="Buttons_wrapper"></div>
              <section>
                <div className="alert alert-warning m-2 bold text-uppercase text-center">Task Assigned to Manifest Number {manifest_no} </div>
                <div className="table-responsive mt-2 bg-white p-1">
                  {/* <form className="row form-group p-2">
                    <div className="col-12">
                      <div className="d-flex justify-content-end">
                        <div className="col-3">
                          <input type="submit" value="Download Manifest" className="form-control btn btn-dark" style={{"fontSize": "13px"}} />
                        </div>
                      </div>
                    </div>
                  </form> */}

                  <div>
                    <span className="float-right mb-2"></span>
                  </div>
                  <table className="table fs-13 table-sm">
                    <caption>
                      <span className="text-left">
                        Showing {addresses.from} to {addresses.to} of {addresses.total} manifest(s)
                      </span>
                      <span className="float-right"></span>
                    </caption>
                    <thead>
                      <tr className="bevel text-light">
                        <th className="sorting_asc" style={{ width: "23px" }}>
                          S/N
                        </th>
                        <th className="sorting" style={{ width: "103.217px" }}>
                          Name
                        </th>
                        <th className="sorting" style={{ width: "193.55px" }}>
                          Address
                        </th>
                        <th className="sorting" style={{ width: "99.8px" }}>
                          Territory
                        </th>
                        <th className="sorting" style={{ width: "67.3667px" }}>
                          LGA
                        </th>
                        <th className="sorting" style={{ width: "84px" }}>
                          Description
                        </th>
                        <th className="sorting" style={{ width: "89.5667px" }}>
                          Does the Address Exist?
                        </th>
                        <th className="sorting" style={{ width: "117.217px" }}>
                          Does the Applicant reside there?
                        </th>
                        <th className="sorting" style={{ width: " 52px" }}>
                          Remark
                        </th>
                        <th className="sorting" style={{ width: "69.2833px" }}>
                          ACTION
                        </th>
                      </tr>
                    </thead>
                    <tbody id="t_body">
                      {addresses.data &&
                        addresses.data.map((data, i) => {
                          return (
                            <tr key={i}>
                              <td>{i + addresses.from}</td>
                              <td>
                                {data.first_name} {data.last_name}
                              </td>
                              <td>{data.address}</td>
                              <td>{data.territory}</td>
                              <td>{data.lga}</td>
                              <td>{data.remark}</td>
                              <td>{data.v_status}</td>
                              <td>{data.resident}</td>
                              <td>{riders_description[data.descriptions] ? riders_description[data.descriptions] : data.descriptions}</td>
                              <td style={{ fontSize: "13px" }}>
                                <i className="fa fa-book btn btn-sm btn-danger mb-2" onClick={() => editData(data)}>
                                  Edit
                                </i>
                                {!data.v_status && (
                                  <i className="fa fa-book btn btn-sm btn-dark" onClick={() => updateData(data)}>
                                    Update
                                  </i>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    activePage={current_page}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns pt-5"}
                    previousLinkClassName={"previousBttn "}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  />
                </div>
              </section>
            </div>
          </div>
          <FooterBar />
        </div>

        <Modal show={showEdit} onHide={handleEditClose} aria-labelledby="example-modal-sizes-title-lg">
          <form onSubmit={submitEdit}>
            <Modal.Header closeButton>
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Address
              </h5>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <dl className="col-6">
                  <dt>First Name</dt>
                  <dd>
                    <input onChange={handleEdit} name="first_name" value={modalData.first_name} type="text" className="form-control" />
                  </dd>
                </dl>

                <dl className="col-6">
                  <dt>Last Name</dt>
                  <dd>
                    <input onChange={handleEdit} name="last_name" value={modalData.last_name} type="text" className="form-control" />
                  </dd>
                </dl>
              </div>
              <div className="row">
                <dl className="col-6">
                  <dt>Address</dt>
                  <dd>
                    <input onChange={handleEdit} name="address" value={modalData.address} type="text" className="form-control" />
                  </dd>
                </dl>
                <dl className="col-6">
                  <dt>Territory</dt>
                  <dd>
                    <input onChange={handleEdit} name="territory" value={modalData.territory} type="text" className="form-control" />
                  </dd>
                </dl>
              </div>
              <div className="row">
                <dl className="col-6">
                  <dt>State</dt>
                  <dd>
                    <select className="form-control" value={modalData.state} name="state" onChange={handleEdit}>
                      <option value="">Choose State</option>
                      {states.map((data, i) => {
                        return (
                          <option key={i} value={data.name.toUpperCase()}>
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                  </dd>
                </dl>
                <dl className="col-6">
                  <dt>LGA</dt>
                  <select className="form-control" value={modalData.lga} name="lga" onChange={handleEdit}>
                    <option value="">Choose LGA </option>
                    {lgaData.map((data, i) => {
                      return (
                        <option key={i} value={data.name.toUpperCase()}>
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                </dl>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-dark" onClick={handleEditClose}>
                Close
              </button>
              {!loadingEdit ? <input type="submit" className="btn bevel" value="Save Changes" /> : <input type="submit" className="btn bevel" value="Loading..." disabled />}
            </Modal.Footer>
          </form>
        </Modal>

        <Modal show={showUpdate} onHide={handleUpdateClose} aria-labelledby="example-modal-sizes-title-lg">
          <form onSubmit={submiUpdate}>
            <Modal.Header closeButton>
              <h5 className="modal-title" id="exampleModalLabel">
                Update Address
              </h5>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <dl className="col-5">
                  <dt>Name</dt>
                  <dd>
                    <p id="apname">
                      {modalData.first_name} {modalData.last_name}
                    </p>
                  </dd>
                </dl>

                <dl className="col-5">
                  <dt>Address</dt>
                  <dd>
                    <p id="apaddr">{modalData.address}</p>
                  </dd>
                </dl>
                <dl className="col-2">
                  <dt>State</dt>
                  <dd>
                    <p id="apstate">{modalData.state}</p>
                  </dd>
                </dl>
              </div>

              <div className="row">
                <dl className="col-6">
                  <dt>Do Address Exist?</dt>
                  <dd>
                    <select className="form-control" value={modalData.v_status} required name="v_status" onChange={handleEdit}>
                      <option value="">Select Option</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </dd>
                </dl>
                <dl className="col-6">
                  <dt>Does Applicant live there?</dt>
                  <dd>
                    <select className="form-control" value={modalData.resident} required name="resident" onChange={handleEdit}>
                      <option value="">Select Option</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Cannot ascertain">Cannot ascertain</option>
                    </select>
                  </dd>
                </dl>
              </div>

              <div className="row">
                <dl className="col-6">
                  <dt>Status</dt>
                  <dd>
                    <select className="form-control" value={modalData.status} name="status" onChange={handleEdit}>
                      <option value="completed">Completed</option>
                      <option value="incomplete">Incomplete</option>
                    </select>
                  </dd>
                </dl>
                <dl className="col-6">
                  <dt>Remarks</dt>
                  <dd>
                    <select className="form-control" value={modalData.descriptions} required name="descriptions" onChange={handleEdit}>
                      <option value="">Select Option</option>
                      {Object.keys(riders_description).map((key, index) => {
                        return (
                          <option key={index} value={key}>
                            {riders_description[key]}
                          </option>
                        );
                      })}
                    </select>
                  </dd>
                </dl>
              </div>

              <div className="row">
                <dl className="col-12">
                  <dt>Description</dt>
                  <dd>
                    <textarea className="form-control" name="remark" value={modalData.remark} required onChange={handleEdit}></textarea>
                  </dd>
                </dl>
              </div>

              <div className="row">
                <dl className="col-5">
                  <dt>Upload Address Image</dt>
                  <dd>
                    <input type="file" accept="image/*" name="addressImgFile" onChange={(e) => setAddressImgFile(e.target.files[0])} />
                  </dd>
                </dl>
                <dl className="col-7">
                  <dt>Upload Address Landmark Image</dt>
                  <dd>
                    <input type="file" accept="image/*" name="landmarkImgFile" onChange={(e) => setLandmarkImgFile(e.target.files[0])} />
                  </dd>
                </dl>
              </div>
              {error && <div className="text-center text-danger">{error}</div>}
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-dark" onClick={handleUpdateClose}>
                Close
              </button>
              {!loadingUpdate ? <input type="submit" className="btn bevel" value="Save Changes" /> : <input type="submit" className="btn bevel" value="Loading..." disabled />}
            </Modal.Footer>
          </form>
        </Modal>
      </main>
    </>
  );
}
