import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react'
import '../../../components/navigationbar/NavigationBar.css'
import { TopBar } from '../../../components/topbar/TopBar.jsx'
import { NavigationBar } from '../../../components/navigationbar/NavigationBar.jsx'
import './dashboard.css'
import {
    FaClipboardCheck,
    FaClipboardList,
    FaClipboard
  } from "react-icons/fa";
import { siginUser } from '../../../reducers/userSlice';
import {FooterBar} from '../../../components/general_footer/footer'


export default function Home() {
    const userSignin = useSelector(siginUser);
    const { user} = userSignin;
 
    const { token, user: loggedInUser } = user;
    // console.log(loggedInUser.state.name);
    const [addressCount, setAddressCount] = useState([]);
    const backend_url = process.env.REACT_APP_API;

    const [sidebarWidth, setSidebarWidth] = useState('sidebar-width')
    const [mainWidth, setMainWidth] = useState('ml-10')

    const toggleNavbar = ()=>{
        sidebarWidth === 'sidebar-width' ? setSidebarWidth('') : setSidebarWidth('sidebar-width');
        mainWidth === 'ml-10' ? setMainWidth('') : setMainWidth('ml-10');
    }
  
    useEffect(() => {
        const fetchAddressCount = async () => {
            const response = await axios.get(`${backend_url}/api/address/count`, 
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setAddressCount(response.data)
        }
        fetchAddressCount();
    }, [setAddressCount, token, backend_url]);

  return (
    <>
        <NavigationBar sidebarWidth={sidebarWidth} />
        <main className={`content overflow-hidden ${mainWidth}`}>
            <TopBar toggleNavbar={toggleNavbar}/>
            <div className="all">
                <div className="py-4">
                    <div  className="long d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <Link className="fw-bold text-dark" to="">
                                    <svg className="fw-bold icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                                    </svg>
                                    <span>Dashboard</span>
                                </Link>
                            </li>
                            <li className="breadcrumb-item"></li>
                        </ol>
                    </div>
                </div>

                <div className="row alert-warning p-3">
                    <div className="col-12 col-sm-6 col-xl-4 mb-4">
                        <div className="card border-0 shadow card-color" style={{"height": "166px"}}>
                            <div className="card-body" >
                                <div className="row d-block d-xl-flex align-items-center" style={{"height": "138px"}}>
                                    <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                        <div className="icon-shape icon-shape-primary rounded me-4 me-sm-0">
                                            {/* <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
                                            </svg> */}
                                            <FaClipboardList className=' icon-md icon-fa' />
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-7 px-xl-0">
                                        <div className="">
                                            <h2 className="h5 top-dash" style={{"color": "#1f2937"}}>Total Addresses</h2>
                                            <h3 className=" mb-1 low-dash" style={{"color": "#1f2937"}}>{addressCount.address}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to= { loggedInUser.level === '1' ? "/admin/verificate/assigned/zone" :
                     (loggedInUser.level === '2' ) ? `/admin/verificate/assigned_region/region?id=${loggedInUser.access_region[0].zone}` : (loggedInUser.level === '3') ? `/admin/verificate/assigned_state/state?id=${loggedInUser.access_state[0].region_id}` : `/admin/verificate/assigned/manifests?s=${loggedInUser.state.name}`}
                    className="dashboard-card col-12 col-sm-6 col-xl-4 mb-4">
                        <div className="card border-0 shadow card-color" style={{"height": "166px"}}>
                            <div className="card-body" >
                                <div className="row d-block d-xl-flex align-items-center" style={{"height": "138px"}}>
                                    <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                        <div className="icon-shape icon-shape-secondary rounded me-4 me-sm-0">
                                            {/* <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd"></path>
                                            </svg> */}
                                             <FaClipboardCheck className=' icon-md icon-fa' />
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-7 px-xl-0">
                                        <div className="">
                                            <h2 className="h5 top-dash" style={{"color": "#1f2937"}}>Assigned</h2>
                                            <h3 className=" mb-1 low-dash" style={{"color": "#1f2937"}}>{addressCount.addressAssigned}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <Link 
                       to= { loggedInUser.level === '1' ? "/admin/verificate/unassigned/zone" :    (loggedInUser.level === '2' ) ? `/admin/verificate/unassigned_region/region?id=${loggedInUser.access_region[0].zone}` : (loggedInUser.level === '3') ? `/admin/verificate/unassigned_state/state?id=${loggedInUser.access_state[0].region_id}` : `/admin/verificate/unassigned/manifests?s=${loggedInUser.state.name}` 
                     }  
                     className="dashboard-card col-12 col-sm-6 col-xl-4 mb-4">
                        <div className="card border-0 shadow card-color" style={{"height": "166px"}} >
                            <div className="card-body">
                                <div className="row d-block d-xl-flex align-items-center" style={{"height": "138px"}}>
                                    <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                        <div className="icon-shape icon-shape-tertiary rounded me-4 me-sm-0">
                                            {/* <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                                            </svg> */}
                                            <FaClipboard className=' icon-md icon-fa' />
                                        </div>
                                        
                                    </div>
                                    <div className="col-12 col-xl-7 px-xl-0">
                                        <div className="">
                                        <h2 className="h5 top-dash" style={{"color": "#1f2937"}}>Unassigned</h2>
                                        <h3 className="mb-1 low-dash" style={{"color": "#1f2937"}}>{addressCount.addressUnassigned}</h3>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    {/* <div className="col-12 col-sm-6 col-xl-3 mb-4">
                        <div className="card border-0 shadow" style={{"height": "166px"}} >
                            <div className="card-body">
                                <div className="row d-block d-xl-flex align-items-center" style={{"height": "138px"}}>
                                    <div className="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                        <div className="icon-shape icon-shape-tertiary rounded me-4 me-sm-0">
                                            <svg className="icon icon-md" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                                            </svg>
                                        </div>
                                        
                                    </div>
                                    <div className="col-12 col-xl-7 px-xl-0">
                                        <div className="">
                                        <h2 className="h5 fw-bold">Incomplete</h2>
                                        <h3 className="fw-extrabold mb-1 font-weight-bold">5</h3>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>

                <FooterBar />
            </div>

           
        </main>
    </>
  )
}
