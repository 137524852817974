import React, {useReducer, useState} from 'react'
import { NavigationBar } from '../../../components/navigationbar/NavigationBar.jsx'
import { TopBar } from '../../../components/topbar/TopBar.jsx'
import Modal from './Modal';
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import _ from "lodash";
import * as XLSX from 'xlsx';
import { useSelector } from 'react-redux';
import axios from "axios";
import { siginUser } from '../../../reducers/userSlice.js';
import {FooterBar} from '../../../components/general_footer/footer'
import Swal from 'sweetalert2'

const reducer = (state, action)=>{
    // console.log(state,action);
    switch (action.type) {
        case 'SET_FILE_NAME':
        return {
            ...state,
            data:action.payload.data,
            fileName:action.payload.name.name,
            testfile:action.payload.name,
            isModalOpen:true, 
            loading : false,
            modalContent:"File Read successfully! Ready to be uploaded!",
            // Swal.fire({
            //   icon: 'success',
             
            //   text: "File Read successfully! Ready to be uploaded!",
              
            // })
        };

        case 'SAVE_FILE':
        return {
            ...state,
            data:[],
            fileName:"",
            testfile:"",
            isModalOpen:true, 
            loading : false,
            modalContent:"File uploaded successfully!!!"
        };
        
        case 'LOADING':
        return {
            ...state,
            data:[],
            fileName:"",
            testfile:"",
            isModalOpen:false, 
            loading : true,
            modalContent:"File uploaded successfully!!!"
        };

        case 'ERROR':
        return {
            ...state,
            data:[],
            fileName:"",
            testfile:"",
            isModalOpen:true, 
            loading : false,
            modalContent:action.error.message
        };

        default:
        throw new Error("no matching action type");
    }
}

const defaultState = {
data:[],
fileName: "",
isModalOpen : false,
loading : false,
modalContent : ''
}


function UploadFile() {
    const userSignin = useSelector(siginUser);
    const { user } = userSignin;
    const { token } = user;
    const navigate = useNavigate();
    const [PerPag, setPerPag] = useState(100);
    const [state, dispatch] = useReducer(reducer, defaultState)

    const backend_url = process.env.REACT_APP_API;

    const [sidebarWidth, setSidebarWidth] = useState('sidebar-width')
    const [mainWidth, setMainWidth] = useState('ml-10')
    const toggleNavbar = ()=>{
        sidebarWidth === 'sidebar-width' ? setSidebarWidth('') : setSidebarWidth('sidebar-width');
        mainWidth === 'ml-10' ? setMainWidth('') : setMainWidth('ml-10');
    }
  
    const saveData = async (getData) => {
      // console.log(getData);
      if (!getData || !getData.files) {
        dispatch({type:"ERROR", error:{message:"Kindly select a file!"}});
        return;
      }
      
      const response = await axios.post(`${backend_url}/api/uploads`, getData,
        {
          headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
        }
      ).catch((error)=>{
        console.log(error);
        Swal.fire("File Upload", "Upload Failed! Try again Later!", "error");
      });
      // response.then((data)=>{
        // console.log(response);
        Swal.fire("File Upload", response.data.filename +" uploaded Sucessfully!!!", "success");
        // alert("File " + response.data.filename +" uploaded Sucessfully!!!");
        navigate('/admin/uploads/'+response.data.filename);
        dispatch({type:'SAVE_FILE'});
      // });
    }
  
    const readExcel = (file)=>{
      const heading = ['SN','PHONE','ADDRESS','CITY','LANDMARK','LGA','STATE','SURNAME','FIRSTNAME','OTHERNAME'];
      if (file) {
        const promise = new Promise((resolve,reject)=>{
          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(file);
          fileReader.onload = (e)=>{
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, {type:'buffer'});
            const wsname = wb.SheetNames[0];
            // console.log(wb);
            const ws = wb.Sheets[wsname];
            const nData = XLSX.utils.sheet_to_json(ws);

            //Notify User for duplicate rows to be removed
            const uniqueIds = new Set();
            const unique = nData.filter(element => {
              const isDuplicate = uniqueIds.has(element.SN);          
              uniqueIds.add(element.SN);          
              if (!isDuplicate) {
                return true;
              }          
              return false;
            });
  
            const nHeading = Object.keys(nData[0]);
            // console.log(JSON.stringify(nHeading) === JSON.stringify(heading));
            if (JSON.stringify(nHeading) !== JSON.stringify(heading)) {
              reject("File Heading not matching");
              dispatch({type:"ERROR", error:{message:"File Heading not matching"}});
            }        
            resolve(unique);
          };
          fileReader.onerror = (error)=>{
            reject(error);
            dispatch({type:"ERROR", error:error});
          }
        });
  
        promise.then((d)=>{
          dispatch({type:"SET_FILE_NAME", payload:{'data':d, 'name':file}});
        })
      } else {
        dispatch({type:"ERROR", error:{message:"Kindly select a file!"}});
      }
    }
  
    const {data  } = state
  
    const onpagChanged = e => setPerPag(Number(e.target.value))
  
   const [pageNumber, setPageNumber] = useState(0);
  
    const addressPerPage = PerPag;
    const pagesVisited = pageNumber * addressPerPage;
   const addressTotal = _.size(data);
    // const addressTotal = 4;
    
  
  
    const pageCount = Math.ceil(addressTotal / addressPerPage);
  
    const changePage = ({ selected }) => {
      setPageNumber(selected);
    };

  return (
    <>
     <NavigationBar sidebarWidth={sidebarWidth}/>
     <main className={`content  ${mainWidth}`}>
      <TopBar toggleNavbar={toggleNavbar}/>
      
      <div className="all">
       <div className="py-4">
         <div aria-label="breadcrumb" className="long  d-inline-block">
           <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
             <li className="breadcrumb-item">
               <Link className="fw-bold text-dark" to="/admin/dashboard">
               <svg className="fw-bold icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
               </svg>
               <span>Dashboard</span>
               </Link>
             </li>
             <li className="breadcrumb-item">
               <Link className="fw-bold text-dark" to="/admin/verificate/upload/excel">
               <span className="text-dark">Upload Excel</span>
               </Link>
             </li>
           </ol>
         </div>
       </div>
        
      <div className="container-fluid">
        
        <div className="alert-waning alert">
         <span className="bold">Note:</span><br />
         <span>1. Please follow the format below when uploading from Excel as each column must match with what is on the database or click the download button to download format.</span><br />
         <span>2. NOTE THAT THE COLUMN NAMES ARE CASE SENSITIVE</span>	
		    </div>


        <form className="row">
          <div className="col-12 text-center">
            <label className="bold">Select Excel File to be Uploaded</label>
            <br />
            <input type="hidden" name="MAX_FILE_SIZE" value="" />
            <input type="file" name="upload" className="border p-2" accept=".xlsx" onChange={(e)=>{
              const file = e.target.files[0];
              readExcel(file);
            }} />

            <button className="btn btn-dark" disabled={state.loading || !data.length} onClick={()=>{
              dispatch({type:'LOADING'});
              const myDatas = {"files": state.testfile};
              saveData(myDatas)
            }}>{!state.loading && data.length ? "Upload File" : (state.loading && data ? "Uploading.....!" : "Upload File")}</button>
          
          </div>
	      </form>

        <section>

        <div className="col-12 mb-4">
          <div className="row">
        
              {/* display modal for error and success */}
              {state.isModalOpen && <Modal modalContent={state.modalContent} />}

            <div className="table-responsive mt-2 bg-white p-1">
              <div className="col-12 m-2 ">
                <div className="row">
                  <div className="col-1">
                    <label className="label-control">No/Page</label>
                    <select name="pp" onChange={onpagChanged} value={PerPag} className="form-control">
                    <option value='10'>10</option>
                    <option value='20'>20</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                    <option value='500'>500</option>
                    </select>
                  </div>

                
                </div>
              </div>
              <table className="table  fs-13 table-sm table-striped">

                  <thead>
                    <tr className="bevel text-light fw-bold">
                      <th className="text-uppercase">sn</th>
                      <th className="text-uppercase">phone</th>
                      <th className="text-uppercase">address</th>
                      <th className="text-uppercase">city</th>
                      <th className="text-uppercase">landmark</th>
                      <th className="text-uppercase">lga</th>
                      <th className="text-uppercase">state</th>
                      <th className="text-uppercase">surname</th>
                      <th className="text-uppercase">firstname</th>
                      <th className="text-uppercase">othername</th>
                    </tr>
                  </thead>

                  <tbody>
                  { data.slice(pagesVisited, pagesVisited + addressPerPage).map((d)=>{
                        return <tr key={d.SN}>
                            <td> {d.SN}</td>
                            <td> {d.PHONE} </td>
                            <td> {d.ADDRESS} </td>
                            <td> {d.CITY} </td>
                            <td> {d.LANDMARK} </td>
                            <td> {d.LGA} </td>
                            <td> {d.STATE} </td>
                            <td> {d.SURNAME} </td>
                            <td> {d.FIRSTNAME} </td>
                            <td> {d.OTHERNAME} </td>
                        </tr>	 					
                      })
                    } 
                  </tbody>
                  
                </table>
                <ReactPaginate
                          previousLabel={"Previous"}
                          nextLabel={"Next"}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"paginationBttns pt-5"}
                          previousLinkClassName={"previousBttn "}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"paginationDisabled"}
                          activeClassName={"paginationActive"}
                        /> 
            </div>
          </div>
        </div>
        </section>
        <div className="d-flex justify-content-center">
         
	      </div>
       </div>
       <FooterBar />
      </div>

     </main>
    </>
  )
}

export default UploadFile