import axios from "axios";
import { Navigate} from 'react-router-dom'
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


const backend_url = process.env.REACT_APP_API;

const initialState = {
    user: window.localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : '',
    loading: false,
    error: null,
 
}


        export const loginUser = createAsyncThunk('loginUser', async (initialPost, { rejectWithValue }) => {
            // console.log(initialPost)
        
            try {
                const { data } = await axios.post(`${backend_url}/api/auth/signin`, initialPost);
                // const response = await axios.post(`${POSTS_URL}/${id}`, initialPost)
                // return data
                localStorage.setItem('user', JSON.stringify(data));
                return data
            } catch (err) {
       
            //   return err.response.data.message;
              return rejectWithValue(err.response.data.message)

            }
        }); 


        export const signout = createAsyncThunk('signout', () => {
            const user = JSON.parse(localStorage.getItem('user'))
            if(user){
                axios.post(`${backend_url}/api/auth/signout`,{},
                {
                    headers: { Authorization: `Bearer ${user.token}` },
                }
                );
            }
            localStorage.removeItem('user');
            localStorage.removeItem('latlng');
            
            <Navigate to='/'/>
        })





export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
    
    },
    extraReducers(builder) {
        builder
            .addCase(loginUser.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;     
                state.user = action.payload
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                // state.error = action.payload
                if (action.payload) { 
                    state.error = action.payload
                  } else {
                    state.error = action.error.message
                  }
                
                // console.log(action.payload);
            })

            .addCase(signout.fulfilled, (state, action) => {
                state.loading = false;
                state.user = {};
            })
        }
    })

    export const siginUser = (state) => state.user;

     export default userSlice