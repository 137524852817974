// import axios from "axios";
// import Moment from 'moment';
// import Modal from 'react-bootstrap/Modal';
import React, {useState } from 'react'
// import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { TopBar } from '../../../components/topbar/TopBar'
import { NavigationBar } from '../../../components/navigationbar/NavigationBar'
import './addUsers.css'
import {FooterBar} from '../../../components/general_footer/footer'

import {
  FaLongArrowAltLeft
  } from "react-icons/fa";


export default function Unassigned() {
    // const handleClose = () => setShow(false);
    //  const [show, setShow] = useState(false);

  const [sidebarWidth, setSidebarWidth] = useState('sidebar-width')
  const [mainWidth, setMainWidth] = useState('ml-10')
  const toggleNavbar = ()=>{
    sidebarWidth === 'sidebar-width' ? setSidebarWidth('') : setSidebarWidth('sidebar-width');
    mainWidth === 'ml-10' ? setMainWidth('') : setMainWidth('ml-10');
  }
 
//   const handleShow = () => setShow(true);
//   setShow(false)
 
  return (
   <>

    <NavigationBar sidebarWidth={sidebarWidth}/>
    <main className={`content  ${mainWidth}`}>
      <TopBar toggleNavbar={toggleNavbar}/>
      <div className="all">
        <div className="py-4">
          <div className="long d-none d-md-inline-block">
            <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
              <li className="breadcrumb-item">
                <Link className="font-weight-bolder text-dark" to="">
                <svg className="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                </svg>
                <span>Dashboard</span>
                </Link>
              </li>
                <li className="breadcrumb-item">
                  <span className="font-weight-bolder text-dark">
                    <span className='text-capitalize'>HO'S</span>
                  </span>
                </li>
                 <li className="breadcrumb-item">
                  <span className="font-weight-bolder text-dark">
                    <span className='text-capitalize'>Show</span>
                  </span>
                </li>
            
            </ol>
          </div>
        </div>
         <Link to="/admin/AddUsers" type="button" className="btn btn-small btn-dark m-2"><FaLongArrowAltLeft />Back to List</Link>

        <div  className="alert-waning ">
          <div  className="col-12 child alert alert-light ">
            
             <form>
                  <div className="row">
                      <dl className="col-6">
                          <dt>First Name</dt>
                          <dd><input type="text" className="form-control" /></dd>
                      </dl>
                      
                      <dl className="col-6">
                          <dt>Last Name</dt>
                          <dd><input type="text" className="form-control" /></dd>
                      </dl>
                  </div>
                  <div className="row">
                      <dl className="col-6">
                          <dt>Email</dt>
                          <dd><input type="text" className="form-control" /></dd>
                      </dl>

                      <dl className="col-6">
                          <dt>Username</dt>
                          <dd><input type="text" className="form-control" /></dd>
                      </dl>
                  </div>
                  <div className="row">
                      <dl className="col-6">
                          <dt>Password</dt>
                          <dd><input type="text" className="form-control" /></dd>
                      </dl>

                      <dl className="col-6">
                          <dt>Confirm Password</dt>
                          <dd><input type="text" className="form-control" /></dd>
                      </dl>
                  </div>

                  <div className="row">
                    <dl className="col-6">
                        <dt>State</dt>
                        <dd>
                            <select className="form-control">
                                <option value="">Select State</option>
                                    <option value="Abia">Abia</option>
                                    <option value="Adamawa">Adamawa</option>
                                    <option value="Akwa Ibom">Akwa Ibom</option>
                                    <option value="Anambra">Anambra</option>
                            </select>
                        </dd>
                    </dl>
                      <dl className="col-6">
                        <dt>State</dt>
                        <dd>
                            <select className="form-control">
                                <option value="">NORTH  CENTRAL</option>
                                    <option value="NORTH EAST"NORTH EAST></option>
                                    <option value="NORTH WEST">NORTH WEST</option>
                                
                            </select>
                        </dd>
                    </dl>
                  </div>
              </form>
              <div className="modal-footer">
                  <button type="button" className="btn bevel">Save changes</button>
              </div>
          </div>
        </div>
        

      </div>
      <FooterBar />
    </main>

    </>

  )
}
