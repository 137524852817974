import './riderIndex.css'
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import ReactPaginate from "react-paginate"
import { siginUser, signout } from '../../reducers/userSlice'
import Spinner from "../../components/spinner/Spinner.jsx"
import React, { useEffect, useState, useCallback } from 'react'
import { RiderTopBar } from '../../components/topbar/RiderTopbar'
import { FooterBar } from '../../components/general_footer/footer'
import {  FaHome, FaClipboardCheck, FaLongArrowAltLeft, } from "react-icons/fa";


export default function RiderIndex() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userSignin = useSelector(siginUser);
    const { user } = userSignin;
 
    const { token } = user;
    const [send, setSend] = useState(true)
    const [address, setAddress] = useState([])
    const [loading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(122)
    const [searchLandmark, setSearchLandmark] = useState("")
    const [searchPageNumber, setSearchPageNumber] = useState(122);
    const backend_url = process.env.REACT_APP_API;

    const { current_page, per_page, total } = address
    const pageCount = Math.ceil(Number(total ? total : 0) / (Number(per_page ? per_page : 1)));

    const changePage = ({ selected }) => {
        let pageNumbe = selected + 1;
        
        setPageNumber(pageNumbe);
    };

  
  const fetchData = useCallback(async (event) => {
    event && event.preventDefault()
    console.log(pageNumber);
    if(send){
        setLoading(true)
        const response = await axios.post(`${backend_url}/api/user/rider/address/unassigned?page=${pageNumber}`,
            {
                landmark: searchLandmark,
            },
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        ).catch((e) => {
            if (e.response.status === 401) {
                dispatch(signout());
                return navigate('/')
            }
        });
        if (response && response.data) {
            setAddress(response.data)
        }
        setLoading(false)
    }
  }, [backend_url, token, send, pageNumber, searchLandmark, dispatch, navigate])

  useEffect(() => {
    fetchData()
    return () => {
      setSend(false)
    }
  }, [searchPageNumber, pageNumber, fetchData]);

  return (
    <>
    {/* <NavigationBar /> */}
        <main className="ridercontent">
            <RiderTopBar />
            <div className="riderall">
           
                <div className="row py-5">
                    <h2 className="card-title fw-bolder text-oran mid p-2"><FaClipboardCheck className=' icon-md' />Unassigned Addresses</h2>

                    <Link to="/rider/dashboard" className=" card-title fw-bolder text-ora text-oran mid p-2 h2"><FaLongArrowAltLeft className=' icon-md' />Back</Link>
                        <div className="p-4">
                            <div className="search-container p-1 mb-2 d-flex justify-content-end">
                            
                                <input className="search-input rounded-pill col-12" type="text" placeholder="Search by Territory"  onChange={(e) =>{ setSearchLandmark(e.target.value); setSearchPageNumber(122) }}/>
                    
                            </div>
                            {!loading ? <input type="submit" className="col-12 btn but btn-sm" value="Search" onClick={() => { setSearchPageNumber(122); setSend(true) }} /> :
                                <input type="submit" className="form-control btn btn-small bevel" value="Loading..." disabled />}
                        </div>
                    {loading
                    ? <div  className="d-flex justify-content-center" style={{ width:"100%"}}> <Spinner  /></div>
                    :
                    <> 
                        { address?.data?.map((data, i) => {
                            return <Link key={i} to={`/rider/details?id=${data.id}`} className="rider-card col-12 col-sm-12 col-xl-4 mb-3">
                                <div className="card border-0 shadow">
                            
                                    <div className="card-body p-4 enitre">
                                        <p className="card-text lh-sm small fw-bolder"><span className=" text-capitalize ">{ i + address.from }. &nbsp;</span>{ data.first_name } { data.last_name }</p>
                                        <p className="card-text lh-sm small"><FaHome className=' icon-md text-oran' />{ data.address } { data.territory } { data.lga }</p>
                                    </div>
                                </div>
                            </Link>
                        })}

                        {address && <span>
                            <span className="text-left">Showing {address.from} to {address.to} of {address.total} address(s)</span>
                        </span> }
                        
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            activePage={current_page}
                            pageCount={pageCount}
                            onPageChange={changePage}
                            containerClassName={"paginationBttns pt-5"}
                            previousLinkClassName={"previousBttn "}
                            nextLinkClassName={"nextBttn"}
                            disabledClassName={"paginationDisabled"}
                            activeClassName={"paginationActive"}
                        />
                    </>}
                </div>
               
              
            </div>
            <FooterBar />
        </main>
    </>
  )
}
