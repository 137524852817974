import axios from "axios";
import './NavigationBar.css'
import React, { useState, useEffect} from 'react'
import defaultEagle from '../../assets/img/logo.png'
import { FaUserTie, FaBiking } from "react-icons/fa"
import { useSelector, useDispatch} from 'react-redux'
import defaultAltech from '../../assets/img/alogo.png'
import { siginUser, signout } from '../../reducers/userSlice'
import { Link, useLocation, NavLink, useNavigate } from "react-router-dom";
import { incompleteCount, incompleteCounter  } from '../../reducers/incompleteSlice';

const NavigationBar = ({sidebarWidth})=> {

  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [uploadCount, setuploadCount] = useState(0);

  const userSignin = useSelector(siginUser);
  const { user} = userSignin;
  const { token } = user;

  const backend_url = process.env.REACT_APP_API;
 
  const { user: loggedInUser} = user;
  const { state} = loggedInUser;

  const count = useSelector(incompleteCounter);

  const { incomplete} = count;
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      checkIfStillAuthorized();
    }, 60000); // Run every 5 seconds (5000 milliseconds)

    const checkIfStillAuthorized = async () => {
      try {
        await axios.get(`${backend_url}/api/authorized`, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(signout());
          navigate('/');
        }
      }
    };
    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
  }, [token, backend_url, dispatch, navigate]);

  useEffect(() => {

    dispatch(incompleteCount()).unwrap();
    const getUploadcount = async () => {
      const response = await axios.get(`${backend_url}/api/uploads/counts/pending`,
        {
        headers: { Authorization: `Bearer ${token}` },
        }
      ).catch((e)=>{
        if (e.response.status === 401) {
          dispatch(signout());
          return navigate('/')
        }
      });
      
      if(response){
        setuploadCount(response.data);
      }
    }
    getUploadcount();
  
  }, [dispatch, token, backend_url, navigate]);

  return (
   <>
    <nav id="sidebarMenu" className={`gw-sidebar  d-lg-block bg-gray-800 text-white ${sidebarWidth} `} >
      <div className="simplebar-wrapper position-relative" style={{"margin": "0px"}}>
        <div className="simplebar-height-auto-observer-wrapper">
          <div className="simplebar-height-auto-observer"></div>
        </div>
        <div className="simplebar-mask">

          <div className="simplebar-offset" style={{"right" : "0px", "bottom": "0px"}}>

            <div className="simplebar-content-wrapper" style={{"height": "auto" }}>

              <div className="simplebar-content" style={{"padding": "0px"}}>

                <div className="sidebar-inner px-4 pt-3">


                <ul className="nav flex-column pt-3 pt-md-0">

                  <li className="nav-item mb-4 justify-content-between align-items-center d-flex">

                    <Link to="#ff" className="nav-link d-flex align-items-center">

                      <span className="sidebar-icon t-icon">

                        <img src={defaultEagle} height="35" width="35" alt="logo"></img>

                      </span>

                      <span className="mt-1 ms-1 sidebar-text">Eagle Eye's</span>

                    </Link>
                    
                    <div className=" cursor d-md-none " data-bs-toggle="collapse" data-bs-target="#sidebarMenu">X</div>

                  </li>

                  <li className={location.pathname === `/admin/dashboard` ? 'nav-item active' : 'nav-item'} >

                    <Link to="/admin/dashboard" className="nav-link">

                      <span className="sidebar-icon">

                        <svg width="20" className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">

                          <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>

                          <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>

                        </svg> 

                      </span>

                      <span className="sidebar-text">Dashboard</span>

                    </Link>

                  </li>
                  <li className={location.pathname === `/admin/verificate/unassigned/manifests` ? 'nav-item active' : 'nav-item'}>

                    <Link to={`/admin/verificate/unassigned/manifests?s=${state.name}`} className="nav-link d-flex justify-content-between">

                      <span>

                        <span className="sidebar-icon">

                          <svg width="20" className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            
                            <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>

                          </svg> 

                        </span>

                        <span className="sidebar-text">Manifest</span>

                      </span>
                    </Link>
                  </li>

                  <li className={location.pathname === `/admin/verificate/assigned/manifests` ? 'nav-item active' : 'nav-item'}>

                        <Link to={`/admin/verificate/assigned/manifests?s=${state.name}`}className="nav-link">

                          <span className="sidebar-icon">

                            <svg width="20" className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>

                              <path fillRule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clipRule="evenodd"></path>
                            </svg> 

                          </span>

                          <span className="sidebar-text">Assigned</span>

                        </Link>

                   </li>

                   
                  <li className={location.pathname === `/admin/verificate/upload/incomplete` ? 'nav-item active' : 'nav-item'}>

                    <Link to="/admin/verificate/upload/incomplete" className="nav-link d-flex justify-content-between">

                      <span>

                        <span className="sidebar-icon">

                          <svg width="20" className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>
                          </svg>

                        </span>

                        <span className="sidebar-text">Incomplete</span>
                        {incomplete ? <span className="">
                           <span className="bage badge badge-md  ms-1 bg-white">{incomplete}</span>
                        </span> : <span></span>}
                      </span>
                    </Link>
                  </li>


                  <li className={location.pathname === `/admin/uploads` ? 'nav-item active' : 'nav-item'}>

                    <Link to="/admin/uploads" className="nav-link d-flex justify-content-between">

                      <span>

                        <span className="sidebar-icon">

                          <svg width="20" className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            
                             <path fill="currentColor" d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,12L16,16H13.5V19H10.5V16H8L12,12Z" />

                          </svg> 

                        </span>

                        <span className="sidebar-text">Uploads</span>
                        {uploadCount ? <span className="">
                           <span className="bage badge badge-md  ms-1 bg-white">{uploadCount}</span>
                        </span> : <span></span>}
                      </span>
                    </Link>
                  </li>
                  <li className={location.pathname === `/admin/report` ? 'nav-item active' : 'nav-item'}>

                    <NavLink to="/admin/report" className="nav-link d-flex justify-content-between">

                      <span>

                        <span className="sidebar-icon">

                           <svg width="20" className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">

                            <path fillRule="evenodd" d="M12 1.586l-4 4v12.828l4-4V1.586zM3.707 3.293A1 1 0 002 4v10a1 1 0 00.293.707L6 18.414V5.586L3.707 3.293zM17.707 5.293L14 1.586v12.828l2.293 2.293A1 1 0 0018 16V6a1 1 0 00-.293-.707z" clipRule="evenodd"></path>

                          </svg> 

                        </span>

                        <span className="sidebar-text">Reports</span>

                      </span>
                    </NavLink>
                  </li>

                  {loggedInUser.level <= 4 && <li className="nav-item">


                    <span className="nav-link collapsed d-flex justify-content-between align-items-center" data-bs-toggle="collapse" data-bs-target="#submenu-mang">

                      <span>

                        <span className="sidebar-icon">

                          <svg width="20" className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">

                          <path fillRule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path>

                        </svg> 

                        </span>

                        <span className="sidebar-text">User Management</span> 

                      </span>

                      <span className="link-arrow">

                        <svg width="20" className="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">

                          <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path>

                        </svg>

                      </span>

                    </span>

                    <div className="multi-level collapse show" role="list" id="submenu-mang" >

                      <ul className="flex-column nav">

                        { loggedInUser.level <= 1 && 
                        <li className={location.pathname === `/admin/users/1` ? 'nav-item active' : 'nav-item'}>

                          <Link className="nav-link" to= {`/admin/users/${'1'}`}>
                            <span className="sidebar-icon">
                                <FaUserTie className="icon icon-xs me-2"/>
                              </span>
                            <span className="sidebar-text fs-6">HO's</span>
                          </Link>
                        </li> }

         
                        { loggedInUser.level < 2 && <li className={location.pathname === `/admin/users/2` ? 'nav-item active' : 'nav-item'}>
                          <Link className="nav-link" to= {`/admin/users/${'2'}`}>
                          <span className="sidebar-icon">
                              <FaUserTie className="icon icon-xs me-2"/>
                            </span>
                          <span className="sidebar-text">ZM's</span>
                          </Link>

                        </li> }

                        { loggedInUser.level < 3 && <li className={location.pathname === `/admin/users/3` ? 'nav-item active' : 'nav-item'}>
                          <Link className="nav-link"to= {`/admin/users/${'3'}`}>
                          <span className="sidebar-icon">
                              <FaUserTie className="icon icon-xs me-2"/>
                            </span>
                          <span className="sidebar-text">RM's</span>
                          </Link>
                        </li> }


                        { loggedInUser.level < 4 && <li className={location.pathname === `/admin/users/4` ? 'nav-item active' : 'nav-item'}>
                          <Link className="nav-link" to= {`/admin/users/${'4'}`}>

                          <span className="sidebar-icon">
                              <FaUserTie className="icon icon-xs me-2"/>
                            </span>
                          <span className="sidebar-text fs-6">DEO's</span>
                          </Link>

                        </li> }


                        <li  className={location.pathname === `/admin/riders` ? 'nav-item active' : 'nav-item'}>
                          <Link className="nav-link" to= {`/admin/riders`}>
                          <span className="sidebar-icon">
                              <FaBiking className="icon icon-xs me-2"/>
                            </span>
                          <span className="sidebar-text fs-6"> VO’s</span>
                          </Link>
                        </li>

                      </ul>

                    </div>

                  </li> }

                  <li role="separator" className="dropdown-divider mt-4 mb-1  border-white-700"></li>

                 
                  <li className="nav-item">

                    <a href="http://alltsnetwork.com/" target="_blank" rel="noopener noreferrer" className="nav-link d-flex align-items-center">

                      <span className="sidebar-icon tb-icon">

                        <img src={defaultAltech} height="30" width="30" alt="Logo"></img>

                      </span>

                      <span className="sidebar-text fs-7">Powered by: <br /> All-Tech Systems & Co.</span>

                    </a>
                  </li>

                  <li className="nav-item ">

                  <a href="http://alltsnetwork.com/" target="_blank" rel="noopener noreferrer" className="nav-link d-flex align-items-center">

                      <span className="sidebar-icon">

                        <svg width="20" className="icon icon-xs me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">

                          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd"></path>

                        </svg> 

                      </span>

                      <span className="sidebar-text">Version 
                      
                        <span className="badge badge-md bg-secondary ms-1 text-gray-800">v2.1</span>

                      </span>

                    </a>
                  </li>
                </ul>

                </div>

              </div>

            </div>

          </div>

        </div>
        <div className="simplebar-placeholder" style={{"width": "260px", "height": "621px"}}></div>
      </div>

    </nav>
   </>
  )
}

export { NavigationBar};